import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

/**
 * Cache for holding observables. Best used for http-requests
 */
export class ObservableCache<TKey, TValue> {
  private readonly cache: CacheEntry<TKey, TValue>[];

  constructor(private readonly maxCacheSize = 3) {
    this.cache = [];
  }

  getOrCreate(
    key: TKey,
    fetcher: () => Observable<TValue>
  ): Observable<TValue> {
    const cached = this.get(key);
    if (cached) {
      return cached;
    }

    const fetch$ = fetcher().pipe(shareReplay(1));
    this.set(key, fetch$);
    return fetch$;
  }

  get(key: TKey): Observable<TValue> {
    const cached = this.cache.find(e => e.key === key);
    return cached && cached.value$;
  }

  set(key: TKey, value$: Observable<TValue>): void {
    this.cache.unshift({
      key,
      value$
    });

    this.reduceToMaxSize();
  }

  private reduceToMaxSize(): void {
    while (this.cache.length > this.maxCacheSize) {
      this.cache.pop();
    }
  }
}

class CacheEntry<TKey, TValue> {
  key: TKey;
  value$: Observable<TValue>;
}
