import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'isd-loading-spinner-block',
  templateUrl: './loading-spinner-block.component.html',
  styleUrls: ['./loading-spinner-block.component.scss']
})
export class LoadingSpinnerBlockComponent implements OnInit {
  @Input() height: string;
  @Input() hideSpinner = false;

  constructor() {}

  ngOnInit() {}
}
