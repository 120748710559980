import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RoleMembership } from 'src/app/dto/role-membership.dto';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Role } from 'src/app/dto/role.dto';
import { User } from 'src/app/dto/user.dto';

export class RoleMembershipDiff {
  created: RoleMembership[];
  deleted: RoleMembership[];
}

@Injectable()
export class RolemembershipService {
  constructor(private readonly http: HttpClient) {}

  getRolemembershipByZone(zoneId: number): Observable<RoleMembership[]> {
    return this.http.get<RoleMembership[]>(`${this.baseUrl}?zoneId=${zoneId}`);
  }

  createRoleMembership(
    roleMembership: RoleMembership
  ): Observable<RoleMembership> {
    return this.http.post<RoleMembership>(`${this.baseUrl}`, roleMembership);
  }

  deleteRoleMembership(roleMembership: RoleMembership): Observable<any> {
    return this.http.delete(
      `${this.baseUrl}?zoneId=${roleMembership.zoneId}&roleId=${roleMembership.roleId}&userId=${roleMembership.user.id}`
    );
  }

  generateDiff(
    currenRoleMembershipList: RoleMembership[],
    nextRoleSelection: Role[],
    user: User,
    zoneId: number
  ): RoleMembershipDiff {
    const deleted: RoleMembership[] = this.findUnselected(
      currenRoleMembershipList,
      nextRoleSelection,
      user
    );

    const created: RoleMembership[] = this.findNewlySelected(
      currenRoleMembershipList,
      nextRoleSelection,
      user,
      zoneId
    );

    return {
      created,
      deleted
    };
  }

  async sendRoleMembershipDiffToServer(diff: RoleMembershipDiff): Promise<any> {
    const createPromises = diff.created.map(rm =>
      this.createRoleMembership(rm).toPromise()
    );

    const deletePromises = diff.deleted.map(rm =>
      this.deleteRoleMembership(rm).toPromise()
    );

    return Promise.all([...createPromises, ...deletePromises]);
  }

  private get baseUrl(): string {
    return `${environment.bolApiHost}/${environment.bolApiBaseUrl}/roleMembership`;
  }

  private findNewlySelected(
    userRoleMembershipList: RoleMembership[],
    nextRoleSelection: Role[],
    user: User,
    zoneId: number
  ): RoleMembership[] {
    return nextRoleSelection
      .filter(
        next =>
          userRoleMembershipList.find(
            current => current.roleId === next.id && current.user.id === user.id
          ) === undefined
      )
      .map(role => ({ roleId: role.id, user, zoneId }));
  }

  private findUnselected(
    userRoleMembershipList: RoleMembership[],
    nextRoleSelection: Role[],
    user: User
  ): RoleMembership[] {
    return userRoleMembershipList.filter(
      current =>
        nextRoleSelection.find(
          nextRole =>
            nextRole.id === current.roleId && current.user.id === user.id
        ) === undefined
    );
  }
}
