<div class="d-flex modal-header border-0">
  <h4 class="text-muted">
    {{ 'OBJECT.NEW_SERVICES.TITLE' | translate }}
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="text-center mb-3">
    <img
      [src]="serviceCategoryImgUrl"
      [alt]="serviceCategoryName"
      class="rounded overflow-hidden"
    />
  </div>
  <div class="row">
    <div class="col">
      <label for="ServiceToAdd">
        {{ 'OBJECT.NEW_SERVICES.SERVICE_TO_ADD' | translate }}
      </label>
    </div>
    <div class="col">
      <label id="ServiceToAdd">{{ serviceCategoryName }}</label>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <label for="ContractNumber">
        {{ 'OBJECT.NEW_SERVICES.CONTRACT_NUMBER' | translate }}
      </label>
    </div>
    <div class="col">
      <ng-select
        [items]="contractNumberOptions"
        [notFoundText]="'COMMON.SELECT.NOT_FOUND' | translate"
        bindLabel="text"
        bindValue="value"
        [placeholder]="'OBJECT.NEW_SERVICES.CHOOSE_CONTRACT' | translate"
        [(ngModel)]="selectedContractNumber"
        [clearable]="false"
      >
      </ng-select>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="closeDialog()" class="btn btn-primary">
    {{ 'OBJECT.NEW_SERVICES.GENERATE_MAIL' | translate }}
  </button>
</div>
