import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationPopupComponent } from './validation-popup/validation-popup.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { InfoPopupComponent } from './info-popup/info-popup.component';
import { InfoModalComponent } from './info-popup/info-modal/info-modal.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { LoadingSpinnerBlockComponent } from './loading-spinner-block/loading-spinner-block.component';
import { LoadingSpinnerOverlayComponent } from './loading-spinner-overlay/loading-spinner-overlay.component';
import { LoadingModule } from 'customer-portal-framework-lib';

@NgModule({
  declarations: [
    ValidationPopupComponent,
    InfoPopupComponent,
    InfoModalComponent,
    LoadingSpinnerComponent,
    LoadingSpinnerBlockComponent,
    LoadingSpinnerOverlayComponent
  ],
  imports: [CommonModule, NgbPopoverModule, TranslateModule, LoadingModule],
  exports: [
    ValidationPopupComponent,
    InfoPopupComponent,
    LoadingSpinnerComponent,
    LoadingSpinnerBlockComponent,
    LoadingSpinnerOverlayComponent
  ],
  entryComponents: [InfoModalComponent]
})
export class SharedModule {}
