import { Component, OnInit, Input } from '@angular/core';
import { Customer } from 'src/app/dto/customer.dto';

@Component({
  selector: 'isd-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent implements OnInit {
  @Input() customer: Customer;

  constructor() {}

  ngOnInit() {}
}
