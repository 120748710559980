import { ILogger } from './ilogger.interface';
import { environment } from 'src/environments/environment';

export class ConsoleLogger implements ILogger {
  constructor(private readonly category: string) {}

  log(message?: any, ...optionalParams: any[]): void {
    if (!environment.production) {
      console.log(`[${this.category}]`, message, ...optionalParams);
    }
  }

  warn(message?: any, ...optionalParams: any[]): void {
    if (!environment.production) {
      console.warn(`[${this.category}]`, message, ...optionalParams);
    }
  }

  error(message?: any, ...optionalParams: any[]): void {
    if (!environment.production) {
      console.error(`[${this.category}]`, message, ...optionalParams);
    }
  }
}
