import { Component, Input } from '@angular/core';

@Component({
  selector: 'isd-services-card',
  templateUrl: './services-card.component.html',
  styleUrls: ['./services-card.component.scss']
})
export class ServicesCardComponent {
  @Input() imageURL: string;
  @Input() description: string;
}
