import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { noop } from 'rxjs';
import { StorageService } from 'src/app/service/storage/storage.service';
import { IntroVideoComponent } from './intro-video.component';

@Injectable({
  providedIn: 'root'
})
export class IntroVideoService {
  private readonly showVideoStorageKey = 'showIntroVideo';

  constructor(
    private readonly modal: NgbModal,
    private readonly storage: StorageService
  ) {}

  async showVideoIfNotSeen(): Promise<void> {
    if (this.shouldShowVideo()) {
      return this.openModal(true).then(
        shouldShowVideoAgain => this.updateShowVideoFlag(shouldShowVideoAgain),
        noop
      );
    }

    return Promise.resolve();
  }

  /**
   * Opens the intro video modal
   * @returns true if the video should be played at a later time
   */
  async openModal(showButtons: boolean): Promise<boolean> {
    const modalRef = this.modal.open(IntroVideoComponent, {
      size: 'xl',
      backdrop: true,
      scrollable: false
    });

    const comp = modalRef.componentInstance as IntroVideoComponent;
    comp.showButtons = showButtons;

    return modalRef.result.then(
      res => {
        if (res === 'no' || (res !== 'later' && comp.hasVideoBeenStarted)) {
          return false;
        }
        return true;
      },
      () => {
        if (comp.hasVideoBeenStarted) {
          return false;
        }
        return true;
      }
    );
  }

  private shouldShowVideo(): boolean {
    const showVideo = this.storage.getItem(this.showVideoStorageKey);
    return showVideo !== 'no';
  }

  private updateShowVideoFlag(shouldShowVideoAgain: boolean): void {
    if (!shouldShowVideoAgain) {
      this.storage.setItem(this.showVideoStorageKey, 'no');
    }
  }
}
