import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'isd-intro-video',
  templateUrl: './intro-video.component.html',
  styleUrls: ['./intro-video.component.scss']
})
export class IntroVideoComponent implements OnInit {
  @ViewChild('videoPlayer', { static: true }) videoplayer: ElementRef;

  hasVideoBeenStarted = false;
  showButtons: boolean;
  videoLink = environment.introVideoLink;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    const video: HTMLVideoElement = this.videoplayer.nativeElement;
    video.onplay = e => (this.hasVideoBeenStarted = true);
  }
}
