import { Component, Input } from '@angular/core';
import { Zone } from 'src/app/dto/zone.dto';
import { User } from 'src/app/dto/user.dto';
import { Role } from 'src/app/dto/role.dto';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'isd-role-assignment-modal',
  templateUrl: './role-assignment-modal.component.html',
  styleUrls: ['./role-assignment-modal.component.scss']
})
export class RoleAssignmentModalComponent {
  @Input() zone: Zone;
  @Input() user: User;
  @Input() availableRoles: Role[];
  @Input() selectedRoles: Role[] = [];

  constructor(public modal: NgbActiveModal) {}

  save(): void {
    this.modal.close(this.selectedRoles);
  }
}
