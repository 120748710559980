<div class="row">
  <div class="col-12">
    <span class="h5 mb-0 font-weight-bold">{{
      'OBJECT.CONTRACTS' | translate
    }}</span>
  </div>
  <div class="col-12">
    <isd-loading-spinner-block
      *cpLoading="loadingKey"
    ></isd-loading-spinner-block>
    <div [hidden]="(contracts$ | async) === undefined" class="row">
      <ng-container *ngIf="contracts$ | async as contracts">
        <ng-container *ngIf="contracts.length > 0; else noContractsTemplate">
          <div
            class="col-xs-12 col-sm-6 col-xl-12 mt-3"
            *ngFor="let contract of contracts"
          >
            <div class="mb-1 contracts-title">
              <i class="fas fa-file-contract mr-1"></i>
              <strong class="align-middle ml-1"
                >{{ 'OBJECT.CONTRACT' | translate }}
                {{ contract.contractNumber }}</strong
              >
            </div>
            <div>
              <span
                *ngFor="
                  let serviceCategory of contract.serviceCategories;
                  let last = last
                "
                class="badge badge-pill p-2 mb-2 badge-size"
                [class.badge-primary]="!serviceCategory.isInContract"
                [class.badge-secondary]="serviceCategory.isInContract"
                [class.mr-1]="!last"
              >
                <ng-container *ngIf="hasNoSpecialServices(contract)">
                  <span class="badge-text">{{ serviceCategory.name }}</span>

                  <span
                    class="ml-1 fa-stack fa-2x cursor-pointer badge-icon"
                    *ngIf="serviceCategory.isInContract"
                    (click)="openAddNewServiceModal(serviceCategory, contract)"
                  >
                    <i class="fas fa-circle fa-stack-2x text-primary"></i>
                    <i class="fas fa-plus fa-stack-1x text-white"></i>
                  </span>
                </ng-container>
                <ng-container *ngIf="isSpecialServiceCategory(serviceCategory)">
                  <span class="badge-text mr-1">{{
                    serviceCategory.name
                  }}</span>

                  <isd-info-popup
                    [popupTemplate]="detailPopover"
                    [iconClass]="'text-white'"
                  ></isd-info-popup>
                </ng-container>

                <ng-template #detailPopover>
                  <ng-container *ngFor="let detail of serviceCategory.details">
                    <br *ngIf="!detail" />
                    <div *ngIf="detail">{{ detail }}</div>
                  </ng-container>
                </ng-template>
              </span>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #noContractsTemplate>
        <div class="col-12 mt-3 text-muted">
          <span>{{ 'OBJECT.NO_CONTRACTS_FOUND' | translate }}</span>
        </div>
      </ng-template>
    </div>
  </div>
</div>
