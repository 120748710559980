import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'isd-calendar-image-modal',
  templateUrl: './calendar-image-modal.component.html',
  styleUrls: ['./calendar-image-modal.component.scss']
})
export class CalendarImageModalComponent {
  public title = '';
  public imageSrc = '';

  constructor(
    private readonly http: HttpClient,
    readonly activeModal: NgbActiveModal
  ) {}

  public async setContent(title: string, imageUrl: string): Promise<void> {
    this.title = title;
    this.imageSrc = await this.getBase64SrcAsync(imageUrl);
  }

  async getBase64SrcAsync(imageUrl: string): Promise<string> {
    const imgBlob = await this.loadImageAsync(imageUrl);
    return await this.getDataUrl(imgBlob);
  }

  getDataUrl(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);

      reader.onloadend = function () {
        resolve(reader.result as string);
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  loadImageAsync(imageUrl: string): Promise<Blob> {
    return this.http
      .get(`${environment.bolApiHost}${imageUrl}`, { responseType: 'blob' })
      .toPromise();
  }
}
