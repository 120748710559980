import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { FolderItem } from '../dto/folder-item.dto';
import { Observable } from 'rxjs';
import { FolderItemType } from '../enum/folder-item-type.enum';

/**
 * Provides functionality to bookmark a given folderpath in the url to restore it later.
 */
@Injectable()
export class FolderPathBookmarkService {
  private readonly paramName = 'folderPath';
  constructor(private readonly router: Router) {}

  get(): Observable<FolderItem[]> {
    return this.getEncodedStringFromUrl().pipe(
      map(raw => {
        if (!raw) {
          return undefined;
        }

        try {
          return this.decodeFolderList(raw);
        } catch {
          return undefined;
        }
      })
    );
  }

  async set(folderList: FolderItem[]): Promise<void> {
    if (folderList.length === 0) {
      await this.clearFolderPathQueryParam();
    }

    const encoded = this.encodeFolderList(folderList);
    await this.addDecodedStringToUrl(encoded);
  }

  private async clearFolderPathQueryParam(): Promise<void> {
    await this.addDecodedStringToUrl(undefined);
  }

  private encodeFolderList(breadcrumb: FolderItem[]): string {
    const str = breadcrumb.map(b => `${b.name}:${b.path}:${b.type}`).join(';');
    return btoa(str);
  }

  private decodeFolderList(raw: string): FolderItem[] {
    const decoded = atob(raw);
    const breadcrumStrList = decoded.split(';');
    return breadcrumStrList.map(breadcrumbStr => {
      const breadcrumbSplit = breadcrumbStr.split(':');
      return {
        name: breadcrumbSplit[0],
        path: breadcrumbSplit[1],
        type: this.parseFileItemType(breadcrumbSplit[2])
      };
    });
  }

  private async addDecodedStringToUrl(path: string): Promise<any> {
    const activeRoute = this.getActiveRoute();

    const params = path ? { folderPath: path } : undefined;
    return this.router.navigate([], {
      relativeTo: activeRoute,
      queryParams: params
    });
  }

  private parseFileItemType(raw: string): FolderItemType {
    return parseInt(raw, 10);
  }

  private getEncodedStringFromUrl(): Observable<string> {
    return this.getActiveRoute().queryParamMap.pipe(
      map(queryParams => queryParams.get(this.paramName))
    );
  }

  /**
   * Find the activatedRoute in the routerState
   */
  private getActiveRoute(): ActivatedRoute {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
