import { Component, Input } from '@angular/core';
import { LoadingService } from 'customer-portal-framework-lib';
import { Observable, of } from 'rxjs';
import { ObjectInformation } from 'src/app/dto/object-information.dto';
import { ObjectLocationDataService } from '../object-location-data.service';
import { catchError } from 'rxjs/operators';
import { ObjectReport } from './object-reports/object-report.dto';
import { ErrorHandlerService } from 'src/app/service/error-handler/error-handler.service';

@Component({
  selector: 'isd-object-information',
  templateUrl: './object-information.component.html',
  styleUrls: ['./object-information.component.scss']
})
export class ObjectInformationComponent {
  base64ImageData$: Observable<string>;
  imageLoadingKey = 'imageLoadingKey';
  controlsLoadingKey = 'controlsLoadingKey';
  reportsLoadingKey = 'reportsLoadingKey';

  objectControls$: Observable<string[]>;
  objectReports$: Observable<ObjectReport[]>;

  private _objectInformation: ObjectInformation;

  get objectInformation(): ObjectInformation {
    return this._objectInformation;
  }

  @Input() set objectInformation(objectInfo: ObjectInformation) {
    this._objectInformation = objectInfo;

    this.loadObjectImage();
    this.loadObjectControls();
    this.loadObjectReports();
  }

  constructor(
    private readonly objectDataService: ObjectLocationDataService,
    private readonly errorHandler: ErrorHandlerService,
    readonly loadingService: LoadingService
  ) {}

  private loadObjectImage(): void {
    const noImageFound$ = of('../assets/img/no_img_found.jpg');
    if (this._objectInformation.hasImage) {
      this.base64ImageData$ = this.objectDataService
        .getBase64ObjectImageByObjectNumber(
          this._objectInformation.objectNumber
        )
        .pipe(catchError(() => noImageFound$));
    } else {
      this.base64ImageData$ = noImageFound$;
    }
  }

  private loadObjectReports(): void {
    this.loadingService.startLoading(this.reportsLoadingKey);
    this.objectReports$ = this.objectDataService
      .getObjectReports(this._objectInformation.objectNumber)
      .pipe(
        this.loadingService.pipeStopLoading(this.reportsLoadingKey),
        this.errorHandler.pipeCatchError()
      );
  }

  private loadObjectControls(): void {
    this.loadingService.startLoading(this.controlsLoadingKey);
    this.objectControls$ = this.objectDataService
      .getObjectControls(this._objectInformation.objectNumber)
      .pipe(
        this.loadingService.pipeStopLoading(this.controlsLoadingKey),
        this.errorHandler.pipeCatchError()
      );
  }
}
