<div class="modal-header">
  <div class="d-flex w-100">
    <div class="flex-grow-1">
      <h3>
        {{ 'OBJECT.CALENDAR.LABEL' | translate }}
      </h3>
    </div>
    <div>
      <isd-loading-spinner
        *cpLoading="loadingServiceEventsKey"
      ></isd-loading-spinner>
    </div>
    <div>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>

<div class="modal-body">
  <div class="row mb-3">
    <div class="col">
      <div class="d-inline-block mr-3">
        <div
          class="rounded-circle event-done d-inline-block"
          style="width: 20px; height: 20px"
        ></div>
        {{ 'OBJECT.CALENDAR.LEGEND_DONE' | translate }}
      </div>
      <div class="d-inline-block">
        <div
          class="rounded-circle event-planned d-inline-block"
          style="width: 20px; height: 20px"
        ></div>
        {{ 'OBJECT.CALENDAR.LEGEND_PLANNED' | translate }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <full-calendar
        #calendarRef
        defaultView="dayGridMonth"
        [options]="calendarOptions$ | async"
      ></full-calendar>
    </div>
  </div>
</div>
