import { ValidatorFn, FormControl, ValidationErrors } from '@angular/forms';

export function valueChangedValidator(baseValue: string): ValidatorFn {
  return (formControl: FormControl): ValidationErrors | undefined => {
    if (formControl.value === baseValue) {
      return {
        error: {
          unchanged: true
        }
      };
    }
    return undefined;
  };
}
