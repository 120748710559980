import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RoleDefinition } from 'src/app/dto/role-definition.dto';

@Injectable()
export class RoleDefinitionService {
  constructor(private readonly http: HttpClient) {}

  getRoleDefinitionsByCustomerZoneId(
    customerZoneId: number
  ): Observable<RoleDefinition[]> {
    return this.http.get<RoleDefinition[]>(
      `${environment.bolApiHost}/${environment.bolApiBaseUrl}/RoleDefinitions?customerZoneId=${customerZoneId}`
    );
  }

  updateRoleDefinition(
    roleDefinition: RoleDefinition
  ): Observable<RoleDefinition> {
    return this.http.put<RoleDefinition>(
      `${environment.bolApiHost}/${environment.bolApiBaseUrl}/RoleDefinitions/${roleDefinition.id}`,
      roleDefinition
    );
  }

  areEqual(
    roleDefinitionA: RoleDefinition,
    roleDefinitionB: RoleDefinition
  ): boolean {
    if (roleDefinitionA === undefined && roleDefinitionB === undefined) {
      return true;
    } else if (roleDefinitionA === undefined || roleDefinitionB === undefined) {
      return false;
    }

    return !(
      roleDefinitionB.contractDocuments !== roleDefinitionA.contractDocuments ||
      roleDefinitionB.customerInfo !== roleDefinitionA.customerInfo ||
      roleDefinitionB.documentInbox !== roleDefinitionA.documentInbox ||
      roleDefinitionB.customerDocuments !== roleDefinitionA.customerDocuments ||
      roleDefinitionB.documentInbox !== roleDefinitionA.documentInbox ||
      roleDefinitionB.gardenPlanning !== roleDefinitionA.gardenPlanning ||
      roleDefinitionB.id !== roleDefinitionA.id ||
      roleDefinitionB.invoices !== roleDefinitionA.invoices ||
      roleDefinitionB.objectControls !== roleDefinitionA.objectControls ||
      roleDefinitionB.objectData !== roleDefinitionA.objectData ||
      roleDefinitionB.roleName !== roleDefinitionA.roleName ||
      roleDefinitionB.roleTypeId !== roleDefinitionA.roleTypeId ||
      roleDefinitionB.contactPersonsIsd !== roleDefinitionA.contactPersonsIsd ||
      roleDefinitionB.serviceCalendar !== roleDefinitionA.serviceCalendar
    );
  }

  cloneRoleDefinition(roleDefinition: RoleDefinition): RoleDefinition {
    return {
      contractDocuments: roleDefinition.contractDocuments,
      customerInfo: roleDefinition.customerInfo,
      customerDocuments: roleDefinition.customerDocuments,
      documentInbox: roleDefinition.documentInbox,
      gardenPlanning: roleDefinition.gardenPlanning,
      id: roleDefinition.id,
      invoices: roleDefinition.invoices,
      objectControls: roleDefinition.objectControls,
      objectData: roleDefinition.objectData,
      roleName: roleDefinition.roleName,
      roleTypeId: roleDefinition.roleTypeId,
      contactPersonsIsd: roleDefinition.contactPersonsIsd,
      serviceCalendar: roleDefinition.serviceCalendar
    };
  }
}
