import { Component, OnInit } from '@angular/core';
import { AuthService } from 'ps-ng-auth-lib';

@Component({
  selector: 'isd-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  constructor(public authService: AuthService) {}

  ngOnInit(): void {}
}
