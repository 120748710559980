<div class="modal-header">
  <div class="d-flex w-100 align-items-center">
    <div class="flex-grow-1">
      <h3 class="mb-0">{{ title }}</h3>
    </div>
    <div>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>

<div class="modal-body">
  <div class="d-flex justify-content-center">
    <img [src]="imageSrc" />
  </div>
</div>
