<div class="shadow-sm">
  <ng-select
    [placeholder]="'ZONENAVIGATION.CUSTOMERSELECTION' | translate"
    [loadingText]="'COMMON.SELECT.LOADING' | translate"
    [notFoundText]="'COMMON.SELECT.NOT_FOUND' | translate"
    [clearAllText]="'COMMON.SELECT.CLEAR' | translate"
    [items]="customerZones$ | async"
    bindLabel="name"
    (change)="customerZoneSelected()"
    [(ngModel)]="selectedCustomer"
    [clearable]="false"
    [loading]="!(customerZones$ | async)"
    appendTo="body"
  >
  </ng-select>
</div>
