import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuardService } from 'ps-ng-auth-lib';
import { CustomerZoneOverviewComponent } from './customer/customer-zone-overview/customer-zone-overview.component';
import { RoleMembershipEditorComponent } from './admin/role-membership/role-membership-editor/role-membership-editor.component';
import { ObjectOverviewComponent } from './object/object-overview/object-overview.component';
import { AdminOverviewComponent } from './admin/admin-overview/admin-overview.component';
import { RoleDefinitionOverviewComponent } from './admin/role-definition/role-definition-overview/role-definition-overview.component';
import { PortalComponent } from './portal/portal.component';
import { ForbiddenComponent } from './auth/forbidden/forbidden.component';
import { WhenNotAuthenticatedRedirectToLoginGuard } from './auth/when-not-authenticated-redirect-to-login.guard';
import { forbiddenUrl } from './auth/forbidden/forbidden.service';
import { AdminGuardService } from './admin/service/admin-guard.service';
import { TermsAndVideoGuard } from './guards/terms-and-video.guard';
import { ErrorComponent } from './portal/error/error.component';
import { errorUrl } from './portal/error/error.service';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: forbiddenUrl,
    component: ForbiddenComponent,
    canActivate: [WhenNotAuthenticatedRedirectToLoginGuard]
  },
  {
    path: errorUrl,
    component: ErrorComponent,
    canActivate: [WhenNotAuthenticatedRedirectToLoginGuard]
  },
  {
    path: 'customer',
    component: PortalComponent,
    canActivate: [AuthGuardService],
    canActivateChild: [TermsAndVideoGuard],
    children: [
      {
        path: ':customerZoneId/overview',
        component: CustomerZoneOverviewComponent
      },
      {
        path: ':customerZoneId',
        redirectTo: ':customerZoneId/overview',
        pathMatch: 'full'
      },
      {
        path: ':customerZoneId/object/:objectLocationId/overview',
        component: ObjectOverviewComponent
      },
      {
        path: ':customerZoneId/object/:objectLocationId',
        redirectTo: ':customerZoneId/object/:objectLocationId/overview',
        pathMatch: 'full'
      },
      {
        path: ':customerZoneId/admin',
        component: AdminOverviewComponent,
        canActivate: [AdminGuardService],
        children: [
          {
            path: 'roles',
            component: RoleDefinitionOverviewComponent
          },
          {
            path: 'rolemembership',
            component: RoleMembershipEditorComponent
          },
          {
            path: '',
            redirectTo: 'rolemembership',
            pathMatch: 'full'
          }
        ]
      }
    ]
  },
  { path: '', redirectTo: 'customer', pathMatch: 'full' },
  { path: '**', redirectTo: forbiddenUrl }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
