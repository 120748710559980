<ng-template #elseTemplate>
  <isd-loading-spinner-block
    class="w-100"
    *cpLoading="loadingObjectInfoKey"
    [height]="'400px'"
  ></isd-loading-spinner-block>
</ng-template>
<div class="row">
  <div class="col-xs-12 col-xl-4 d-flex justify-content-center mb-4 mb-xl-0">
    <ng-container
      *ngIf="objectInfo$ | async; let objectInfo; else: elseTemplate"
    >
      <div class="h-100">
        <div class="p-3 w-100 shadow rounded">
          <div>
            <isd-object-information
              *ngIf="!(loadingService.isLoading(loadingObjectInfoKey) | async)"
              [objectInformation]="objectInfo"
            ></isd-object-information>
          </div>
          <div
            class="mt-2"
            *ngIf="!(loadingService.isLoading(loadingObjectInfoKey) | async)"
          >
            <isd-object-service-calendar
              *isdIfPermitted="'object.serviceCalendar.canRead'"
            ></isd-object-service-calendar>
          </div>
          <div
            class="mt-2"
            *ngIf="!(loadingService.isLoading(loadingObjectInfoKey) | async)"
          >
            <isd-object-contracts
              *isdIfPermitted="'object.contract.canRead'"
              [officeEmail]="objectInfo.officeEmail"
            ></isd-object-contracts>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div
    *ngIf="objectInfo$ | async; let objectInfo"
    class="col-xs-12 col-xl-8 d-flex flex-column"
  >
    <div class="flex-grow-1">
      <isd-object-documents></isd-object-documents>
    </div>
    <div class="mt-2 flex-shrink-1">
      <isd-additional-services-banner
        *isdIfPermitted="'object.contract.canRead'"
        [officeMail]="objectInfo?.officeEmail"
      ></isd-additional-services-banner>
    </div>
  </div>
</div>
