<isd-loading-spinner-block
  class="w-100"
  *cpLoading="loadingServiceCategories"
></isd-loading-spinner-block>

<div [hidden]="loadingService.isLoading(loadingServiceCategories) | async">
  <ng-container *ngIf="serviceCategories$ | async as serviceCategories">
    <h4 class="text-primary">
      {{ 'OBJECT.MORE_SERVICES' | translate }}
    </h4>
    <div class="d-flex flex-wrap">
      <ng-container *ngFor="let serviceCategory of serviceCategories">
        <isd-services-card
          [imageURL]="objectContractsService.getImageURL(serviceCategory.name)"
          [description]="serviceCategory.name"
          (click)="openAddNewServiceModal(serviceCategory)"
        ></isd-services-card>
      </ng-container>
    </div>
  </ng-container>
</div>
