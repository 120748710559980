import { Component, OnInit } from '@angular/core';
import { AuthService } from 'ps-ng-auth-lib';
import { ActivatedRoute, Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { StorageService } from 'src/app/service/storage/storage.service';

@Component({
  selector: 'isd-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  userName: string;
  private readonly defaultRedirectTo = '/';

  constructor(
    readonly auth: AuthService,
    readonly route: ActivatedRoute,
    readonly router: Router,
    readonly storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.auth
      .isAuthenticated()
      .pipe(take(1))
      .subscribe(isAuthenticated => {
        if (isAuthenticated) {
          this.redirect();
          return;
        }

        const storedName = this.getStoredMail();
        this.userName = storedName !== null ? storedName : '';
      });
  }

  async login(): Promise<void> {
    const redirectUrl = await this.getRedirectUrl();
    this.storeMail();
    this.auth.login(this.getTrimmedUserName(), redirectUrl);
  }

  private getTrimmedUserName(): string {
    return this.userName.trim();
  }

  private async redirect(): Promise<void> {
    const redirectUrl = await this.getRedirectUrl();
    this.router.navigate([redirectUrl]);
  }

  private async getRedirectUrl(): Promise<string> {
    return this.route.queryParams
      .pipe(
        take(1),
        map(params => params.redirectTo || this.defaultRedirectTo)
      )
      .toPromise();
  }

  private storeMail(): void {
    this.storageService.setItem('username', this.getTrimmedUserName());
  }

  private getStoredMail(): string {
    return this.storageService.getItem('username');
  }
}
