import { TermsInfos } from 'customer-portal-framework-lib';

export const termsVersion = 2;

export const termsInfosDe: TermsInfos[] = [
  {
    label: 'Angebotener Service',
    id: 'service',
    text: `Unser Service dient dem optimierten dienstleistungsorientierten
     Kommunikationsaustausch zwischen dem Dienstleister und den beteiligten Nutzern.`
  },
  {
    label: 'Nutzer',
    id: 'user',
    text: `„Nutzer“ im Sinne dieser Bedingungen sind alle per Zugangslink eingeladenen und
     angemeldeten berechtigten Personen. Aus Gründen der besseren Lesbarkeit wird auf die
     gleichzeitige Verwendung jeglicher Sprachformen verzichtet und gilt für alle Geschlechter.`
  },
  {
    label: 'Nutzungsberechtigung',
    id: 'allowance',
    text: 'Das Portal ist ausschließlich für personenbezogene Nutzer bestimmt.'
  },
  {
    label: 'Nutzungsvoraussetzung',
    id: 'condition',
    list: [
      'Die Nutzung vom Portal setzt eine Registrierung mittels eines Benutzernamens voraus.',
      `Als Benutzername ist eine gültige E-Mail-Adresse anzugeben, die auch zu Kommunikationszwecken
       genutzt wird. An diese E-Mail-Adresse werden die für die Freischaltung erforderlichen Zugangsdaten versandt.`,
      'Es besteht kein Anspruch auf eine Registrierung.'
    ]
  },
  {
    label: 'Sorgfaltspflichten des Nutzers',
    id: 'dutys',
    list: [
      `Der Nutzer ist für den Schutz seiner Zugriffsberechtigungen vollumfänglich verantwortlich
       und hat seine Zugangsdaten geheim zu halten. Es ist sicherzustellen, dass ein Dritter keine
       Kenntnis von den Zugangsdaten erhält, um einer missbräuchlichen Nutzung vorzubeugen.`,
      `Die persönlichen Zugangsdaten dürfen nicht notiert oder außerhalb des Online-Portals eingegeben
       werden. Eine elektronische Speicherung der Zugangsdaten darf nur verschlüsselt z.B. durch die
       Verwendung eines Passwortmanagers erfolgen. Bei Wechsel oder Veränderung der berechtigen Personen
       muss eine unverzügliche Information an persona service erfolgen.`
    ]
  },
  {
    label: 'Nutzungssperre',
    id: 'userlock',
    list: [
      `Bei Vorliegen eines wichtigen Grundes, insbesondere bei begründetem Verdacht, dass die Zugangsdaten
       des Nutzers unberechtigt durch Dritte genutzt werden oder eine Verletzung der Sorgfaltspflichten
       vorliegt, behalten wir uns vor, eine Zugangssperre einzurichten.`,
      `Die persönlichen Zugangsdaten dürfen nicht notiert oder außerhalb des Online-Portals eingegeben
       werden. Eine elektronische Speicherung der Zugangsdaten darf nur verschlüsselt z.B. durch die Verwendung
       eines Passwortmanagers erfolgen. Dies gilt auch, wenn er Anhaltspunkte für unbefugte Kenntnis dieser
       Daten durch einen Dritten besitzt.`
    ]
  },
  {
    label: 'Verfügbarkeit und Haftung',
    id: 'availability',
    list: [
      `Die Verfügbarkeit vom Portal kann aufgrund von Störungen von technischen Anlagen, Netzwerk- oder
       Telekommunikationsverbindungen, höherer Gewalt sowie für den reibungslosen Betriebsablauf erforderlichen
       Wartungsarbeiten oder sonstigen Umständen eingeschränkt oder zeitweise ausgeschlossen sein.`,
      `Wir als Unternehmen haftet nicht für Schäden, die durch Fehler, Verzögerungen oder Unterbrechungen in
       der Übermittlung, eingeschränkte oder ausgeschlossene Verfügbarkeit, bei Störung der technischen Anlagen
       und der Services, höherer Gewalt, Verlust oder Löschung von Daten oder die in sonstiger Weise beim Nutzer
       vom Portal entstehen können, es sei denn, dass solche Schäden vorsätzlich oder grob fahrlässig herbeigeführt
       werden.`
    ]
  },
  {
    label: 'Online-Dokumente',
    id: 'onlineDocuments',
    text: `Der Nutzer hat die Möglichkeit, für bestimmte Produkte auf den Erhalt von Vertragsdokumenten in Papierform
      zu verzichten.<br>Nutzt er diese Option, so erklärt er sich damit einverstanden, dass ihm ausgewählte Nachrichten und
      Dokumente ausschließlich online im Portal zur Verfügung gestellt werden, soweit keine gesetzlichen Regelungen oder
      vertragliche Vereinbarungen dem entgegenstehen. Ein Anspruch des Nutzers auf die Online-Bereitstellung bestimmter
      bzw. aller Dokumente zu einem konkreten Vertrag/Produkt besteht nicht.`
  },
  {
    label: 'Deaktivierung im Portal',
    id: 'deactivate',
    text: 'Der Nutzer kann die Deaktivierung seines Zugangs jederzeit bei persona service beantragen.'
  },
  {
    label: 'Änderung der Nutzungsbedingungen',
    id: 'changes',
    text: `Änderungen dieser Nutzungsbedingungen werden den berechtigen Personen vor Wirksamwerden in Textform zur Verfügung
    gestellt.`
  },
  {
    label: 'Anwendbarkeit deutschen Rechts',
    id: 'germanLaw',
    text: 'Es gilt deutsches Recht sowie die EU-Datenschutzgrundverordnung (EU DSGVO).'
  },
  {
    label: 'Hinweis',
    id: 'note',
    text: `Wir weisen ausdrücklich darauf hin, dass der Umgang mit den Daten der Mitarbeiter im Kundenbetrieb im Sinne einer
    Gleichbehandlung zu erfolgen hat.`
  }
];
