import { Injectable } from '@angular/core';
import { saveAs as save } from 'file-saver';
import { LoggerFactoryService } from 'src/app/service/logging/logger-factory.service';
import { ILogger } from 'src/app/service/logging/ilogger.interface';

const applicationPdf = 'application/pdf';

/**
 * Simple service to store files to the users computer or device.
 */
@Injectable()
export class FileSaverService {
  private readonly logger: ILogger;

  constructor(loggerFactory: LoggerFactoryService) {
    this.logger = loggerFactory.getDefaultLogger(FileSaverService.name);
  }

  /**
   * Stores the given blob to the filesystem of the user
   */
  saveAs(bin: Blob, fileName: string): void {
    try {
      const blob = this.resolveMimeType(bin, fileName);

      if (this.shouldOpenAsDataUrl()) {
        this.openDataUrl(blob);
      } else {
        save(blob, fileName);
      }
    } catch (ex) {
      this.logger.error('error saving file', ex);
    }
  }

  private openDataUrl(data: Blob): void {
    const url = window.URL.createObjectURL(data);
    window.open(url, '_blank');
  }

  private shouldOpenAsDataUrl(): boolean {
    return this.isChromeOnIOS();
  }

  private resolveMimeType(blob: Blob, fileName: string): Blob {
    if (blob.type !== applicationPdf && fileName.endsWith('.pdf')) {
      return new Blob([blob], { type: applicationPdf });
    }
    return blob;
  }

  private isSafariOnIOS(): boolean {
    return (
      this.isAppleHardwareAgent() && this.isWebKitAgent() && !this.isChrome()
    );
  }

  private isChromeOnIOS(): boolean {
    return (
      this.isAppleHardwareAgent() && this.isWebKitAgent() && this.isChrome()
    );
  }

  private isChrome(): boolean {
    return /CriOS/i.test(window.navigator.userAgent);
  }

  private isAppleHardwareAgent(): boolean {
    return /(iPad|iPhone|iPod|Macintosh)/gi.test(window.navigator.userAgent);
  }

  private isWebKitAgent(): boolean {
    return /WebKit/i.test(window.navigator.userAgent);
  }
}
