import { Injectable } from '@angular/core';
import { PermissionService } from './permission.service';
import { Observable, of, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IfPermittedService {
  constructor(private readonly permissionService: PermissionService) {}

  getPermissionByPath(permissionPath: string): Observable<boolean> {
    const [scope, entity, right] = permissionPath.split('.');

    if (scope === 'customer') {
      return this.permissionService
        .getCurrentCustomerPermission()
        .pipe(this.checkHasPermission(entity, right));
    } else if (scope === 'object') {
      return this.permissionService
        .getCurrentObjectPermission()
        .pipe(this.checkHasPermission(entity, right));
    }

    return of(undefined);
  }

  private checkHasPermission(
    entity: string,
    right: string
  ): OperatorFunction<any, boolean> {
    return map(permissions => {
      const permissionEntity = permissions[entity];
      const permissionValue = permissionEntity && permissionEntity[right];
      return permissionValue;
    });
  }
}
