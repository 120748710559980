import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  debounceTime,
  switchMap,
  take,
  tap,
  catchError
} from 'rxjs/operators';
import { User } from 'src/app/dto/user.dto';
import { ExternalUserService } from 'src/app/service/external-user/external-user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserCreateModalComponent } from '../user-create-modal/user-create-modal.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NavigationStateService } from 'src/app/service/navigation-state/navigation-state.service';

@Component({
  selector: 'isd-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit {
  users$: Observable<User[]>;
  searchInput$ = new Subject<string>();
  isCurrentlySearching = false;

  selectedUser: User;

  @Output() readonly userChange = new EventEmitter<User>();
  @Input() disabled = false;

  @Input() set unselect$(unselect$: Subject<undefined>) {
    if (unselect$) {
      unselect$.subscribe(() => this.unselectUser());
    }
  }

  @Input() set useCachedUsers(useCachedUsers: boolean) {
    this._useCachedUsers = useCachedUsers;
  }

  /**
   * Sets the select reference each time *ngIf is set to 'true'
   */
  @ViewChild('select') set selectChildComponent(
    elementRef: NgSelectComponent
  ) {
    if (elementRef) {
      this.selectRef = elementRef;
    }
  }

  private _useCachedUsers = false;
  private selectRef: NgSelectComponent;

  constructor(
    private readonly externalUserService: ExternalUserService,
    private readonly navigationState: NavigationStateService,
    private readonly modalService: NgbModal
  ) {}

  ngOnInit(): void {
    if (!this.users$) {
      this.users$ = this.search$;
    }
  }

  onSelectionChange(): void {
    this.blurSelect();
    this.userChange.emit(this.selectedUser);
  }

  async create(): Promise<void> {
    const modalRef = this.modalService.open(UserCreateModalComponent);

    try {
      const user = await modalRef.result;
      if (user) {
        this.userChange.emit(user);
      }
    } catch {}

    this.blurSelect();
  }

  private blurSelect(): void {
    setTimeout(() => {
      this.selectRef.blur();
    }, 1);
  }

  private unselectUser(): void {
    this.selectedUser = undefined;
    this.blurSelect();
  }

  private get search$(): Observable<User[]> {
    return this.searchInput$.pipe(
      distinctUntilChanged(),
      tap(() => (this.isCurrentlySearching = true)),
      debounceTime(500),
      switchMap(searchFragment => this.searchForUser(searchFragment)),
      tap(() => (this.isCurrentlySearching = false))
    );
  }

  private searchForUser(searchFragment: string): Observable<User[]> {
    if (this._useCachedUsers && !searchFragment) {
      return this.externalUserService.recentSearchResults$.pipe(take(1));
    }

    return this.navigationState.getCustomerZoneId().pipe(
      take(1),
      switchMap(customerZoneId =>
        this.externalUserService.searchForUser(searchFragment, customerZoneId)
      )
    );
  }
}
