import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tap, catchError } from 'rxjs/operators';
import { MonoTypeOperatorFunction, empty } from 'rxjs';
import { ErrorHandlingMap } from './error-object.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  private defaultErrorMap: ErrorHandlingMap;
  private defaultErrorMessage = 'Something went wrong';
  constructor(
    translateService: TranslateService,
    private readonly toastr: ToastrService
  ) {
    translateService.get('HTTP.ERRORS').subscribe(errors => {
      this.defaultErrorMap = {
        400: errors['400'],
        401: errors['401'],
        403: errors['403'],
        404: errors['404'],
        500: errors['500']
      };
      this.defaultErrorMessage = errors.defaultError;
    });
  }

  /**
   * Shows a toastr message based on error codes. Can be configured by inputting custom error messages.
   * The error is not catched. The observable will continue.
   * Example:
   * ```
   * this.exService.getCustomerZones()
   *  .pipe(this.errorHandler.pipeHandleError({404: translateService.instant("CUSTOMER.ERRORS.NOT_FOUND")}))
   *  .subscribe(this.data$);
   * ```
   *
   * @param errorMap Map of error messages
   * @param defaultErrorMessage The default message if no error code could get recognized in the map
   */
  pipeHandleError<T>(
    errorMap?: ErrorHandlingMap,
    defaultErrorMessage?: string
  ): MonoTypeOperatorFunction<T> {
    return tap(
      () => undefined,
      e => this.handleError(e, errorMap, defaultErrorMessage)
    );
  }

  /**
   * Shows a toastr message based on error codes. Can be configured by inputting custom error messages.
   * Stops the error from propagating. The Observable will not continue.
   * Example:
   * ```
   * this.exService.getCustomerZones()
   *  .pipe(this.errorHandler.pipeHandleError({404: translateService.instant("CUSTOMER.ERRORS.NOT_FOUND")}))
   *  .subscribe(this.data$);
   * ```
   *
   * @param errorMap Map of error messages
   * @param defaultErrorMessage The default message if no error code could get recognized in the map
   */
  pipeCatchError<T>(
    errorMap?: ErrorHandlingMap,
    defaultErrorMessage?: string
  ): MonoTypeOperatorFunction<T> {
    return catchError(e => {
      this.handleError(e, errorMap, defaultErrorMessage);
      return empty();
    });
  }

  /**
   * Shows a toastr message based on error codes. Can be configured by inputting custom error messages.
   * Example:
   * ```
   *  this.errorHandler.handleError({404: translateService.instant("CUSTOMER.ERRORS.NOT_FOUND")})
   * ```
   *
   * @param error HttpErrorResponse
   * @param errorMap Map of error messages
   * @param defaultErrorMessage The default message if no error code could get recognized in the map
   */
  handleError(
    error: HttpErrorResponse,
    errorMap?: ErrorHandlingMap,
    defaultErrorMessage?: string
  ): void {
    const errorMessage = this.getErrorMessage(
      errorMap,
      error.status,
      defaultErrorMessage
    );
    this.showError(errorMessage);
  }

  private getErrorMessage(
    errorMap: ErrorHandlingMap,
    foundErrorCode: number,
    defaultErrorMessage?: string
  ): string {
    return (
      (errorMap && errorMap[foundErrorCode]) ||
      defaultErrorMessage ||
      this.defaultErrorMap[foundErrorCode] ||
      this.defaultErrorMessage
    );
  }

  private showError(errorString: string): void {
    this.toastr.error(errorString);
  }
}
