<div class="left-bar level-{{ level }} pr-2 pl-2 pb-3 pt-3">
  <div [class.h4]="level === 1" [class.h5]="level === 2">
    <i
      (click)="toggleUserRoles()"
      class="fas mr-2 cursor-pointer hover-opacity-2"
      [class.fa-angle-up]="toggled"
      [class.fa-angle-down]="!toggled"
    ></i>
    <i class="fas mr-1" [class.fa-home]="level === 2"></i>
    {{ zone.name }}
  </div>
  <ng-container *ngIf="toggled">
    <isd-user-search
      (userChange)="selectUser($event)"
      [unselect$]="resetSearch$"
    ></isd-user-search>
    <isd-loading-spinner-block
      [height]="'100px'"
      *cpLoading="loadingKey"
    ></isd-loading-spinner-block>
    <div
      [hidden]="loadingService.isLoading(loadingKey) | async"
      *ngIf="userRoles$ | async as userRoles"
      class="mt-2"
    >
      <div *ngIf="userRoles.length === 0" class="text-muted">
        {{ 'ZONE.NO_USERROLES_FOUND' | translate }}
      </div>
      <div *ngFor="let userRole of userRoles; let last = last" class="d-flex">
        <button
          class="btn btn-small text-danger hover-opacity-2 align-top pt-0 align-self-start"
          (click)="removeRolemembership(userRole.roles)"
        >
          <i class="fas fa-minus-circle"></i>
        </button>
        <div class="d-inline-block" [class.mb-2]="!last">
          <a [routerLink]="" (click)="selectUser(userRole.user)">{{
            userRole.user.displayName
          }}</a>
          <span class="text-muted"> | </span>
          <span class="text-muted">{{ userRole.user.mail }}</span>
          <br />
          <span class="comma-seperated">
            <span class="text-muted" *ngFor="let role of userRole.roles">{{
              getRole(role.roleId).name
            }}</span>
          </span>
        </div>
      </div>
    </div>
  </ng-container>
</div>
