// tslint:disable: prefer-inline-decorator
import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  CalendarOptions,
  DatesSetArg,
  EventContentArg,
  EventInput
} from '@fullcalendar/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'customer-portal-framework-lib';
import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import { merge, MonoTypeOperatorFunction, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import { CalendarImageModalComponent } from '../calendar-image-modal/calendar-image-modal.component';
import { CalendarEventService } from './calendar-event.service';
import { DateRange } from './date-range.dto';
import { Month } from './month.dto';
dayjs.extend(isoWeek);
@Component({
  selector: 'isd-calendar-modal',
  templateUrl: './calendar-modal.component.html',
  styleUrls: ['./calendar-modal.component.scss'],
  providers: [CalendarEventService]
})
export class CalendarModalComponent {
  calendarEvents$: Observable<EventInput[]>;
  calendarChange$ = new Subject<Month>();
  calendarOptions$: Observable<CalendarOptions>;
  calendarOptions: CalendarOptions;

  nextButton: Element;
  prevButton: Element;

  @ViewChild('calendarRef', { read: ElementRef })
  fullCalendar: ElementRef;

  loadingServiceEventsKey = 'loadingServiceEventsKey';

  minDate: dayjs.Dayjs;
  maxDate: dayjs.Dayjs;

  constructor(
    private readonly translate: TranslateService,
    private readonly ngbModal: NgbModal,
    readonly calendarEventsService: CalendarEventService,
    readonly loadingService: LoadingService,
    readonly activeModal: NgbActiveModal
  ) {
    this.initCalendarOptions();

    this.calendarEvents$ = this.calendarChange$.pipe(
      debounceTime(500),
      distinctUntilChanged((a, b) => this.isSameMonthYear(a, b)),
      this.pipeStartLoading(),
      switchMap(monthYear => this.loadEventsForMonthYear(monthYear)),
      this.pipeStopLoading()
    );

    this.calendarOptions$ = merge(
      of(this.calendarOptions),
      this.calendarEvents$.pipe(
        map(events => ({
          ...this.calendarOptions,
          events
        }))
      )
    );
  }

  onDatesSet(datesSetArg: DatesSetArg): void {
    const currentStart = datesSetArg.start;
    currentStart.setDate(currentStart.getDate() + 8);
    this.cacheNavigationButtons();
    this.calendarChange$.next({
      month: currentStart.getMonth(),
      year: currentStart.getFullYear()
    });
  }

  loadEventsForMonthYear(monthYear: Month): Observable<EventInput[]> {
    const dateRange = this.getDateRangeForRequest(monthYear);
    return this.calendarEventsService.loadEventsByDateRange(dateRange);
  }

  private initCalendarOptions(): void {
    this.calendarOptions = {
      locale: 'de',
      firstDay: 1,
      height: 700,
      headerToolbar: {
        left: 'title',
        center: '',
        right: 'prev next'
      },
      weekNumbers: true,
      weekText: this.translate.instant('OBJECT.CALENDAR.WEEKLABEL'),
      initialView: 'dayGridMonth',
      events: [],
      validRange: {
        start: new Date(),
        end: new Date()
      },
      datesSet: this.onDatesSet.bind(this),
      eventContent: arg => this.renderEventContent(arg)
    };

    this.calendarOptions.validRange = this.initValidDateRange();
  }

  private renderEventContent(args: EventContentArg): { domNodes: Element[] } {
    const imageUrl = args.event.extendedProps.imageUrl;

    const div = document.createElement('div');
    div.className = 'mx-1 d-flex';

    if (imageUrl) {
      div.classList.add('cursor-pointer');
      div.dataset['title'] = args.event.title;
      div.dataset['imageUrl'] = imageUrl;
      div.onclick = () => {
        this.openCalendarModal(div);
      };
      div.innerHTML = `<span class="position-absolute" style="right: 4px;" target="isd_img" href="${imageUrl}" title="Bild anzeigen"><i class="fas fa-image"></i></span>
        <span class="text-truncate" style="padding-right: 18px" title="${args.event.title}">${args.event.title}</span>`;
    } else {
      div.innerHTML = `<span class="text-truncate" title="${args.event.title}">${args.event.title}</span>`;
    }

    return { domNodes: [div] };
  }

  openCalendarModal(div: HTMLElement): void {
    const modalRef = this.ngbModal.open(CalendarImageModalComponent, {
      size: 'xl',
      centered: true
    });

    var modal = modalRef.componentInstance as CalendarImageModalComponent;
    modal.setContent(div.dataset['title'], div.dataset['imageUrl']);
  }

  private cacheNavigationButtons(): void {
    this.nextButton = this.fullCalendar.nativeElement.querySelector(
      '.fc-next-button'
    ) as Element;

    this.prevButton = this.fullCalendar.nativeElement.querySelector(
      '.fc-prev-button'
    ) as Element;
  }

  private initValidDateRange(): DateRange {
    this.minDate = dayjs()
      .subtract(1, 'year')
      .month(0)
      .date(0)
      .add(1, 'day')
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);

    this.maxDate = dayjs().add(1, 'month');
    this.maxDate = this.maxDate
      .date(this.maxDate.daysInMonth())
      .hour(23)
      .minute(59)
      .second(59)
      .millisecond(999);

    return {
      start: this.minDate.toDate(),
      end: this.maxDate.toDate()
    };
  }

  private pipeStartLoading<T>(): MonoTypeOperatorFunction<T> {
    return tap(() => {
      this.nextButton.classList.add('disabled');
      this.prevButton.classList.add('disabled');
      this.loadingService.startLoading(this.loadingServiceEventsKey);
    });
  }

  private pipeStopLoading<T>(): MonoTypeOperatorFunction<T> {
    return tap(() => {
      this.nextButton.classList.remove('disabled');
      this.prevButton.classList.remove('disabled');
      this.loadingService.stopLoading(this.loadingServiceEventsKey);
    });
  }

  private getDateRangeForRequest(monthYear: Month, monthOffset = 1): DateRange {
    let startDate = this.getStartOfMonth({
      month: monthYear.month - monthOffset,
      year: monthYear.year
    });

    let endDate = this.getEndOfMonth({
      month: monthYear.month + monthOffset,
      year: monthYear.year
    });

    if (startDate.isBefore(this.minDate)) {
      startDate = this.minDate;
    }

    if (endDate.isAfter(this.maxDate)) {
      endDate = this.maxDate;
    }

    return {
      start: startDate.toDate(),
      end: endDate.toDate()
    };
  }

  private getEndOfMonth(monthYear: Month) {
    let endOfMonth = dayjs(
      new Date(monthYear.year, monthYear.month, 1, 23, 59, 59, 999)
    );
    endOfMonth = endOfMonth.date(endOfMonth.daysInMonth());
    return endOfMonth;
  }

  private getStartOfMonth(monthYear: Month) {
    let startOfMonth = dayjs(
      new Date(monthYear.year, monthYear.month, 1, 0, 0, 0, 0)
    );
    return startOfMonth;
  }

  private isSameMonthYear(
    firstMonthYear: Month,
    secondMonthYear: Month
  ): boolean {
    return (
      firstMonthYear.month === secondMonthYear.month &&
      firstMonthYear.year === secondMonthYear.year
    );
  }
}
