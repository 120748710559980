import { Component, OnInit, Input } from '@angular/core';
import { Zone } from 'src/app/dto/zone.dto';
import { User } from 'src/app/dto/user.dto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleAssignmentModalComponent } from '../role-assignment-modal/role-assignment-modal.component';
import { Role } from 'src/app/dto/role.dto';
import {
  RolemembershipService,
  RoleMembershipDiff
} from '../../service/rolemembership.service';
import { RoleMembership } from 'src/app/dto/role-membership.dto';
import { Observable, noop, ReplaySubject as Subject, merge } from 'rxjs';
import { map, take, shareReplay, tap } from 'rxjs/operators';
import { LoadingService } from 'customer-portal-framework-lib';
import { UserRoles } from 'src/app/dto/user-roles.dto';
import { UserRolesService } from '../../service/user-roles.service';
import { ErrorHandlerService } from 'src/app/service/error-handler/error-handler.service';

@Component({
  selector: 'isd-zone-rolemembership',
  templateUrl: './zone-rolemembership.component.html',
  styleUrls: ['./zone-rolemembership.component.scss']
})
export class ZoneRolemembershipComponent implements OnInit {
  @Input() zone: Zone;
  @Input() level: number;
  @Input() availableRoles: Role[];
  @Input() toggled = false;

  roleMembership$: Observable<RoleMembership[]>;
  loadingKey: string;
  resetSearch$ = new Subject<undefined>();

  private readonly roleMembershipListSubject = new Subject<RoleMembership[]>(1);

  constructor(
    readonly loadingService: LoadingService,
    private readonly modalService: NgbModal,
    private readonly roleMembershipService: RolemembershipService,
    private readonly userRolesService: UserRolesService,
    private readonly errorHandler: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.loadingKey = `roleMembership_Zone${this.zone.id}`;

    this.loadingService.startLoading(this.loadingKey);
    this.roleMembership$ = merge(
      this.roleMembershipListSubject.asObservable(),
      this.loadRoleMembershipOnce()
    ).pipe(
      this.errorHandler.pipeCatchError(),
      this.loadingService.pipeStopLoading(this.loadingKey),
      shareReplay(1)
    );
  }

  get userRoles$(): Observable<UserRoles[]> {
    return this.roleMembership$.pipe(this.userRolesService.toUserRolesPipe());
  }

  async selectUser(user: User): Promise<void> {
    if (!user) {
      return;
    }

    try {
      const roleSelection = await this.openRoleSelectionModal(user);
      this.resetSearch$.next();
      await this.processNewRoleSelection(roleSelection, user);
    } catch {
      this.resetSearch$.next();
    }
  }

  getRole(roleId: number): Role {
    return this.availableRoles.find(r => r.id === roleId);
  }

  toggleUserRoles(): void {
    this.toggled = !this.toggled;
  }

  async removeRolemembership(
    roleMembershipToDelete: RoleMembership[]
  ): Promise<void> {
    const diff: RoleMembershipDiff = {
      created: [],
      deleted: roleMembershipToDelete
    };

    await this.processDiff(diff);
  }

  private async openRoleSelectionModal(user: User): Promise<Role[]> {
    const modalRef = this.modalService.open(RoleAssignmentModalComponent);
    const component = modalRef.componentInstance as RoleAssignmentModalComponent;

    component.zone = this.zone;
    component.user = user;
    component.availableRoles = this.availableRoles;
    component.selectedRoles = await this.getCurrentUserRoles(user.id);

    return modalRef.result;
  }

  private async processNewRoleSelection(
    newRoleSelection: Role[],
    user: User
  ): Promise<void> {
    const userRoleMembershipList = await this.getCurrentUserRoleMembershipList(
      user.id
    );

    const diff = this.roleMembershipService.generateDiff(
      userRoleMembershipList,
      newRoleSelection,
      user,
      this.zone.id
    );

    await this.processDiff(diff);
  }

  private async processDiff(diff: RoleMembershipDiff): Promise<void> {
    this.loadingService.startLoading(this.loadingKey);
    try {
      await this.roleMembershipService.sendRoleMembershipDiffToServer(diff);
      this.updateDisplayedRoleMembership(diff);
    } catch (error) {
      this.errorHandler.handleError(error);
      this.loadRoleMembershipOnce().subscribe(rmList =>
        this.roleMembershipListSubject.next(rmList)
      );
    }
  }

  private async updateDisplayedRoleMembership(
    diff: RoleMembershipDiff
  ): Promise<void> {
    const current = [...(await this.roleMembership$.pipe(take(1)).toPromise())];

    current.unshift(...diff.created);
    for (const roleMembership of diff.deleted) {
      const idx = current.indexOf(roleMembership);
      current.splice(idx, 1);
    }

    this.roleMembershipListSubject.next(current);
  }

  private async getCurrentUserRoles(userId: string): Promise<Role[]> {
    const roleMembership = await this.getCurrentUserRoleMembershipList(userId);
    return roleMembership.map(rm => this.getRole(rm.roleId));
  }

  private async getCurrentUserRoleMembershipList(
    userId: string
  ): Promise<RoleMembership[]> {
    return this.roleMembership$
      .pipe(
        take(1),
        map(list => list.filter(r => r.user.id === userId))
      )
      .toPromise();
  }

  private loadRoleMembershipOnce(): Observable<RoleMembership[]> {
    return this.roleMembershipService
      .getRolemembershipByZone(this.zone.id)
      .pipe(take(1), this.errorHandler.pipeHandleError());
  }
}
