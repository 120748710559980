import { Component, OnInit } from '@angular/core';
import {
  RoleDefinition,
  RoleDefinitionContainer
} from 'src/app/dto/role-definition.dto';
import { Observable } from 'rxjs';
import { RoleDefinitionService } from '../../service/role-definition.service';
import { NavigationStateService } from 'src/app/service/navigation-state/navigation-state.service';
import { switchMap, map } from 'rxjs/operators';
import { LoadingService } from 'customer-portal-framework-lib';
import { ErrorHandlerService } from 'src/app/service/error-handler/error-handler.service';
import { ZoneType } from 'src/app/dto/enums/zone-type.dto';

@Component({
  selector: 'isd-role-definition-overview',
  templateUrl: './role-definition-overview.component.html',
  styleUrls: ['./role-definition-overview.component.scss']
})
export class RoleDefinitionOverviewComponent implements OnInit {
  roleDefinitions$: Observable<RoleDefinitionContainer>;
  loadingRoleDefinitionsKey = 'LoadingRoleDefinitionsKey';

  constructor(
    private readonly roleDefinitionservice: RoleDefinitionService,
    private readonly navigationState: NavigationStateService,
    readonly loadingService: LoadingService,
    private readonly errorHandler: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.roleDefinitions$ = this.loadRoleDefinitions();
  }

  private loadRoleDefinitions(): Observable<RoleDefinitionContainer> {
    this.loadingService.startLoading(this.loadingRoleDefinitionsKey);
    return this.navigationState.getCustomerZoneId().pipe(
      this.errorHandler.pipeHandleError(),
      switchMap(customerZoneId =>
        this.roleDefinitionservice
          .getRoleDefinitionsByCustomerZoneId(customerZoneId)
          .pipe(
            this.errorHandler.pipeHandleError(),
            map(roleDefinitions => this.splitRolesByScope(roleDefinitions))
          )
      ),
      this.loadingService.pipeStopLoading(this.loadingRoleDefinitionsKey)
    );
  }

  private splitRolesByScope(
    roleDefinitions: RoleDefinition[]
  ): RoleDefinitionContainer {
    const objectRoles = this.getObjectRoles(roleDefinitions);
    const customerRoles = this.getCustomerRoles(roleDefinitions);

    return {
      objectRoles: this.sortRolesAlphabetically(objectRoles),
      customerRoles: this.sortRolesAlphabetically(customerRoles)
    };
  }

  private getCustomerRoles(allRoles: RoleDefinition[]): RoleDefinition[] {
    return allRoles.filter(rd => rd.roleTypeId === ZoneType.CustomerZone);
  }

  private getObjectRoles(allRoles: RoleDefinition[]): RoleDefinition[] {
    return allRoles.filter(rd => rd.roleTypeId === ZoneType.ObjectZone);
  }

  private sortRolesAlphabetically(
    roleDefinitions: RoleDefinition[]
  ): RoleDefinition[] {
    return roleDefinitions.sort((a, b) => a.roleName.localeCompare(b.roleName));
  }
}
