import { Component, OnInit, Input } from '@angular/core';
import { ObjectReport } from './object-report.dto';
import { ObjectLocationDataService } from '../../object-location-data.service';
import { FileSaverService } from 'src/app/file-transfer/services/file-saver.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'isd-object-reports',
  templateUrl: './object-reports.component.html',
  styleUrls: ['./object-reports.component.scss']
})
export class ObjectReportsComponent {
  lastObjectReport: ObjectReport;
  remainingObjectReports: ObjectReport[] = [];

  @Input() set objectReports(value: ObjectReport[]) {
    [this.lastObjectReport, ...this.remainingObjectReports] = value
      ? value
      : [undefined];
  }

  constructor(
    private readonly objectLocationDataService: ObjectLocationDataService,
    private readonly fileSaver: FileSaverService,
    private readonly translate: TranslateService
  ) {}

  hasObjectReports(): boolean {
    return !!this.lastObjectReport;
  }

  hasMoreReports(): boolean {
    return this.remainingObjectReports.length > 0;
  }

  async downloadReport(report: ObjectReport): Promise<void> {
    const binaryData = await this.objectLocationDataService
      .downloadObjectReport(report.url)
      .toPromise();

    const fileName = this.generateReportFileName(report);
    this.fileSaver.saveAs(binaryData, fileName);
  }

  private generateReportFileName(report: ObjectReport): string {
    const suffix = this.translate.instant('OBJECT.REPORTS_FILENAME_SUFFIX');
    return `${suffix}-${report.date}.pdf`;
  }
}
