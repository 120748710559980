<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'ROLE.ROLEASSIGNMENT' | translate }}
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col">
      <p>
        {{ 'ROLE.ROLESFOR' | translate }} <b>{{ user.displayName }}</b>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <ng-select
        [items]="availableRoles"
        bindLabel="name"
        multiple="true"
        [placeholder]="'ROLE.SELECT_ROLES' | translate"
        [(ngModel)]="selectedRoles"
        [clearable]="false"
      >
      </ng-select>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="modal.dismiss()">
    {{ 'COMMON.BUTTON.CANCEL' | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="save()">
    {{ 'COMMON.BUTTON.SAVE' | translate }}
  </button>
</div>
