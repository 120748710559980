<div class="row mb-2">
  <div class="col-9 col-md-10">
    <div class="d-flex justify-content-between">
      <div class="h6">
        {{ title }}
      </div>
      <isd-info-popup [popupTemplate]="descriptionTmpl"></isd-info-popup>
      <ng-template #descriptionTmpl>
        <div>{{ description }}</div>
      </ng-template>
    </div>
  </div>
  <div class="col-3 col-md-2 text-center">
    <cp-checkbox
      [state]="checked"
      (stateChange)="checkedChange.emit($event)"
      [disabled]="disabled"
    ></cp-checkbox>
  </div>
</div>
