<ng-container *ngIf="hasPermission">
  <cp-file-system-breadcrumb
    class="h4"
    [items]="breadcrumbItems$ | async"
    (itemClicked)="openBreadcrumbItem($event)"
  ></cp-file-system-breadcrumb>

  <isd-loading-spinner-overlay [loadingKey]="loadingKey">
    <div>
      <ng-container
        *ngIf="isRootActive()"
        [ngTemplateOutlet]="rootFiles"
      ></ng-container>

      <ng-container
        *ngIf="isContractsFolderActive()"
        [ngTemplateOutlet]="contractFiles"
      >
      </ng-container>

      <ng-container
        *ngIf="isInvoicesFolderActive()"
        [ngTemplateOutlet]="invoiceFiles"
      >
      </ng-container>
    </div>
  </isd-loading-spinner-overlay>
</ng-container>

<ng-template #rootFiles>
  <div class="row px-2">
    <div class="col">
      <div class="row text-muted">
        <div class="col">
          <small>{{ 'OBJECT.DOCUMENTS.DOCUMENT_NAME' | translate }}</small>
        </div>
      </div>
    </div>
  </div>
  <cp-file-system-folder
    [fileSystemItems]="fileSystemItems$ | async"
    (fileSystemItemClicked)="fileSystemItemClicked($event)"
  ></cp-file-system-folder>
</ng-template>

<ng-template #contractFiles>
  <div class="row px-2">
    <div class="col">
      <div class="row text-muted">
        <div class="col-8 col-sm-9 col-md-10">
          <small>{{ 'OBJECT.DOCUMENTS.DOCUMENT_NAME' | translate }}</small>
        </div>
        <div class="col-4 col-sm-3 col-md-2">
          <small>{{ 'OBJECT.DOCUMENTS.DATE' | translate }}</small>
        </div>
      </div>
    </div>
  </div>
  <cp-file-system-folder
    [fileSystemItems]="fileSystemItems$ | async"
    (fileSystemItemClicked)="fileSystemItemClicked($event)"
  >
    <ng-template cpFileItemTmp let-item let-iconTmp="iconTmp">
      <div class="row">
        <div class="col-8 col-sm-9 col-md-10">
          <span class="d-block text-truncate" [title]="item.label">
            <ng-container *ngTemplateOutlet="iconTmp"></ng-container>
            <span class="ml-1 align-middle">{{ item.label }}</span>
          </span>
        </div>
        <div class="align-middle col-4 col-sm-3 col-md-2">
          {{ item.meta.date }}
        </div>
      </div>
    </ng-template>
  </cp-file-system-folder>
</ng-template>

<ng-template #invoiceFiles>
  <div class="row px-2">
    <div class="col">
      <div class="row text-muted">
        <div class="col-8 col-sm-7 col-md-6">
          <small>{{ 'OBJECT.DOCUMENTS.DOCUMENT_NAME' | translate }}</small>
        </div>
        <div class="col-4 col-sm-3 col-md-2">
          <small>{{ 'OBJECT.DOCUMENTS.DATE' | translate }}</small>
        </div>
        <div class="d-none d-md-block col-sm-2">
          <small>{{ 'OBJECT.DOCUMENTS.DOCUMENT_TYPE' | translate }}</small>
        </div>
        <div class="d-none d-sm-block col-sm-2">
          <small>{{ 'OBJECT.DOCUMENTS.INVOICE_AMOUNT' | translate }}</small>
        </div>
      </div>
    </div>
  </div>
  <cp-file-system-folder
    *ngIf="isInvoicesFolderActive()"
    [fileSystemItems]="fileSystemItems$ | async"
    (fileSystemItemClicked)="fileSystemItemClicked($event)"
  >
    <ng-template cpFileItemTmp let-item let-iconTmp="iconTmp">
      <div class="row">
        <div class="col-8 col-sm-7 col-md-6">
          <span class="d-block text-truncate" [title]="item.label">
            <ng-container *ngTemplateOutlet="iconTmp"></ng-container>
            <span class="ml-1 align-middle">{{ item.label }}</span>
          </span>
        </div>
        <div class="align-middle col-4 col-sm-3 col-md-2">
          {{ item.meta.date }}
        </div>
        <div class="align-middle d-none d-md-block col-sm-2">
          {{ item.meta.type }}
        </div>
        <div class="align-middle d-none d-sm-block col-sm-2">
          {{ item.meta.amount | currency: 'EUR' }}
        </div>
      </div>
    </ng-template>
  </cp-file-system-folder>
</ng-template>
