import { Injectable } from '@angular/core';
import {
  NavigationState,
  EnrichedNavigationState
} from './navigation-state.dto';
import { Observable, of, merge, MonoTypeOperatorFunction } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {
  take,
  map,
  shareReplay,
  filter,
  switchMap,
  distinctUntilChanged
} from 'rxjs/operators';
import { ZoneService } from '../zone/zone.service';

const customerZoneSegment = 'customer';
const customerZoneIdParam = 'customerZoneId';
const objectLocationSegment = 'object';
const objectLocationIdParam = 'objectLocationId';

/**
 * This service handles storing the current NavigationState inside the url
 */
@Injectable({
  providedIn: 'root'
})
export class NavigationStateService {
  private readonly state$: Observable<NavigationState>;
  private readonly enrichedState$: Observable<EnrichedNavigationState>;

  constructor(
    private readonly router: Router,
    private readonly zoneService: ZoneService
  ) {
    this.state$ = this.onPageloadAndOnNavigationEnd().pipe(
      switchMap(() => this.getStateFromUrl()),
      distinctUntilChanged((x, y) => this.areStatesEqual(x, y)),
      shareReplay(1)
    );

    this.enrichedState$ = this.state$.pipe(
      switchMap(state => this.enrichState(state)),
      distinctUntilChanged((x, y) => this.areStatesEqual(x, y)),
      shareReplay(1)
    );
  }

  get(): Observable<NavigationState> {
    return this.state$;
  }

  getEnriched(): Observable<EnrichedNavigationState> {
    return this.enrichedState$;
  }

  set(nextState: NavigationState): void {
    this.setUrlFromState(nextState);
  }

  getCustomerZoneId(): Observable<number> {
    return this.state$.pipe(
      map(state => state.customerZoneId),
      distinctUntilChanged()
    );
  }

  getObjectLocationId(): Observable<string> {
    return this.state$.pipe(
      filter(state => state.objectLocationId !== undefined),
      map(state => state.objectLocationId),
      distinctUntilChanged()
    );
  }

  getCustomerNumber(): Observable<number> {
    return this.enrichedState$.pipe(
      filter(state => state.customerNumber !== undefined),
      map(state => state.customerNumber),
      distinctUntilChanged()
    );
  }

  getObjectZoneId(): Observable<number> {
    return this.enrichedState$.pipe(
      map(state => state.objectZoneId),
      distinctUntilChanged()
    );
  }

  private onPageloadAndOnNavigationEnd(): Observable<any> {
    return merge(
      of(1),
      this.router.events.pipe(
        filter(e => e instanceof NavigationEnd),
        this.pipeIgnoreForbbidenPage()
      )
    );
  }

  private pipeIgnoreForbbidenPage(): MonoTypeOperatorFunction<NavigationEnd> {
    return filter(e => e.urlAfterRedirects.indexOf('forbidden') < 0);
  }

  private areStatesEqual(
    stateX: NavigationState,
    stateY: NavigationState
  ): boolean {
    return (
      stateX.customerZoneId === stateY.customerZoneId &&
      stateX.objectLocationId === stateY.objectLocationId
    );
  }

  private enrichState(
    state: NavigationState
  ): Observable<EnrichedNavigationState> {
    if (state.customerZoneId === undefined) {
      return of(
        this.createEnrichedState(
          state,
          undefined,
          undefined,
          undefined,
          undefined
        )
      );
    }

    return this.zoneService.getCustomerZoneByZoneId(state.customerZoneId).pipe(
      switchMap(cz => {
        if (!cz) {
          return of(this.createEnrichedState(state, undefined));
        }

        if (!state.objectLocationId) {
          return of(this.createEnrichedState(state, cz.customerNumber));
        }

        return this.zoneService.getObjectLocations(cz.zoneId).pipe(
          map(locations => {
            const objectLocation = locations.find(
              d => d.id === state.objectLocationId
            );

            return this.createEnrichedState(
              state,
              cz.customerNumber,
              objectLocation && objectLocation.objectNumber,
              objectLocation && objectLocation.dispoObjectNumber,
              objectLocation && objectLocation.zoneId
            );
          })
        );
      })
    );
  }

  private createEnrichedState(
    state: NavigationState,
    customerNumber: number,
    objectNumber?: number,
    dispoObjectNumber?: string,
    objectZoneId?: number
  ): EnrichedNavigationState {
    return {
      customerNumber,
      customerZoneId: state.customerZoneId,
      objectLocationId: state.objectLocationId,
      objectNumber,
      dispoObjectNumber,
      objectZoneId
    };
  }

  private setUrlFromState(state: NavigationState): void {
    const targetUrl = this.generateRouteableUrl(state);
    this.router.navigate(targetUrl);
  }

  private generateRouteableUrl(currentState: NavigationState): any[] {
    if (!currentState.customerZoneId) {
      return [''];
    }

    const targetUrlSegments = [
      customerZoneSegment,
      currentState.customerZoneId
    ];
    if (currentState.objectLocationId) {
      targetUrlSegments.push(
        objectLocationSegment,
        currentState.objectLocationId
      );
    }

    return targetUrlSegments;
  }

  private getStateFromUrl(): Observable<NavigationState> {
    const route = this.getActiveRoute();

    return route.paramMap.pipe(
      take(1),
      map(paramMap => {
        const customerZoneIdValue = paramMap.get(customerZoneIdParam);
        const objectLocationIdValue = paramMap.get(objectLocationIdParam);

        return {
          customerZoneId: customerZoneIdValue
            ? parseInt(customerZoneIdValue, 10)
            : undefined,
          objectLocationId: objectLocationIdValue
            ? objectLocationIdValue
            : undefined
        };
      })
    );
  }

  /**
   * Find the activatedRoute in the routerState
   */
  private getActiveRoute(): ActivatedRoute {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
