<div class="row">
  <div class="col-xs-12 col-xl-4 d-flex justify-content-center">
    <div class="mb-3 w-100">
      <isd-loading-spinner-block
        [height]="'400px'"
        *cpLoading="loadingCustomerKey"
      ></isd-loading-spinner-block>
      <div
        [hidden]="loadingService.isLoading(loadingCustomerKey) | async"
        *ngIf="customer$ | async as customer"
      >
        <isd-customer-info
          class="anim-move-in anim-fade-in"
          [customer]="customer"
        ></isd-customer-info>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-xl-8">
    <div class="mb-3">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="./roles">
            {{ 'ADMIN.ROLEDEFINITIONS' | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active"
            routerLink="./rolemembership"
          >
            {{ 'ADMIN.ROLEMEMBERSHIPS' | translate }}
          </a>
        </li>
      </ul>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
