import { Component } from '@angular/core';
import { CustomerZoneDataService } from '../customer-zone-data.service';
import { NavigationStateService } from 'src/app/service/navigation-state/navigation-state.service';
import { switchMap, catchError } from 'rxjs/operators';
import { Observable, empty } from 'rxjs';
import { Customer } from 'src/app/dto/customer.dto';
import { LoadingService } from 'customer-portal-framework-lib';
import { ErrorHandlerService } from 'src/app/service/error-handler/error-handler.service';

@Component({
  selector: 'isd-customer-zone-overview',
  templateUrl: './customer-zone-overview.component.html',
  styleUrls: ['./customer-zone-overview.component.scss']
})
export class CustomerZoneOverviewComponent {
  customer$: Observable<Customer>;
  loadingCustomersKey = 'LoadingCustomerData';

  constructor(
    private readonly customerDataService: CustomerZoneDataService,
    private readonly navigationState: NavigationStateService,
    public loadingService: LoadingService,
    private readonly errorHandler: ErrorHandlerService
  ) {
    loadingService.startLoading(this.loadingCustomersKey);

    this.customer$ = this.navigationState.getCustomerNumber().pipe(
      loadingService.pipeStartLoading(this.loadingCustomersKey),
      switchMap(customerNumber => this.loadCustomer(customerNumber)),
      loadingService.pipeStopLoading(this.loadingCustomersKey)
    );
  }

  private loadCustomer(customerNumber: number): Observable<Customer> {
    return this.customerDataService
      .getCustomerByCustomerNumber$(customerNumber)
      .pipe(
        catchError(err => {
          this.errorHandler.handleError(err);
          this.loadingService.stopLoading(this.loadingCustomersKey);
          return empty();
        })
      );
  }
}
