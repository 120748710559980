import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ObjectLocation } from 'src/app/dto/object-location.dto';
import {
  Observable,
  merge,
  Subject,
  Subscription,
  BehaviorSubject
} from 'rxjs';
import { ZoneNavigationFilterService } from '../zone-navigation-filter.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'isd-object-location-select',
  templateUrl: './object-location-select.component.html',
  styleUrls: ['./object-location-select.component.scss']
})
export class ObjectLocationSelectComponent implements OnDestroy {
  @Input() selectedObjectLocation: ObjectLocation;
  objectLocations$: Observable<ObjectLocation[]>;
  isLoadingObjectLocations = true;
  clearObjectLocationSubject = new Subject<ObjectLocation[]>();
  subscriptions: Subscription = new Subscription();

  isLoading$ = new BehaviorSubject<boolean>(true);

  constructor(
    private readonly navigationFilterService: ZoneNavigationFilterService
  ) {
    this.subscriptions.add(
      navigationFilterService.selectedCustomerZone$.subscribe(() => {
        this.isLoading$.next(true);
        this.clearObjectLocationSubject.next([]);
      })
    );

    this.objectLocations$ = merge(
      this.navigationFilterService.objectLocations$.pipe(
        tap(() => this.isLoading$.next(false))
      ),
      this.clearObjectLocationSubject
    );

    this.subscriptions.add(
      this.navigationFilterService.selectedLocation$.subscribe(
        ol => (this.selectedObjectLocation = ol)
      )
    );
  }

  objectLocationSelected(): void {
    this.navigationFilterService.selectObjectLocation(
      this.selectedObjectLocation
    );
  }

  searchObjectLocation(term: string, objectLocation: ObjectLocation): boolean {
    return (
      objectLocation.address.street.toLowerCase().indexOf(term) >= 0 ||
      objectLocation.address.city.toLowerCase().indexOf(term) >= 0 ||
      objectLocation.address.zip.toLowerCase().indexOf(term) >= 0
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
