import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User, UserCreate } from 'src/app/dto/user.dto';
@Injectable()
export class ExternalUserService {
  private readonly MAX_SEARCH_RESULTS_TO_DISPLAY = 10;

  private readonly recentSearchResultsSub$ = new BehaviorSubject<User[]>([]);
  private readonly collectionUrl = `${environment.bolApiHost}/${environment.bolApiBaseUrl}/users/external`;

  private readonly encodedUmlautStrings: string[] = [
    encodeURIComponent('ü'),
    encodeURIComponent('Ü'),
    encodeURIComponent('ä'),
    encodeURIComponent('Ä'),
    encodeURIComponent('ö'),
    encodeURIComponent('Ö')
  ];

  get recentSearchResults$(): Observable<User[]> {
    return this.recentSearchResultsSub$;
  }

  constructor(private readonly http: HttpClient) {}

  searchForUser(
    searchFragment: string,
    customerZoneId: number,
    take = this.MAX_SEARCH_RESULTS_TO_DISPLAY
  ): Observable<User[]> {
    if (!searchFragment) {
      return of([]);
    }
    return this.http
      .get<User[]>(`${this.collectionUrl}/search`, {
        params: {
          searchInput: this.encodeURIExceptSpecials(searchFragment),
          customerZoneId: customerZoneId.toString(),
          take: take.toString()
        }
      })
      .pipe(
        tap(searchResults => this.addToRecentSearch(searchResults)),
        catchError(err => of([]))
      );
  }

  createExternalUser(newUser: UserCreate): Observable<User> {
    return this.http
      .post<User>(this.collectionUrl, newUser)
      .pipe(tap(createdUser => this.addToRecentSearch([createdUser])));
  }

  private addToRecentSearch(newSearchResults: User[]): void {
    const list = this.mergeUniqueMax(
      this.recentSearchResultsSub$.value,
      newSearchResults
    );

    this.recentSearchResultsSub$.next(list);
  }

  private encodeURIExceptSpecials(value: string): string {
    return this.removeEncodingOfUmlauts(encodeURIComponent(value));
  }

  private removeEncodingOfUmlauts(value: string): string {
    let encodedValue = value;
    this.encodedUmlautStrings.forEach(element => {
      encodedValue = encodedValue
        .split(element)
        .join(decodeURIComponent(element));
    });
    return encodedValue;
  }

  private mergeUniqueMax(currentList: User[], newElements: User[]): User[] {
    const newList = currentList;

    for (const newUser of newElements.reverse()) {
      const userIndex = newList.findIndex(u => u.id === newUser.id);

      if (userIndex >= 0) {
        newList.splice(userIndex, 1);
      }

      newList.unshift(newUser);
    }

    const maxIndex =
      newList.length >= this.MAX_SEARCH_RESULTS_TO_DISPLAY
        ? this.MAX_SEARCH_RESULTS_TO_DISPLAY
        : newList.length;

    return newList.slice(0, maxIndex);
  }
}
