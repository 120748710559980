import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  FileSystemItem,
  FileSystemItemType
} from 'customer-portal-framework-lib';
import { PermissionService } from 'src/app/permission/permission.service';
import { NavigationStateService } from 'src/app/service/navigation-state/navigation-state.service';
import { take, map, switchMap } from 'rxjs/operators';
import { ObjectZonePermission } from 'src/app/permission/permission.dto';
import {
  ObjectDocument,
  ObjectDocumentWithMetadata
} from './object-document.dto';
import { TranslateService } from '@ngx-translate/core';
import { Util } from 'src/app/util';

@Injectable()
export class ObjectDocumentsService {
  private readonly rootUrl = '/';
  private readonly invoicesUrl = '/invoices';
  private readonly contractsUrl = '/contracts';

  constructor(
    private readonly http: HttpClient,
    private readonly permissionService: PermissionService,
    private readonly navStateService: NavigationStateService,
    private readonly translate: TranslateService
  ) {}

  getFolderContent(path?: string): Observable<FileSystemItem[]> {
    if (!path || path === this.rootUrl) {
      return this.getRootFolderContent();
    } else if (path === this.contractsUrl) {
      return this.getContractDocumentsAsFileSystemItems();
    } else if (path === this.invoicesUrl) {
      return this.getInvoiceDocumentsAsFileSystemItems();
    }
  }

  getRootUrl(): string {
    return this.rootUrl;
  }

  getInvoicesUrl(): string {
    return this.invoicesUrl;
  }

  getContractsUrl(): string {
    return this.contractsUrl;
  }

  downloadDocument(url: string): Observable<Blob> {
    return this.http.get(Util.urlConcat(environment.bolApiHost, url), {
      responseType: 'blob'
    });
  }

  private getRootFolderContent(): Observable<FileSystemItem[]> {
    return this.permissionService.getCurrentObjectPermission().pipe(
      take(1),
      map(permission => this.generateRootFolderBasedOnPermission(permission))
    );
  }

  private getContractDocumentsAsFileSystemItems(): Observable<
    FileSystemItem[]
  > {
    return this.loadContractDocumentsFromServer().pipe(
      map(contractDocs =>
        contractDocs.map(doc => this.convertDocumentToFileSystemItem(doc))
      )
    );
  }

  private getInvoiceDocumentsAsFileSystemItems(): Observable<FileSystemItem[]> {
    return this.loadInvoiceDocumentsFromServer().pipe(
      map(contractDocs =>
        contractDocs.map(doc =>
          this.convertDocumentWithMetadataToFileSystemItem(doc)
        )
      )
    );
  }

  private loadContractDocumentsFromServer(): Observable<ObjectDocument[]> {
    return this.navStateService.getObjectZoneId().pipe(
      take(1),
      switchMap(objectZoneId =>
        this.http.get<ObjectDocument[]>(
          `${environment.bolApiHost}/${environment.bolApiBaseUrl}/contractDocuments?objectZoneId=${objectZoneId}`
        )
      )
    );
  }

  private loadInvoiceDocumentsFromServer(): Observable<
    ObjectDocumentWithMetadata[]
  > {
    return this.navStateService.getObjectZoneId().pipe(
      take(1),
      switchMap(objectZoneId =>
        this.http.get<ObjectDocumentWithMetadata[]>(
          `${environment.bolApiHost}/${environment.bolApiBaseUrl}/invoiceDocuments?objectZoneId=${objectZoneId}`
        )
      )
    );
  }

  private convertDocumentToFileSystemItem(doc: ObjectDocument): FileSystemItem {
    return {
      uri: doc.url,
      label: doc.name,
      fileType: FileSystemItemType.File,
      meta: {
        date: this.formatDate(doc.date)
      }
    } as any;
  }

  private convertDocumentWithMetadataToFileSystemItem(
    doc: ObjectDocumentWithMetadata
  ): FileSystemItem {
    return {
      uri: doc.url,
      label: doc.name,
      fileType: FileSystemItemType.File,
      meta: {
        date: this.formatDate(doc.date),
        type: doc.type,
        amount: doc.amount
      }
    } as any;
  }

  private generateRootFolderBasedOnPermission(
    permission: ObjectZonePermission
  ): FileSystemItem[] {
    const fileSystemItems: FileSystemItem[] = [];

    if (permission.contract.canRead) {
      fileSystemItems.push(this.generateContractFolder());
    }

    if (permission.invoice.canRead) {
      fileSystemItems.push(this.generateInvoicesFolder());
    }

    return fileSystemItems;
  }

  private generateInvoicesFolder(): FileSystemItem {
    return {
      id: undefined,
      dropdownOptions: undefined,
      fileType: FileSystemItemType.SystemFolder,
      label: this.translate.instant('OBJECT.DOCUMENTS.INVOICES_FOLDER'),
      uri: this.invoicesUrl
    };
  }

  private generateContractFolder(): FileSystemItem {
    return {
      id: undefined,
      dropdownOptions: undefined,
      fileType: FileSystemItemType.SystemFolder,
      label: this.translate.instant('OBJECT.DOCUMENTS.CONTRACTS_FOLDER'),
      uri: this.contractsUrl
    };
  }

  private formatDate(date: string): string {
    const [year, month, day] = date.split('-');
    return `${day}.${month}.${year}`;
  }
}
