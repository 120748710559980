import { Component, OnInit } from '@angular/core';
import { FileTransferService } from '../services/file-transfer.service';
import { NavigationStateService } from 'src/app/service/navigation-state/navigation-state.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FrameworkLibTranslationProviderService } from '../services/framework-lib-translation-provider.service';
import { FileUploadTranslation } from 'customer-portal-framework-lib';
import { Observable, from } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';

@Component({
  selector: 'isd-document-inbox-upload',
  templateUrl: './document-inbox-upload.component.html',
  styleUrls: ['./document-inbox-upload.component.scss']
})
export class DocumentInboxUploadComponent {

  constructor(
    private readonly fileTransferService: FileTransferService,
    private readonly navState: NavigationStateService,
    private readonly translate: TranslateService,
    private readonly toast: ToastrService,
    private readonly frameworkTranslation: FrameworkLibTranslationProviderService
  ) {}

  getUploadToInboxTranslation(): FileUploadTranslation {
    return this.frameworkTranslation.generateInboxUploadTranslation();
  }

  onUploadFilesToInbox(): (file: File) => Observable<any> {
    return file => this.uploadToInbox(file);
  }

  onUploadFilesToInboxDone(): void {
    this.toast.success(
      this.translate.instant('FILESYSTEM.UPLOAD_TO_INBOX_SUCCESS')
    );
  }

  private uploadToInbox(file: File): Observable<any> {
    return this.navState.getCustomerZoneId().pipe(
      take(1),
      switchMap(customerZoneId =>
        from(this.fileTransferService.uploadToInbox(file, customerZoneId))
      )
    );
  }
}
