<isd-loading-spinner-block
  [height]="'400px'"
  *cpLoading="loadingCustomerStructureKey"
></isd-loading-spinner-block>
<div
  [hidden]="loadingService.isLoading(loadingCustomerStructureKey) | async"
  class="customer-zone-list"
  *ngIf="customerZoneStructure$ | async as customerZone"
>
  <isd-zone-rolemembership
    [zone]="customerZone"
    [level]="1"
    [availableRoles]="customerZoneRoles$ | async"
  ></isd-zone-rolemembership>
  <isd-zone-rolemembership
    *ngFor="let objectZone of customerZone.objectZones"
    [zone]="objectZone"
    [level]="2"
    [availableRoles]="objectZoneRoles$ | async"
  ></isd-zone-rolemembership>
</div>
