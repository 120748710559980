import { Component, OnInit } from '@angular/core';
import { AdminService } from '../service/admin/admin.service';
import { of } from 'rxjs';
import { ZoneService } from '../service/zone/zone.service';
import { take, map, catchError } from 'rxjs/operators';
import { ForbiddenService } from '../auth/forbidden/forbidden.service';
import { ZoneNavigationFilterService } from './zone-navigation-filters/zone-navigation-filter.service';
import { ErrorService } from './error/error.service';

@Component({
  selector: 'isd-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit {
  displayMainContent = false;
  navigationVisibleOnMobile = false;

  constructor(
    readonly adminService: AdminService,
    private readonly zoneService: ZoneService,
    private readonly forbiddenService: ForbiddenService,
    private readonly errorService: ErrorService
  ) {}

  async ngOnInit(): Promise<void> {
    const permissionCheck = await this.checkForAnyCustomerZonePermissions();
    if (permissionCheck) {
      this.showMainContent();
    } else {
      if (permissionCheck === false) {
        await this.forbiddenService.sendToForbiddenPageWithNavState();
      } else {
        await this.errorService.sendToErrorPage();
      }
    }
  }

  private async checkForAnyCustomerZonePermissions(): Promise<boolean> {
    return this.zoneService
      .getCustomerZones()
      .pipe(
        take(1),
        map(customerZones => customerZones && customerZones.length > 0),
        catchError(err => {
          if (err.status === 403 || err.status === 401) {
            return of(false);
          }
          return of(undefined);
        })
      )
      .toPromise();
  }

  private showMainContent(): void {
    this.displayMainContent = true;
  }
}
