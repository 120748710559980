import { Injectable } from '@angular/core';
import { AuthService, User } from 'ps-ng-auth-lib';
import { environment } from 'src/environments/environment';
import { take, filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { termsVersion } from 'src/assets/i18n/terms-de';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TermsModalComponent } from 'src/app/portal/terms-modal/terms-modal.component';
import { TermsApproval } from 'src/app/dto/terms-approval.dto';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class TermsOfUseService {
  constructor(
    private readonly auth: AuthService,
    private readonly http: HttpClient,
    private readonly modal: NgbModal,
    protected readonly storage: StorageService
  ) {}

  async ensureUserHasAcceptedTerms(): Promise<boolean> {
    const user = await this.getCurrentUser();
    if (!this.isExternalUser(user)) {
      return true;
    }

    const lastApprovedVersion = await this.getLatestTermsApproval(user);

    if (!this.isRequiredToApproveTerms(lastApprovedVersion)) {
      return true;
    }

    const approvedInModal = await this.openModal(true);
    if (approvedInModal) {
      this.saveTermsApproval(user);
    }

    return true;
  }

  async openModal(requestApproval = false): Promise<any> {
    const modalRef = this.modal.open(TermsModalComponent, {
      size: 'xl',
      backdrop: requestApproval ? 'static' : true,
      scrollable: true
    });

    const comp = modalRef.componentInstance as TermsModalComponent;
    comp.requestApproval = requestApproval;

    try {
      await modalRef.result;
      return true;
    } catch {
      return requestApproval ? false : true;
    }
  }

  private isRequiredToApproveTerms(
    lastApprovedVersion: TermsApproval
  ): boolean {
    return (
      !lastApprovedVersion ||
      lastApprovedVersion.approvedVersion !== termsVersion
    );
  }

  private async getCurrentUser(): Promise<User> {
    return this.auth
      .getUser()
      .pipe(
        filter(u => !!u),
        take(1)
      )
      .toPromise();
  }

  private isExternalUser(user: User): boolean {
    return user.tenant === environment.authentication.external.tenantId;
  }

  private async getLatestTermsApproval(user: User): Promise<TermsApproval> {
    const cached = this.readFromLocalStorage(user);
    if (cached) {
      return cached;
    }

    const approval = await this.requestTermsApprovalFromServer();
    if (approval) {
      this.saveToLocalStorage(approval, user);
    }
    return approval;
  }

  private async requestTermsApprovalFromServer(): Promise<TermsApproval> {
    return this.http
      .get<TermsApproval>(
        `${environment.bolApiHost}/${environment.bolApiBaseUrl}/termsApprovals`
      )
      .toPromise();
  }

  private async saveTermsApproval(user: User): Promise<TermsApproval> {
    const termsApproval: TermsApproval = {
      approvedVersion: termsVersion
    };

    this.saveToLocalStorage(termsApproval, user);
    return this.sendTermsApprovalToServer(termsApproval);
  }

  private async sendTermsApprovalToServer(
    termsApproval: TermsApproval
  ): Promise<TermsApproval> {
    return this.http
      .post<TermsApproval>(
        `${environment.bolApiHost}/${environment.bolApiBaseUrl}/termsApprovals`,
        termsApproval
      )
      .toPromise();
  }

  private saveToLocalStorage(termsApproval: TermsApproval, user: User): void {
    this.storage.setItem(`terms_${user.mail}`, JSON.stringify(termsApproval));
  }

  private readFromLocalStorage(user: User): TermsApproval {
    const raw = this.storage.getItem(`terms_${user.mail}`);
    return raw && JSON.parse(raw);
  }
}
