import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomerZoneNavigation } from 'src/app/dto/customer-zone-navigation.dto';
import { ObjectLocation } from 'src/app/dto/object-location.dto';
import { ObservableCache } from '../observable-cache';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {
  private readonly baseUrl = `${environment.bolApiHost}/${environment.bolApiBaseUrl}/navigation`;
  private readonly customersZones$: Observable<CustomerZoneNavigation[]>;
  private readonly objectLocationsCache = new ObservableCache<
    number,
    ObjectLocation[]
  >(5);

  constructor(private readonly http: HttpClient) {
    this.customersZones$ = this.loadCustomerZones().pipe(shareReplay(1));
  }

  getObjectLocations(customerZoneId: number): Observable<ObjectLocation[]> {
    return this.objectLocationsCache.getOrCreate(customerZoneId, () =>
      this.loadObjectLocations(customerZoneId)
    );
  }

  getCustomerZones(): Observable<CustomerZoneNavigation[]> {
    return this.customersZones$;
  }

  getCustomerZoneByZoneId(zoneId: number): Observable<CustomerZoneNavigation> {
    return this.customersZones$.pipe(
      map(czs => czs && czs.find(cz => cz.zoneId === zoneId)),
      filter(cz => cz !== undefined)
    );
  }

  private loadCustomerZones(): Observable<CustomerZoneNavigation[]> {
    return this.http.get<CustomerZoneNavigation[]>(
      `${this.baseUrl}/customerZones`
    );
  }

  private loadObjectLocations(
    customerZoneId: number
  ): Observable<ObjectLocation[]> {
    return this.http.get<ObjectLocation[]>(
      `${this.baseUrl}/customerZones/${customerZoneId}/objectLocations`
    );
  }
}
