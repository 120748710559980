import { Component } from '@angular/core';
import { AdminService } from './service/admin/admin.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'ps-ng-auth-lib';
import { Observable } from 'rxjs';
import { LoggerFactoryService } from './service/logging/logger-factory.service';
import { ILogger } from './service/logging/ilogger.interface';

@Component({
  selector: 'isd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'isdcp-ui';
  toggle = false;
  private readonly logger: ILogger;

  constructor(
    translate: TranslateService,
    private readonly auth: AuthService,
    loggerFactory: LoggerFactoryService
  ) {
    this.logger = loggerFactory.getLocalLogger(AppComponent.name);
    translate.setDefaultLang('de');
    translate.use('de');

    this.handleAuthenticationError();
  }

  isAuthorized(): Observable<boolean> {
    return this.auth.isAuthenticated();
  }

  private handleAuthenticationError(): void {
    this.auth.error$.subscribe(err => {
      this.logger.error('critical auth error', err);
      this.auth.silentLogout();
    });
  }
}
