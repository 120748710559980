<div class="d-none d-md-inline-block">
  <span
    [placement]="placement"
    [ngbPopover]="popoverContent"
    [popoverClass]="popoverClass"
  >
    <ng-container *ngIf="label === undefined; else labelTemplate">
      <i
        class="fas fa-info-circle align-middle cursor-pointer {{ iconClass }}"
      ></i>
    </ng-container>

    <ng-template #labelTemplate>
      <button class="btn btn-link p-0">{{ label }}</button>
    </ng-template>
    <ng-template #popoverContent>
      <div class="info-popover-content">
        <ng-container [ngTemplateOutlet]="popupTemplate"></ng-container>
      </div>
    </ng-template>
  </span>
</div>
<div class="d-inline-block d-md-none">
  <span (click)="openInfoModal()">
    <ng-container *ngIf="label === undefined; else labelTemplate">
      <i class="fas fa-info-circle text-white align-middle cursor-pointer"></i>
    </ng-container>
    <ng-template #labelTemplate>
      <button class="btn btn-link p-0">{{ label }}</button>
    </ng-template>
  </span>
</div>
