import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CustomerZoneStructure } from 'src/app/dto/customer-zone-structure.dto';
import { map } from 'rxjs/operators';
import { Zone } from 'src/app/dto/zone.dto';

@Injectable()
export class CustomerZoneStructureService {
  constructor(private readonly http: HttpClient) {}

  getStructure(customerZoneId: number): Observable<CustomerZoneStructure> {
    return this.http
      .get<CustomerZoneStructure>(`${this.baseUrl}/${customerZoneId}/structure`)
      .pipe(
        map(customerZone => {
          customerZone.objectZones = this.orderObjectLocationsDescending(
            customerZone.objectZones
          );
          return customerZone;
        })
      );
  }

  private orderObjectLocationsDescending(objectZones: Zone[]): Zone[] {
    return objectZones.sort((a, b) => a.name.localeCompare(b.name));
  }

  private get baseUrl(): string {
    return `${environment.bolApiHost}/${environment.bolApiBaseUrl}/customerZones`;
  }
}
