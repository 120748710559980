import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { termsInfosDe } from 'src/assets/i18n/terms-de';

@Component({
  selector: 'isd-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss']
})
export class TermsModalComponent {
  termInfos = termsInfosDe;
  requestApproval: boolean;

  constructor(public activeModal: NgbActiveModal) {}
}
