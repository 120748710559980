<div class="overflow-hidden m-3 service-card cursor-pointer">
  <ng-container>
      <div class="hoverable position-absolute"></div>
      <div
        class="
          fa-stack fa-2x
          d-flex
          plus-container
          position-absolute
          justify-content-center
          align-items-center
        "
      >
        <i class="fas fa-circle fa-stack-2x text-primary"></i>
        <i class="fas fa-plus fa-stack-1x text-white"></i>
      </div>
    <img class="w-100" [src]="imageURL" alt="{{ description }}" />
    <span
      class="
        text-white
        justify-content-center
        bg-primary
        d-inline-block
        w-100
        text-center
      "
    >
      {{ description }}
    </span>
  </ng-container>
</div>
