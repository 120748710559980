<div class="row anim-fade-in justify-content-center">
  <div class="col text-center align-self-center">
    <ng-container
      *ngIf="
        this.base64ImageData$ | async as base64ImageData;
        else imagePlaceHolder
      "
    >
      <div class="anim-move-in object-image shadow">
        <img
          [src]="base64ImageData"
          [alt]="'OBJECT.OBJECT_IMG_ALT_TAG' | translate"
        />
      </div>
    </ng-container>
    <ng-template #imagePlaceHolder>
      <div class="image-placeholder">
        <div class="row h-100 align-items-center">
          <div class="col align-self-center">
            <isd-loading-spinner-block></isd-loading-spinner-block>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<div class="row">
  <div class="col p-3 text-center">
    <div class="d-inline-block px-3 py-1 border-white h4 text-primary">
      {{ objectInformation.objectAddress.street }},
      {{ objectInformation.objectAddress.zip }}
      {{ objectInformation.objectAddress.city }}
    </div>
  </div>
</div>
<div class="row mb-3">
  <div class="col-7">
    <b>
      <span class="d-none d-sm-inline-block icon-wrapper">
        <i class="fas fa-home"></i>
      </span>
      {{ 'OBJECT.NUMBER' | translate }}:
    </b>
  </div>
  <div class="col-5">{{ objectInformation.objectNumber }}</div>
</div>
<div
  class="row mb-2"
  *ngFor="let contact of objectInformation.contacts; let i = index"
>
  <div class="col-7">
    <b>
      <span class="d-none d-sm-inline-block icon-wrapper">
        <i class="fas fa-address-book"></i>
      </span>
      {{ contact.description }}
    </b>
  </div>
  <div class="col-5 d-flex justify-content-lg-between">
    {{ contact.name }}
    <isd-info-popup [popupTemplate]="contactPopoverContent"></isd-info-popup>

    <ng-template #contactPopoverContent>
      <div class="d-flex">
        <div class="mr-2">
          <b>{{ 'PERSON.NAME' | translate }}:</b>
        </div>
        <div>{{ contact.name }}</div>
      </div>
      <div class="d-flex">
        <div class="mr-2">
          <b>{{ 'PERSON.TEL' | translate }}:</b>
        </div>
        <div>
          <a [href]="'tel:+' + contact.tel">
            {{ contact.tel }}
          </a>
        </div>
      </div>
      <div class="d-flex">
        <div class="mr-2 text-nowrap">
          <b>{{ 'PERSON.EMAIL' | translate }}:</b>
        </div>
        <div>
          <a [href]="'mailto:' + contact.email">
            {{ contact.email }}
          </a>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<div *ngIf="objectInformation.emergencyPhone" class="row mb-3">
  <div class="col-7">
    <b>
      <span class="d-none d-sm-inline-block icon-wrapper">
        <i class="fas fa-phone-alt"></i>
      </span>
      {{ 'OBJECT.EMERGENCY_PHONE' | translate }}
    </b>
  </div>
  <div class="col-5">
    <a [href]="'tel:' + objectInformation.emergencyPhone">
      {{ objectInformation.emergencyPhone }}
    </a>
  </div>
</div>

<ng-container *isdIfPermitted="'object.objectControls.canRead'">
  <div class="row mb-2">
    <div class="col">
      <isd-loading-spinner-block
        *cpLoading="controlsLoadingKey"
      ></isd-loading-spinner-block>
      <isd-object-controls
        [hidden]="loadingService.isLoading(controlsLoadingKey) | async"
        [objectControls]="objectControls$ | async"
      ></isd-object-controls>
    </div>
  </div>

  <div class="row mb-2">
    <div class="col">
      <isd-loading-spinner-block
        *cpLoading="reportsLoadingKey"
      ></isd-loading-spinner-block>
      <isd-object-reports
        [hidden]="loadingService.isLoading(reportsLoadingKey) | async"
        [objectReports]="objectReports$ | async"
      ></isd-object-reports>
    </div>
  </div>
</ng-container>
