import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from 'src/app/dto/role.dto';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ObservableCache } from 'src/app/service/observable-cache';

@Injectable()
export class RoleService {
  private cache = new ObservableCache<number, Role[]>(5);

  constructor(private readonly http: HttpClient) {}

  getRolesByCustomerZone(zoneId: number): Observable<Role[]> {
    return this.cache.getOrCreate(zoneId, () =>
      this.http.get<Role[]>(`${this.baseUrl}?customerZoneId=${zoneId}`)
    );
  }

  clearRoleCache(): void {
    this.cache = new ObservableCache<number, Role[]>(5);
  }

  private get baseUrl(): string {
    return `${environment.bolApiHost}/${environment.bolApiBaseUrl}/roles`;
  }
}
