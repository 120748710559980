<div class="modal-header">
  <h4 class="modal-title">{{ 'COMMON.INFORMATION' | translate }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-container [ngTemplateOutlet]="popupTemplate"></ng-container>
</div>
