<div class="container-fluid vh-100 bg-primary-lightest">
  <div class="row vh-100 align-items-center justify-content-center">
    <div class="col-sm-8 col-md-6 col-lg-5 col-xl-4">
      <div class="bg-white shadow rounded-lg p-5 d-block">
        <img
          class="isd-logo mb-4 w-100 h-auto"
          routerLink="/"
          src="../assets/svg/isd_logo.svg"
        />
        <h2 class="text-center mb-3">{{ 'AUTH.LOGIN_TITLE' | translate }}</h2>
        <form>
          <div class="row">
            <div class="col mb-4">
              <input
                type="text"
                name="userName"
                class="form-control"
                [(ngModel)]="userName"
                [placeholder]="'USER.EMAIL' | translate"
              />
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-7">
              <button
                class="btn btn-primary w-100"
                type="submit"
                (click)="login()"
              >
                {{ 'AUTH.LOGIN' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
