<ng-container *isdIfPermitted="'customer.roles.canEdit'">
  <ng-container *ngIf="!(adminService.admin$ | async)">
    <a
      class="btn btn-primary d-block shadow-sm"
      [routerLink]="adminLink$ | async"
    >
      <i class="fas fa-users-cog mr-1"></i>
      <span>
        {{ 'ADMIN.OPEN_ADMIN_PANEL' | translate }}
      </span>
    </a>
  </ng-container>
  <ng-container *ngIf="adminService.admin$ | async">
    <a
      class="btn btn-light d-block shadow-sm"
      [routerLink]="leaveAdminLink$ | async"
    >
      <i class="fas fa-arrow-left"></i>
      <span>
        {{ 'ADMIN.CLOSE_ADMIN_PANEL' | translate }}
      </span>
    </a>
  </ng-container>
</ng-container>
