export class Util {
  static deepClone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
  }

  static shallowClone<T>(obj: T): T {
    return Object.assign({}, obj);
  }

  static urlConcat(urlA: string, urlB: string): string {
    if (urlA.endsWith('/')) {
      if (urlB.startsWith('/')) {
        return urlA + urlB.substring(1);
      }
      return urlA + urlB;
    } else if (urlB.startsWith('/')) {
      return urlA + urlB;
    }
    return `${urlA}/${urlB}`;
  }
}
