import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceEvent } from 'src/app/dto/service-event.dto';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceEventsService {
  private readonly collectionUrl = `${environment.bolApiHost}/${environment.bolApiBaseUrl}/objectlocations`;
  constructor(private readonly http: HttpClient) {}

  getObjectLocationServiceEvents(
    objectLocationId: string,
    startDate: Date,
    endDate: Date
  ): Observable<ServiceEvent[]> {
    return this.http.get<ServiceEvent[]>(
      `${
        this.collectionUrl
      }/${objectLocationId}/serviceEvents?startDate=${startDate.toDateString()}&endDate=${endDate.toDateString()}`
    );
  }
}
