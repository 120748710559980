import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ZoneNavigationFilterService } from '../zone-navigation-filter.service';
import { CustomerZoneNavigation } from 'src/app/dto/customer-zone-navigation.dto';
import { Observable, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { AuthService } from 'ps-ng-auth-lib';

@Component({
  selector: 'isd-customer-select',
  templateUrl: './customer-select.component.html',
  styleUrls: ['./customer-select.component.scss']
})
export class CustomerSelectComponent implements OnDestroy {
  @Input() selectedCustomer: CustomerZoneNavigation;
  customerZones$: Observable<CustomerZoneNavigation[]>;
  customerSubscription: Subscription;
  constructor(
    private readonly navigationFilterService: ZoneNavigationFilterService,
    readonly auth: AuthService
  ) {
    this.customerZones$ = this.auth.isAuthenticated().pipe(
      filter(isAuthenticated => isAuthenticated),
      switchMap(() => navigationFilterService.customersZones$)
    );

    this.customerSubscription = this.navigationFilterService.selectedCustomerZone$.subscribe(
      c => {
        this.selectedCustomer = c;
      }
    );
  }

  customerZoneSelected(): void {
    this.navigationFilterService.selectCustomerZone(this.selectedCustomer);
  }

  ngOnDestroy(): void {
    this.customerSubscription.unsubscribe();
  }
}
