import { Injectable, OnDestroy } from '@angular/core';
import { CustomerZoneNavigation } from '../../dto/customer-zone-navigation.dto';
import { ObjectLocation } from '../../dto/object-location.dto';
import {
  Observable,
  of,
  empty,
  Subscription,
  merge,
  BehaviorSubject
} from 'rxjs';
import { ZoneService } from '../../service/zone/zone.service';
import { NavigationStateService } from '../../service/navigation-state/navigation-state.service';
import {
  switchMap,
  map,
  distinctUntilChanged,
  take,
  catchError,
  tap,
  filter
} from 'rxjs/operators';
import { ErrorHandlerService } from '../../service/error-handler/error-handler.service';
import { NavigationState } from '../../service/navigation-state/navigation-state.dto';
import { ForbiddenService } from 'src/app/auth/forbidden/forbidden.service';

@Injectable()
export class ZoneNavigationFilterService implements OnDestroy {
  customersZones$: Observable<CustomerZoneNavigation[]>;
  objectLocations$: Observable<ObjectLocation[]>;

  selectedCustomerZone$: Observable<CustomerZoneNavigation>;
  selectedLocation$: Observable<ObjectLocation>;

  private readonly selectedCustomerZoneSubject = new BehaviorSubject<
    CustomerZoneNavigation
  >(undefined);
  private readonly selectedLocationSubject = new BehaviorSubject<
    ObjectLocation
  >(undefined);
  private readonly subcriptions = new Subscription();

  private get activeCustomerZoneId(): number {
    const current = this.selectedCustomerZoneSubject.value;
    return current && current.zoneId;
  }

  private get activeObjectLocationId(): string {
    const current = this.selectedLocationSubject.value;
    return current && current.id;
  }

  constructor(
    private readonly zoneService: ZoneService,
    private readonly navigationStateService: NavigationStateService,
    private readonly errorHandler: ErrorHandlerService,
    private readonly forbiddenService: ForbiddenService
  ) {
    this.setupCustomerZones();
    this.setupObjectLocations();
    this.setupNavigationChangeHandler();
  }

  ngOnDestroy(): void {
    this.subcriptions.unsubscribe();
  }

  selectCustomerZone(customer: CustomerZoneNavigation): void {
    this.setSelectedObjectLocation(undefined);
    this.setNavigationState(customer.zoneId, undefined);
  }

  selectObjectLocation(objectLocation: ObjectLocation): void {
    this.setNavigationState(
      this.activeCustomerZoneId,
      objectLocation && objectLocation.id
    );
  }

  private setNavigationState(
    customerZoneId: number,
    objectLocationId: string
  ): void {
    this.navigationStateService.set({
      customerZoneId,
      objectLocationId
    });
  }

  private setupObjectLocations(): void {
    this.loadObjectLocationsOnCustomerSelectionChange();
    this.makeObjectLocationDistinctlyAvailable();
  }

  private makeObjectLocationDistinctlyAvailable(): void {
    this.selectedLocation$ = this.selectedLocationSubject.pipe(
      distinctUntilChanged((x, y) => (x && x.id) === (y && y.id))
    );
  }

  private setupNavigationChangeHandler(): void {
    const sub = this.navigationStateService
      .get()
      .subscribe(state => this.onNavigationChange(state));

    this.subcriptions.add(sub);
  }

  private async onNavigationChange(state: NavigationState): Promise<void> {
    if (state.customerZoneId === undefined) {
      await this.pickDefaultCustomerZone();
      return;
    } else if (state.customerZoneId !== this.activeCustomerZoneId) {
      await this.updateCustomerSelection(state);
    }

    if (this.activeObjectLocationId !== state.objectLocationId) {
      await this.updateObjectLocationSelection(state);
    }
  }

  private async updateObjectLocationSelection(
    state: NavigationState
  ): Promise<void> {
    await this.zoneService
      .getObjectLocations(this.activeCustomerZoneId)
      .pipe(
        take(1),
        switchMap(objectLocations =>
          this.findObjectLocationToSelect(
            state.objectLocationId,
            objectLocations
          )
        ),
        tap(location => this.setSelectedObjectLocation(location))
      )
      .toPromise();
  }

  private setSelectedObjectLocation(location: ObjectLocation): void {
    this.selectedLocationSubject.next(location);
  }

  private async updateCustomerSelection(state: NavigationState): Promise<void> {
    await this.customersZones$
      .pipe(
        take(1),
        switchMap(customerZones =>
          this.findCustomerZoneToSelect(state.customerZoneId, customerZones)
        ),
        tap(customerZone => this.setSelectedCustomerZone(customerZone))
      )
      .toPromise();
  }

  private async pickDefaultCustomerZone(): Promise<void> {
    const customerZones = await this.customersZones$.pipe(take(1)).toPromise();
    const defaultCustomerZone = this.getDefaultCustomer(customerZones);
    this.setNavigationState(
      defaultCustomerZone && defaultCustomerZone.zoneId,
      undefined
    );
  }

  private setSelectedCustomerZone(customerZone: CustomerZoneNavigation): void {
    this.selectedCustomerZoneSubject.next(customerZone);
  }

  private findObjectLocationToSelect(
    objectLocationId: string,
    objectLocations: ObjectLocation[]
  ): Observable<ObjectLocation> {
    if (!objectLocationId) {
      return of(undefined);
    }

    const matchingLocation = objectLocations.find(
      ol => ol.id === objectLocationId
    );

    if (!matchingLocation && objectLocations.length > 0) {
      this.forbiddenService.sendToForbiddenPageWithNavState();
      return empty();
    }

    return of(matchingLocation);
  }

  private loadObjectLocationsOnCustomerSelectionChange(): void {
    this.objectLocations$ = this.selectedCustomerZone$.pipe(
      filter(customer => !!customer),
      switchMap(customer =>
        merge(this.loadOrderedObjectLocations(customer.zoneId))
      )
    );
  }

  private loadOrderedObjectLocations(
    customerZoneId: number
  ): Observable<ObjectLocation[]> {
    return this.zoneService.getObjectLocations(customerZoneId).pipe(
      map(objectLocations =>
        this.orderObjectLocationsDescending(objectLocations)
      ),
      catchError(err => {
        this.errorHandler.handleError(err);
        return of([]);
      })
    );
  }

  private orderObjectLocationsDescending(
    objectLocations: ObjectLocation[]
  ): ObjectLocation[] {
    return objectLocations.sort((a, b) =>
      a.address.street.localeCompare(b.address.street)
    );
  }

  private setupCustomerZones(): void {
    this.loadCustomerZones();
    this.makeCustomerZonesDisctinctlyAvailable();
  }

  private loadCustomerZones(): void {
    this.customersZones$ = this.zoneService.getCustomerZones().pipe(
      map(customerZones => this.orderCustomerZonesDescending(customerZones)),
      catchError(err => {
        this.errorHandler.handleError(err);
        return of([]);
      })
    );
  }

  private orderCustomerZonesDescending(
    customerZones: CustomerZoneNavigation[]
  ): CustomerZoneNavigation[] {
    return customerZones.sort((a, b) => a.name.localeCompare(b.name));
  }

  private makeCustomerZonesDisctinctlyAvailable(): void {
    this.selectedCustomerZone$ = this.selectedCustomerZoneSubject.pipe(
      distinctUntilChanged((x, y) => (x && x.zoneId) === (y && y.zoneId))
    );
  }

  private findCustomerZoneToSelect(
    customerZoneId: number,
    customerZones: CustomerZoneNavigation[]
  ): Observable<CustomerZoneNavigation> {
    const matchingCustomer = customerZones.find(
      c => c.zoneId === customerZoneId
    );

    if (!matchingCustomer) {
      this.forbiddenService.sendToForbiddenPageWithNavState();
      return empty();
    }

    return of(matchingCustomer);
  }

  private getDefaultCustomer(
    selectableCustomers: CustomerZoneNavigation[]
  ): CustomerZoneNavigation {
    return selectableCustomers && selectableCustomers[0];
  }
}
