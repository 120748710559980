<div
  class="dropdown d-inline-block"
  ngbDropdown
  placement="bottom-right"
  [autoClose]="'outside'"
>
  <button
    class="btn dropdown-toggle text-right"
    type="button"
    ngbDropdownToggle
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <span class="d-none d-xl-inline">
      {{ (user$ | async).name }}
    </span>
    <span class="d-inline d-xl-none user-icon">
      <i class="fas fa-user"></i>
    </span>
  </button>
  <div class="dropdown-menu" ngbDropdownMenu>
    <button
      *ngIf="!(isInternalUser() | async)"
      class="btn btn-link dropdown-item rounded-0"
      (click)="this.openResetPage()"
    >
      {{ 'TOPBAR.DROPDOWN.OPTIONS.CHANGE_PASSWORD' | translate }}
    </button>

    <button class="btn btn-link dropdown-item rounded-0" (click)="openTerms()">
      {{ 'TOPBAR.DROPDOWN.OPTIONS.TERMS' | translate }}
    </button>
    <button class="btn btn-link dropdown-item rounded-0" (click)="showPrivacyPolicy()">
      {{ 'TOPBAR.DROPDOWN.OPTIONS.PRIVACY_POLICY' | translate }}
    </button>

    <button class="btn btn-link dropdown-item rounded-0" (click)="openVideo()">
      {{ 'TOPBAR.DROPDOWN.OPTIONS.INTRO_VIDEO' | translate }}
    </button>

    <button
      class="btn btn-link dropdown-item rounded-0"
      (click)="toggleLegalDocuments()"
    >
      {{ 'TOPBAR.DROPDOWN.OPTIONS.LEGAL' | translate }}
      <i
        [class.fa-caret-right]="legalDocumentsToggled"
        [class.fa-caret-down]="!legalDocumentsToggled"
        class="fas ml-2"
      ></i>
    </button>

    <ng-container *ngIf="legalDocumentsToggled">
      <button
        class="btn btn-link dropdown-item border-left border-color-gray-200 border-width-3 rounded-0"
        *ngFor="let legalDocument of legalDocuments$ | async"
        (click)="
          downloadLegalDocumentPdf(legalDocument.url, legalDocument.label)
        "
      >
        {{ legalDocument.label }}
      </button>
    </ng-container>

    <div class="dropdown-divider"></div>

    <button
      class="btn btn-link dropdown-item rounded-0"
      (click)="this.auth.logout()"
    >
      {{ 'TOPBAR.DROPDOWN.LOGOUT' | translate }}
    </button>
  </div>
</div>
