import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild
} from '@angular/router';
import { IntroVideoService } from '../portal/intro-video/intro-video.service';
import { TermsOfUseService } from '../service/terms-of-use/terms-of-use.service';

@Injectable({
  providedIn: 'root'
})
export class TermsAndVideoGuard implements CanActivate, CanActivateChild {
  skip = false;

  constructor(
    private readonly termsService: TermsOfUseService,
    private readonly videoService: IntroVideoService
  ) {}
  async canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.showTermsAndVideo();
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.showTermsAndVideo();
  }

  private async showTermsAndVideo(): Promise<boolean> {
    if (this.skip) {
      return true;
    }
    this.skip = true;

    await this.termsService.ensureUserHasAcceptedTerms();
    await this.videoService.showVideoIfNotSeen();

    return true;
  }
}
