import { Component, Input } from '@angular/core';

@Component({
  selector: 'isd-object-controls',
  templateUrl: './object-controls.component.html',
  styleUrls: ['./object-controls.component.scss']
})
export class ObjectControlsComponent {
  latestControl: string;
  remainingObjectControls: string[] = [];

  @Input() set objectControls(value: string[]) {
    [this.latestControl, ...this.remainingObjectControls] = value
      ? value
      : [undefined];
  }

  hasControlDates(): boolean {
    return !!this.latestControl;
  }

  hasMoreControls(): boolean {
    return this.remainingObjectControls.length > 0;
  }
}
