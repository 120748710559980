<div class="loading-wrapper">
  <div *cpLoading="loadingKey" class="loading-overlay anim-fade-in">
    <div class="h-100 w-100 d-flex justify-content-center opacity-1">
      <div class="align-self-center">
        <isd-loading-spinner></isd-loading-spinner>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
