import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'isd-fullscreen-loader',
  templateUrl: './fullscreen-loader.component.html',
  styleUrls: ['./fullscreen-loader.component.scss']
})
export class FullscreenLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
