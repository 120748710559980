<div class="shadow-sm">
  <ng-select
    [placeholder]="'ZONENAVIGATION.LOCATIONSELECTION' | translate"
    [loadingText]="'COMMON.SELECT.LOADING' | translate"
    [notFoundText]="'COMMON.SELECT.NOT_FOUND' | translate"
    [clearAllText]="'COMMON.SELECT.CLEAR' | translate"
    [items]="objectLocations$ | async"
    [searchFn]="searchObjectLocation"
    (change)="objectLocationSelected()"
    [(ngModel)]="selectedObjectLocation"
    [loading]="isLoading$ | async"
    appendTo="body"
  >
    <ng-template ng-label-tmp let-item="item">
      <span
        >{{ item.address.street }}, {{ item.address.zip }}
        {{ item.address.city }}
      </span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <span
        >{{ item.address.street }}, {{ item.address.zip }}
        {{ item.address.city }}
      </span>
    </ng-template>
  </ng-select>
</div>
