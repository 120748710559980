import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  UrlTree
} from '@angular/router';
import { PermissionService } from 'src/app/permission/permission.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGuardService implements CanActivate {
  constructor(
    private readonly permissionService: PermissionService,
    private readonly router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const customerZoneIdStr = route.paramMap.get('customerZoneId');
    const customerZoneId = parseInt(customerZoneIdStr, 10);

    return this.permissionService.getCustomerPermission(customerZoneId).pipe(
      map(permission => {
        if (permission.roles.canEdit) {
          return true;
        }
        return this.router.createUrlTree(['customer', customerZoneId]);
      })
    );
  }
}
