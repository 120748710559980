import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  Input,
  OnDestroy
} from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IfPermittedService } from './if-permitted.service';

@Directive({
  selector: '[isdIfNotPermitted]'
})
export class IfNotPermittedDirective implements OnDestroy {
  @Input() set isdIfNotPermitted(requiredPermission: string) {
    this.calculatePermission(requiredPermission);
  }

  private contentVisible = false;
  private subscription: Subscription;

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly ifPermittedService: IfPermittedService
  ) {
    this.updateView();
  }

  ngOnDestroy(): void {
    this.cleanupExistingSubscription();
  }

  private calculatePermission(requiredPermission: string): void {
    this.cleanupExistingSubscription();

    this.subscription = this.shouldShowContent(requiredPermission).subscribe(
      shouldShowContent => {
        if (this.contentVisible === shouldShowContent) {
          return;
        }

        this.contentVisible = shouldShowContent;

        this.updateView();
      }
    );
  }

  private cleanupExistingSubscription(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private shouldShowContent(
    requiredPermissionPath: string
  ): Observable<boolean> {
    return this.ifPermittedService
      .getPermissionByPath(requiredPermissionPath)
      .pipe(map(p => p !== undefined && !p));
  }

  private updateView(): void {
    if (this.contentVisible) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
