import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FolderItem } from '../dto/folder-item.dto';
import { FolderItemCollection } from '../dto/folder-item-collection.dto';

/**
 * Handles http requests regarding the ISDCP filesystem.
 */
@Injectable()
export class FileTransferBackendService {
  private readonly baseUrl = `${environment.bolApiHost}/${environment.bolApiBaseUrl}/filetransfer`;

  constructor(private readonly http: HttpClient) {}

  getFolderItemsFromPath(
    path: string,
    skip = 0,
    take = 1000
  ): Observable<FolderItemCollection> {
    const encodedPath = encodeURIComponent(path);
    return this.http.get<FolderItemCollection>(
      `${this.baseUrl}/folderItems?path=${encodedPath}&skip=${skip}&take=${take}`
    );
  }

  uploadFile(file: File, path: string, force: boolean): Observable<FolderItem> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('path', path);
    formData.append('displayName', file.name.trim());
    formData.append('force', `${force}`);

    return this.http.post<FolderItem>(`${this.baseUrl}/uploadFile`, formData);
  }

  uploadToInbox(file: File, customerZoneId: number): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('customerZoneId', customerZoneId.toString());
    formData.append('displayName', file.name.trim());

    return this.http.post(`${this.baseUrl}/inbox/uploadFile`, formData);
  }

  createFolder(folderName: string, path: string): Observable<FolderItem> {
    return this.http.post<FolderItem>(`${this.baseUrl}/createFolder`, {
      name: folderName.trim(),
      path
    });
  }

  downloadFile(path: string): Observable<Blob> {
    const encodedPath = encodeURIComponent(path);
    return this.http.get(`${this.baseUrl}/downloadFile?path=${encodedPath}`, {
      responseType: 'blob'
    });
  }

  renameFolderItem(newName: string, path: string): Observable<FolderItem> {
    const encodedPath = encodeURIComponent(path);
    return this.http.put<FolderItem>(
      `${this.baseUrl}/rename?path=${encodedPath}`,
      {
        name: newName.trim()
      }
    );
  }

  deleteFolderItem(path: string): Observable<any> {
    const encodedPath = encodeURIComponent(path);
    return this.http.delete<any>(`${this.baseUrl}/delete?path=${encodedPath}`);
  }
}
