import { Component } from '@angular/core';
import { LoadingService } from 'customer-portal-framework-lib';
import { EMPTY, Observable } from 'rxjs';
import { catchError, filter, shareReplay, switchMap } from 'rxjs/operators';
import { ObjectInformation } from 'src/app/dto/object-information.dto';
import { ErrorHandlerService } from 'src/app/service/error-handler/error-handler.service';
import { NavigationStateService } from 'src/app/service/navigation-state/navigation-state.service';
import { ObjectLocationDataService } from '../object-location-data.service';

@Component({
  selector: 'isd-object-overview',
  templateUrl: './object-overview.component.html',
  styleUrls: ['./object-overview.component.scss']
})
export class ObjectOverviewComponent {
  loadingObjectInfoKey = 'loadingObjectInfoKey';
  objectInfo$: Observable<ObjectInformation>;

  constructor(
    private readonly objectDataService: ObjectLocationDataService,
    private readonly navigationStateService: NavigationStateService,
    private readonly errorHandler: ErrorHandlerService,
    public loadingService: LoadingService
  ) {
    this.loadObjectInfo();
  }

  private loadObjectInfo(): void {
    this.loadingService.startLoading(this.loadingObjectInfoKey);
    this.objectInfo$ = this.navigationStateService.getObjectLocationId().pipe(
      this.loadingService.pipeStartLoading(this.loadingObjectInfoKey),
      switchMap(id => this.loadObjectLocationInfo(id)),
      this.loadingService.pipeStopLoading(this.loadingObjectInfoKey),
      shareReplay({
        bufferSize: 1,
        refCount: true
      })
    );
  }

  private loadObjectLocationInfo(
    objectLocationId: string
  ): Observable<ObjectInformation> {
    return this.objectDataService
      .getObjectInformationByObjectNumber(objectLocationId)
      .pipe(
        catchError(err => {
          this.errorHandler.handleError(err);
          this.loadingService.stopLoading(this.loadingObjectInfoKey);
          return EMPTY;
        })
      );
  }
}
