import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  get length(): number {
    return localStorage.length;
  }

  clear(): void {
    localStorage.clear();
  }

  getItem(key: string): string {
    return localStorage.getItem(this.generateIsdcpScopedKey(key));
  }

  key(index: number): string {
    return localStorage.key(index);
  }

  removeItem(key: string): void {
    localStorage.removeItem(this.generateIsdcpScopedKey(key));
  }

  setItem(key: string, value: string): void {
    localStorage.setItem(this.generateIsdcpScopedKey(key), value);
  }

  generateIsdcpScopedKey(key: string): string {
    return `isdcp.${key}`;
  }
}
