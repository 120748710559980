import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CreateFolderTranslation,
  EditFileNameTranslation,
  FileUploadTranslation
} from 'customer-portal-framework-lib';

/**
 * Provides translations for 'customer-portal-framework-lib' components.
 */
@Injectable()
export class FrameworkLibTranslationProviderService {
  constructor(private readonly translateService: TranslateService) {}

  generateCreateFolderTranslation(): CreateFolderTranslation {
    const translation = this.getCommonTranslation();

    return {
      createFolderModalTitle: translation.FILESYSTEM.CREATE_FOLDER,
      placeholder: translation.FILESYSTEM.FOLDERNAME,
      placeholder_error: translation.FILESYSTEM.FOLDERNAME,
      button: {
        save: translation['COMMON.BUTTON'].SAVE,
        cancel: translation['COMMON.BUTTON'].CANCEL,
        createFolder: translation.FILESYSTEM.CREATE_FOLDER
      }
    };
  }

  generateRenameTranslation(isFile: boolean): EditFileNameTranslation {
    const translation = this.getCommonTranslation();

    const placeholder = isFile
      ? translation.FILESYSTEM.FILENAME
      : translation.FILESYSTEM.FOLDERNAME;

    const title = isFile
      ? translation.FILESYSTEM.RENAME_FILE
      : translation.FILESYSTEM.RENAME_FOLDER;

    return {
      modalTitle: title,
      namePlaceholder: placeholder,
      invalidNameError: translation.FILESYSTEM.ERROR.NAME_MUST_NOT_BE_EMPTY,
      button: {
        save: translation['COMMON.BUTTON'].SAVE,
        cancel: translation['COMMON.BUTTON'].CANCEL
      }
    };
  }

  generateUploadTranslation(): FileUploadTranslation {
    const translation = this.getCommonTranslation();

    return {
      uploadModalTitle: translation.FILESYSTEM.UPLOAD_FILES,
      overwriteFilePrompt: translation.FILESYSTEM.OVERWRITE_FILES_PROMPT,
      dragAndDropFiles: translation.FILESYSTEM.DRAG_AND_DROP,
      orClickHere: translation.FILESYSTEM.OR_CLICK_HERE,
      overwriteFileModalTitle: translation.FILESYSTEM.OVERWRITE_FILES,
      fileSizeLimitInfo: translation.FILESYSTEM.FILESIZE_LIMIT_INFO,
      button: {
        cancel: translation['COMMON.BUTTON'].CANCEL,
        save: translation['COMMON.BUTTON'].SAVE,
        upload: translation.FILESYSTEM.UPLOAD_FILES,
        no: translation['COMMON.BUTTON'].NO,
        yes: translation['COMMON.BUTTON'].YES
      }
    };
  }

  generateInboxUploadTranslation(): FileUploadTranslation {
    const translation = this.getCommonTranslation();

    return {
      uploadModalTitle: translation.FILESYSTEM.UPLOAD_TO_INBOX,
      overwriteFilePrompt: translation.FILESYSTEM.OVERWRITE_FILES_PROMPT,
      dragAndDropFiles: translation.FILESYSTEM.DRAG_AND_DROP,
      orClickHere: translation.FILESYSTEM.OR_CLICK_HERE,
      overwriteFileModalTitle: translation.FILESYSTEM.OVERWRITE_FILES,
      fileSizeLimitInfo: translation.FILESYSTEM.FILESIZE_LIMIT_INFO,
      button: {
        cancel: translation['COMMON.BUTTON'].CANCEL,
        save: translation['COMMON.BUTTON'].SAVE,
        upload: translation.FILESYSTEM.UPLOAD_TO_INBOX,
        no: translation['COMMON.BUTTON'].NO,
        yes: translation['COMMON.BUTTON'].YES
      }
    };
  }

  private getCommonTranslation(): any {
    return this.translateService.instant(['FILESYSTEM', 'COMMON.BUTTON']);
  }
}
