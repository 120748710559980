import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  CustomerZonePermission,
  NO_CUSTOMERZONE_PERMISSIONS,
  ObjectZonePermission,
  NO_OBJECTZONE_PERMISSION
} from './permission.dto';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NavigationStateService } from '../service/navigation-state/navigation-state.service';
import { shareReplay, switchMap } from 'rxjs/operators';
import { ObservableCache } from '../service/observable-cache';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private customerZonePermissions$: Observable<CustomerZonePermission>;
  private objectZonePermissions$: Observable<ObjectZonePermission>;
  private readonly customerCache = new ObservableCache<
    number,
    CustomerZonePermission
  >(5);
  private readonly objectCache = new ObservableCache<
    number,
    ObjectZonePermission
  >(5);

  constructor(
    private readonly http: HttpClient,
    private readonly navigationState: NavigationStateService
  ) {
    this.listenForCustomerZonePermissionChange();
    this.listenForObjectZonePermissionChange();
  }

  getCurrentCustomerPermission(): Observable<CustomerZonePermission> {
    return this.customerZonePermissions$;
  }

  getCurrentObjectPermission(): Observable<ObjectZonePermission> {
    return this.objectZonePermissions$;
  }

  getCustomerPermission(
    customerZoneId: number
  ): Observable<CustomerZonePermission> {
    return this.customerCache.getOrCreate(customerZoneId, () =>
      this.getCustomerZonePermissionFromServer(customerZoneId)
    );
  }

  getObjectPermission(objectZoneId: number): Observable<ObjectZonePermission> {
    return this.objectCache.getOrCreate(objectZoneId, () =>
      this.getObjectZonePermissionFromServer(objectZoneId)
    );
  }

  private listenForCustomerZonePermissionChange(): void {
    this.customerZonePermissions$ = this.navigationState
      .getCustomerZoneId()
      .pipe(
        switchMap(customerZoneId => {
          if (customerZoneId === undefined) {
            return of(NO_CUSTOMERZONE_PERMISSIONS);
          }

          return this.getCustomerPermission(customerZoneId);
        }),
        shareReplay(1)
      );
  }

  private listenForObjectZonePermissionChange(): void {
    this.objectZonePermissions$ = this.navigationState.getObjectZoneId().pipe(
      switchMap(objectZoneId => {
        if (objectZoneId === undefined) {
          return of(NO_OBJECTZONE_PERMISSION);
        }

        return this.getObjectPermission(objectZoneId);
      }),
      shareReplay(1)
    );
  }

  private getCustomerZonePermissionFromServer(
    customerZoneId: number
  ): Observable<CustomerZonePermission> {
    const url = `${environment.bolApiHost}/${environment.bolApiBaseUrl}/customerZones/${customerZoneId}/permissions`;
    return this.http.get<CustomerZonePermission>(url);
  }

  private getObjectZonePermissionFromServer(
    objectZoneId: number
  ): Observable<ObjectZonePermission> {
    const url = `${environment.bolApiHost}/${environment.bolApiBaseUrl}/objectZones/${objectZoneId}/permissions`;
    return this.http.get<ObjectZonePermission>(url);
  }
}
