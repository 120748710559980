<isd-fullscreen-loader
  *ngIf="!displayMainContent; else mainContent"
></isd-fullscreen-loader>
<ng-template #mainContent>
  <div
    class="cp-content-wrapper overflow-hidden d-flex flex-column p-1 p-sm-2 p-md-3"
    [class.bg-primary-dark]="adminService.admin$ | async"
    [class.bg-primary-lightest]="!(adminService.admin$ | async)"
  >
    <cp-top-bar [class.mb-2]="navigationVisibleOnMobile === false">
      <div class="w-100">
        <div class="d-flex justify-content-between">
          <button
            class="d-xl-none d-xs-block btn btn-link text-left"
            (click)="navigationVisibleOnMobile = !navigationVisibleOnMobile"
          >
            <i
              class="fas menu-icon"
              [class.fa-bars]="!navigationVisibleOnMobile"
              [class.fa-times]="navigationVisibleOnMobile"
            ></i>
          </button>

          <img
            class="isd-logo d-inline-block"
            routerLink="/"
            src="../assets/svg/isd_logo_no_space.svg"
          />
          <isd-user-dropdown></isd-user-dropdown>
        </div>
      </div>
    </cp-top-bar>
    <div
      [class.d-flex]="navigationVisibleOnMobile"
      class="row align-self-stretch my-3 d-none d-xl-flex"
    >
      <div class="col-12 col-xl-10">
        <isd-zone-navigation-filters></isd-zone-navigation-filters>
      </div>
      <div class="col-12 col-xl-2">
        <isd-admin-panel-opener></isd-admin-panel-opener>
      </div>
    </div>
    <div
      class="container-fluid p-2 p-sm-5 align-self-stretch flex-grow-1 bg-white rounded-lg shadow-sm"
    >
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-template>
