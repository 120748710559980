<ng-container *ngIf="hasObjectReports()">
  <div class="row">
    <div class="col-7">
      <b>
        <span class="d-none d-sm-inline-block icon-wrapper">
          <i class="fas fa-file-alt"></i>
        </span>
        {{ 'OBJECT.REPORTS' | translate }}:
      </b>
    </div>
    <div class="col-5">
      <ng-container>
        <button
          class="btn btn-link p-0 d-block"
          (click)="downloadReport(lastObjectReport)"
        >
          {{ lastObjectReport.date | date: 'dd.MM.yyyy' }}
          <img [src]="'assets/svg/pdf.svg'" class="ml-1 pdf-icon" />
        </button>
        <isd-info-popup
          *ngIf="hasMoreReports()"
          [label]="'COMMON.SHOW_MORE' | translate"
          [popupTemplate]="controlsPopoverContent"
          [popoverClass]="'popover-sm text-center'"
        ></isd-info-popup>
      </ng-container>
      <ng-template #controlsPopoverContent>
        <ng-container
          *ngFor="let report of remainingObjectReports; let last = last"
        >
          <button
            class="btn btn-link p-0 d-block"
            [class.m-0]="last"
            [class.mb-2]="!last"
            (click)="downloadReport(report)"
          >
            {{ report.date | date: 'dd.MM.yyyy' }}
            <img [src]="'assets/svg/pdf.svg'" class="ml-1 pdf-icon" />
          </button>
        </ng-container>
      </ng-template>
      <ng-template #noControls>
        <span class="text-muted">
          {{ 'OBJECT.NO_REPORTS_AVAILABLE' | translate }}
        </span>
      </ng-template>
    </div>
  </div>
</ng-container>
