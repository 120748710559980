import { Component } from '@angular/core';
import { LoadingService } from 'customer-portal-framework-lib';
import { Observable } from 'rxjs';
import { ContractInfo } from 'src/app/dto/contract-info.dto';
import { ContractInformationService } from 'src/app/service/contact-information/contract-information.service';
import { NavigationStateService } from 'src/app/service/navigation-state/navigation-state.service';
import { switchMap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModalComponent } from '../calendar-modal/calendar-modal.component';

@Component({
  selector: 'isd-object-service-calendar',
  templateUrl: './object-service-calendar.component.html',
  styleUrls: ['./object-service-calendar.component.scss']
})
export class ObjectServiceCalendarComponent {
  loadingKey = 'contractInfo';
  contracts$: Observable<ContractInfo[]>;

  constructor(
    public loading: LoadingService,
    private readonly ngbModal: NgbModal,
    private readonly navState: NavigationStateService,
    private readonly contractInfoService: ContractInformationService
  ) {
    this.contracts$ = navState.getObjectZoneId().pipe(
      loading.pipeStartLoading(this.loadingKey),
      switchMap(objectZoneId =>
        this.contractInfoService.getObjectContractInfo(objectZoneId)
      ),
      loading.pipeStopLoading(this.loadingKey)
    );
  }

  OpenCalendarModal(): void {
    const modalRef = this.ngbModal.open(CalendarModalComponent, { size: 'xl' });
  }
}
