import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CpUserModule } from 'customer-portal-framework-lib';
import { UserDropdownComponent } from './user-dropdown/user-dropdown.component';
import { UserSearchComponent } from './user-search/user-search.component';
import { UserCreateModalComponent } from './user-create-modal/user-create-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ExternalUserService } from '../service/external-user/external-user.service';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    UserDropdownComponent,
    UserSearchComponent,
    UserCreateModalComponent
  ],
  imports: [
    CommonModule,
    CpUserModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    RouterModule,
    NgbDropdownModule,
    SharedModule
  ],
  providers: [ExternalUserService],
  exports: [UserDropdownComponent, UserSearchComponent],
  entryComponents: [UserCreateModalComponent]
})
export class UserModule {}
