<div class="modal-header">
  <h3>
    {{ 'USER.CREATE_HEADER' | translate }}
  </h3>
</div>

<div class="modal-body">
  <form [formGroup]="userForm" class="form-validated">
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="salutation">{{ 'USER.SALUTATION' | translate }}*</label>
        <isd-validation-popup [formControlField]="userForm.get('salutation')">
          <select
            required
            class="form-control"
            name="salutation"
            formControlName="salutation"
            id="salutation"
          >
            <option disabled value="">{{
              'COMMON.BUTTON.CHOOSE' | translate
            }}</option>
            <option
              *ngFor="let salutationOption of salutationOptions"
              [value]="salutationOption.value"
            >
              {{ salutationOption.label }}
            </option>
          </select>
        </isd-validation-popup>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="givenName">{{ 'USER.FIRSTNAME' | translate }}*</label>
        <isd-validation-popup [formControlField]="userForm.get('givenName')">
          <input
            type="text"
            required
            class="form-control"
            placeholder="{{ 'USER.FIRSTNAME' | translate }}"
            name="givenName"
            id="givenName"
            formControlName="givenName"
          />
        </isd-validation-popup>
      </div>
      <div class="form-group col-md-6">
        <label for="surname">{{ 'USER.SURNAME' | translate }}*</label>
        <isd-validation-popup [formControlField]="userForm.get('surname')">
          <input
            type="text"
            required
            class="form-control"
            placeholder="{{ 'USER.SURNAME' | translate }}"
            name="surname"
            formControlName="surname"
            id="surname"
          />
        </isd-validation-popup>
      </div>
    </div>
    <div class="form-row mb-4">
      <div class="form-group col">
        <label for="mail">{{ 'USER.EMAIL' | translate }}*</label>
        <isd-validation-popup [formControlField]="userForm.get('mail')">
          <input
            type="mail"
            email
            required
            class="form-control"
            placeholder="{{ 'USER.EMAIL' | translate }}"
            name="mail"
            id="mail"
            formControlName="mail"
          />
        </isd-validation-popup>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="cancel()">
    {{ 'COMMON.BUTTON.CANCEL' | translate }}
  </button>
  <button
    type="button"
    [disabled]="
      userForm.invalid ||
      userForm.pristine ||
      (loadingService.isLoading(savingUserKey) | async)
    "
    class="btn btn-primary"
    data-dismiss="modal"
    (click)="save()"
  >
    <ng-container *ngIf="loadingService.isLoading(savingUserKey) | async">{{
      'COMMON.BUTTON.SAVING' | translate
    }}</ng-container>
    <ng-container *ngIf="!(loadingService.isLoading(savingUserKey) | async)">{{
      'COMMON.BUTTON.SAVE' | translate
    }}</ng-container>
  </button>
</div>
