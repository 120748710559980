import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IfPermittedDirective } from './if-permitted.directive';
import { IfNotPermittedDirective } from './if-not-permitted.directive';

@NgModule({
  declarations: [IfPermittedDirective, IfNotPermittedDirective],
  imports: [CommonModule],
  exports: [IfPermittedDirective, IfNotPermittedDirective]
})
export class PermissionModule {}
