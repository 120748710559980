<div class="p-3">
  <div class="row">
    <div class="col-12">
      <button
        *isdIfPermitted="'object.serviceCalendar.canRead'"
        class="btn btn-primary rounded-pill py-2 px-3 d-block mx-auto"
        (click)="OpenCalendarModal()"
      >
        <div class="mb-0 font-weight-bold">
          <div class="float-left mr-3">
            {{ 'OBJECT.CALENDAR.LABEL' | translate }}
          </div>
          <div class="float-right">
            <i class="fas fa-calendar-alt"></i>
          </div>
        </div>
      </button>
    </div>
  </div>
</div>
