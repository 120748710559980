import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { LoadingService } from 'customer-portal-framework-lib';
import { ZoneType } from 'src/app/dto/enums/zone-type.dto';
import { RoleDefinition } from 'src/app/dto/role-definition.dto';
import { ErrorHandlerService } from 'src/app/service/error-handler/error-handler.service';
import { whiteSpaceValidator } from 'src/app/validators/white-space.validator';
import { RoleDefinitionService } from '../../service/role-definition.service';
import { RoleService } from '../../service/role.service';
import { valueChangedValidator } from './../../../validators/value-changed.validator';

@Component({
  selector: 'isd-edit-role-definition',
  templateUrl: './edit-role-definition.component.html',
  styleUrls: ['./edit-role-definition.component.scss']
})
export class EditRoleDefinitionComponent implements OnInit {
  @Input() roleDefinition: RoleDefinition;
  initialRoleDefinition: RoleDefinition;
  savingRoleDefinitionKey = '';
  newRoleName: FormControl;
  toggled = false;
  editName = false;

  constructor(
    private readonly roleDefinitionService: RoleDefinitionService,
    private readonly errorHandler: ErrorHandlerService,
    private readonly roleService: RoleService,
    readonly loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.savingRoleDefinitionKey = `savingRoleDefinitionKey_${this.roleDefinition.id}`;
    this.initialRoleDefinition = this.roleDefinitionService.cloneRoleDefinition(
      this.roleDefinition
    );
    this.newRoleName = new FormControl(this.initialRoleDefinition.roleName, [
      Validators.required,
      whiteSpaceValidator(),
      valueChangedValidator(this.initialRoleDefinition.roleName)
    ]);
    this.newRoleName.setValue(this.roleDefinition.roleName);
    this.newRoleName.valueChanges.subscribe(() => {
      this.roleDefinition.roleName = this.newRoleName.value.trim();
    });
  }

  getZoneTypeTranslateKey(zoneTypeId: number): string {
    return ZoneType[zoneTypeId].toUpperCase();
  }

  toggleRole(): void {
    this.toggled = !this.toggled;
  }

  save(): void {
    this.loadingService.startLoading(this.savingRoleDefinitionKey);
    this.roleDefinitionService
      .updateRoleDefinition(this.roleDefinition)
      .pipe(
        this.errorHandler.pipeHandleError(),
        this.loadingService.pipeStopLoading(this.savingRoleDefinitionKey)
      )
      .subscribe(roleDefinition => {
        this.roleService.clearRoleCache();
        this.roleDefinition = roleDefinition;
        this.editName = false;
        this.initialRoleDefinition = this.roleDefinitionService.cloneRoleDefinition(
          this.roleDefinition
        );
      });
  }

  isObjectRole(): boolean {
    return this.roleDefinition.roleTypeId === ZoneType.ObjectZone;
  }

  isCustomerRole(): boolean {
    return this.roleDefinition.roleTypeId === ZoneType.CustomerZone;
  }

  roleDefinitionHasChanged(): boolean {
    return !this.roleDefinitionService.areEqual(
      this.initialRoleDefinition,
      this.roleDefinition
    );
  }

  roleNameIsValid(): boolean {
    return this.newRoleName.valid || !this.newRoleName.dirty;
  }

  resetRoleNameAndToggleEditMode(): void {
    this.editName = !this.editName;
    this.newRoleName.reset(this.initialRoleDefinition.roleName);
  }
}
