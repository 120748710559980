import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ContractInfo } from 'src/app/dto/contract-info.dto';

interface Option {
  text: string;
  value: number;
}

@Component({
  selector: 'isd-new-service-modal',
  templateUrl: './new-service-modal.component.html',
  styleUrls: ['./new-service-modal.component.scss']
})
export class NewServiceModalComponent {
  serviceCategoryImgUrl: string;
  serviceCategoryName: string;
  selectedContractNumber: number;
  contractNumberOptions: Option[];

  private readonly noSelectionOption = {
    text: this.translate.instant('OBJECT.NEW_SERVICES.NO_SELECTION'),
    value: 0
  };

  constructor(
    public activeModal: NgbActiveModal,
    private readonly translate: TranslateService
  ) {}

  setContracts(contracts: ContractInfo[]): void {
    this.contractNumberOptions = this.generateOptions(contracts);
    this.insertNoSelectOption(contracts);
    this.selectDefault();
  }

  setSelectedContract(contract: ContractInfo): void {
    this.selectedContractNumber = contract.contractNumber;
  }

  closeDialog(): void {
    this.activeModal.close(
      this.selectedContractNumber > 0 ? this.selectedContractNumber : undefined
    );
  }

  private generateOptions(contracts: ContractInfo[]): Option[] {
    return contracts.map(c => ({
      text: `${c.contractNumber}`,
      value: c.contractNumber
    }));
  }

  private insertNoSelectOption(contracts: ContractInfo[]): void {
    if (contracts.length > 1) {
      this.contractNumberOptions.splice(0, 0, this.noSelectionOption);
    }
  }

  private selectDefault(): void {
    this.selectedContractNumber = this.contractNumberOptions[0].value;
  }
}
