import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ILogger } from './ilogger.interface';

type Severity = 'warning' | 'critical';

export class RemoteLogger implements ILogger {
  constructor(
    private readonly category: string,
    private readonly http: HttpClient,
    private readonly location: Location
  ) {}

  log(message?: any, ...optionalParams: any[]): void {
    // ignore
  }

  warn(message?: any, ...optionalParams: any[]): void {
    this.send([message, ...optionalParams], 'warning');
  }

  error(message?: any, ...optionalParams: any[]): void {
    this.send([message, ...optionalParams], 'critical');
  }

  private async send(msgs: any[], severity: Severity): Promise<void> {
    try {
      const logEntry: LogEntry = {
        message: this.joinMessages(msgs),
        severity,
        userAgent: window.navigator.userAgent,
        source: `${this.location.path(true)}|${this.category}`
      };
      await this.http
        .post(
          `${environment.bolApiHost}/${environment.bolApiBaseUrl}/log`,
          logEntry
        )
        .toPromise();
    } catch {}
  }

  private joinMessages(msgs: any[]): string {
    let result = '';

    for (const msg of msgs) {
      if (result) {
        result += ' ';
      }

      if (typeof msg === 'object') {
        result += JSON.stringify(msg);
      } else {
        result += msg;
      }
    }

    return result;
  }
}

class LogEntry {
  message: string;
  severity: Severity;
  userAgent: string;
  source: string;
}
