<ng-container
  *ngIf="roleDefinitions$ && (roleDefinitions$ | async) as roleDefinitions"
>
  <b class="text-gray-600 mb-3 d-block">{{
    'ROLE.CUSTOMER_ROLES' | translate
  }}</b>
  <ng-container *ngFor="let roleDefinition of roleDefinitions.customerRoles">
    <div class="mb-3">
      <isd-edit-role-definition
        [roleDefinition]="roleDefinition"
      ></isd-edit-role-definition>
    </div>
  </ng-container>
  <b class="text-gray-600 mb-3 d-block mt-4">{{
    'ROLE.OBJECT_ROLES' | translate
  }}</b>
  <ng-container *ngFor="let roleDefinition of roleDefinitions.objectRoles">
    <div class="mb-3">
      <isd-edit-role-definition
        [roleDefinition]="roleDefinition"
      ></isd-edit-role-definition>
    </div>
  </ng-container>
</ng-container>
<isd-loading-spinner-block
  [height]="'400px'"
  *cpLoading="loadingRoleDefinitionsKey"
></isd-loading-spinner-block>
