import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { noop } from 'rxjs';
import {
  ContractInfo,
  ServiceCategoryInfo
} from 'src/app/dto/contract-info.dto';
import { NewServiceModalComponent } from '../new-service-modal/new-service-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ObjectContractsService {
  constructor(
    readonly modalService: NgbModal,
    private readonly translate: TranslateService
  ) {}

  getObjectServiceCategories(): ServiceCategoryInfo[] {
    return [
      { name: 'Außenreinigung', details: [], isInContract: true },
      { name: 'Büroreinigung', details: [], isInContract: true },
      { name: 'Grünflächenpflege', details: [], isInContract: true },
      { name: 'Haustechnik', details: [], isInContract: true },
      { name: 'Treppenhausreinigung', details: [], isInContract: true },
      { name: 'Winterdienst', details: [], isInContract: true }
    ];
  }

  getImageURL(name: string): string {
    return `./assets/img/${name}.jpg`;
  }

  initModal(
    contractList: ContractInfo[],
    serviceCategory: ServiceCategoryInfo,
    selectedContract: ContractInfo
  ): NgbModalRef {
    const modalRef = this.modalService.open(NewServiceModalComponent);
    const component = modalRef.componentInstance as NewServiceModalComponent;
    component.serviceCategoryName = serviceCategory.name;
    component.serviceCategoryImgUrl = this.getImageURL(serviceCategory.name);
    component.setContracts(
      this.getAvailableContractInfos(contractList, serviceCategory)
    );

    if (selectedContract) {
      component.setSelectedContract(selectedContract);
    }

    return modalRef;
  }

  mergeServiceCategories(contractInfos: ContractInfo[]): void {
    Object.entries(contractInfos).forEach(([, value]) => {
      Object.entries(this.getObjectServiceCategories()).forEach(
        ([, newServiceCategory]) => {
          if (
            !value.serviceCategories
              .map(s => s.name)
              .includes(newServiceCategory.name)
          ) {
            value.serviceCategories.push(newServiceCategory);
          }
        }
      );
    });
  }

  getAvailableContractInfos(
    contractInfos: ContractInfo[],
    serviceCategory: ServiceCategoryInfo
  ): ContractInfo[] {
    const contractInfoToReturn: ContractInfo[] = [];

    Object.entries(contractInfos).forEach(([, value]) => {
      Object.entries(value.serviceCategories).forEach(
        ([, singleServiceCategory]) => {
          if (
            singleServiceCategory.isInContract &&
            singleServiceCategory.name === serviceCategory.name
          ) {
            contractInfoToReturn.push(value);
          }
        }
      );
    });

    return contractInfoToReturn;
  }

  openNewServiceModal(
    officeMail: string,
    serviceCategory: ServiceCategoryInfo,
    contractList: ContractInfo[],
    selectedContract: ContractInfo
  ): void {
    const modalRef = this.initModal(
      contractList,
      serviceCategory,
      selectedContract
    );

    modalRef.result
      .then(selectedContractNumber =>
        this.sendMailBasedOnModalResponse(
          officeMail,
          selectedContractNumber,
          serviceCategory.name
        )
      )
      .catch(() => noop);
  }

  sendMailBasedOnModalResponse(
    officeMail: string,
    contractNumber: string,
    serviceCategory: string
  ): void {
    const subject = this.translate.instant('OBJECT.NEW_SERVICES.MAIL_SUBJECT');
    const body = this.generateMailBody(contractNumber, serviceCategory);
    const mailToString = this.generateMailtoString(officeMail, subject, body);
    window.location.href = mailToString;
  }

  private generateMailBody(
    contractNumber: string,
    serviceCategory: string
  ): string {
    if (contractNumber === undefined) {
      return this.translate.instant(
        'OBJECT.NEW_SERVICES.MAIL_BODY_WITHOUT_CONTRACT',
        { serviceCategory }
      );
    }

    return this.translate.instant('OBJECT.NEW_SERVICES.MAIL_BODY', {
      contractNumber,
      serviceCategory
    });
  }

  private generateMailtoString(
    mail: string,
    emailSubject: string,
    body: string
  ): string {
    const escapedSubject = encodeURI(this.replaceUmlauts(emailSubject));
    const escapedbody = encodeURI(this.replaceUmlauts(body));

    return `mailto:${mail}?subject=${escapedSubject}&body=${escapedbody}`;
  }

  filterContractsWithoutSpecialServices(
    contracts: ContractInfo[]
  ): ContractInfo[] {
    return contracts.filter(x => x.serviceCategories[0].details.length === 0);
  }

  private replaceUmlauts(value: string): string {
    let newString = value;

    newString = newString.replace(new RegExp('ä', 'g'), 'ae');
    newString = newString.replace(new RegExp('ö', 'g'), 'oe');
    newString = newString.replace(new RegExp('ü', 'g'), 'ue');
    newString = newString.replace(new RegExp('Ä', 'g'), 'Ae');
    newString = newString.replace(new RegExp('Ö', 'g'), 'Oe');
    newString = newString.replace(new RegExp('Ü', 'g'), 'Ue');
    newString = newString.replace(new RegExp('ß', 'g'), 'ss');

    return newString;
  }
}
