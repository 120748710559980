<ng-container *ngIf="formControlField">
  <div
    class="form-validation-group"
    [ngbPopover]="popOverContent"
    [disablePopover]="
      formControlField.valid ||
      !formControlField.errors ||
      (formControlField.untouched && formControlField.pristine)
    "
  >
    <ng-content></ng-content>
  </div>
  <ng-template #popOverContent>
    <ng-container *ngFor="let errorKey of getErrorKeys(); let first = first">
      <ng-container *ngIf="errorKey">
        <span>
          {{
            keyMapping.get(errorKey).valueOf()
              | translate: { error: formControlField.errors[errorKey] }
          }}
        </span>
        <hr *ngIf="!first" />
      </ng-container>
    </ng-container>
  </ng-template>
</ng-container>
