import { Injectable } from '@angular/core';
import { ILogger } from './ilogger.interface';
import { ConsoleLogger } from './console-logger';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { RemoteLogger } from './remote-logger';

@Injectable({
  providedIn: 'root'
})
export class LoggerFactoryService {
  private localLogger: ILogger;
  private remoteLogger: ILogger;

  constructor(
    private readonly http: HttpClient,
    private readonly location: Location
  ) {}

  getDefaultLogger(category: string): ILogger {
    return this.getRemoteLogger(category);
  }

  getLocalLogger(category: string): ILogger {
    if (!this.localLogger) {
      this.localLogger = new ConsoleLogger(category);
    }

    return this.localLogger;
  }

  getRemoteLogger(category: string): ILogger {
    if (!this.remoteLogger) {
      this.remoteLogger = new RemoteLogger(category, this.http, this.location);
    }

    return this.remoteLogger;
  }
}
