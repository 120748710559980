import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthLibModule } from 'ps-ng-auth-lib';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdminModule } from './admin/admin.module';
import localeDE from '@angular/common/locales/de';
import {
  CpLayoutModule,
  LoadingService,
  CpFormsModule,
  LoadingModule
} from 'customer-portal-framework-lib';
import { ZoneNavigationFiltersComponent } from './portal/zone-navigation-filters/zone-navigation-filters.component';
import { UserModule } from './user/user.module';
import { PermissionModule } from './permission/permission.module';
import { CustomerModule } from './customer/customer.module';
import { ToastrModule } from 'ngx-toastr';
import { ObjectModule } from './object/object.module';
import { FileTransferModule } from './file-transfer/file-transfer.module';
import { registerLocaleData } from '@angular/common';
import { PortalComponent } from './portal/portal.component';
import { FullscreenLoaderComponent } from './portal/fullscreen-loader/fullscreen-loader.component';
import { ForbiddenComponent } from './auth/forbidden/forbidden.component';
import { CustomerSelectComponent } from './portal/zone-navigation-filters/customer-select/customer-select.component';
import { ObjectLocationSelectComponent } from './portal/zone-navigation-filters/object-location-select/object-location-select.component';
import { ZoneNavigationFilterService } from './portal/zone-navigation-filters/zone-navigation-filter.service';
import { TermsModalComponent } from './portal/terms-modal/terms-modal.component';
import { SharedModule } from './shared/shared.module';
import { IntroVideoComponent } from './portal/intro-video/intro-video.component';
import { ErrorComponent } from './portal/error/error.component';
import { hashes as i18nHashes } from '../../i18n-cache-busting.json';

registerLocaleData(localeDE);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ZoneNavigationFiltersComponent,
    PortalComponent,
    FullscreenLoaderComponent,
    ForbiddenComponent,
    CustomerSelectComponent,
    ObjectLocationSelectComponent,
    TermsModalComponent,
    IntroVideoComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    UserModule,
    FileTransferModule,
    CpLayoutModule,
    HttpClientModule,
    AppRoutingModule,
    PermissionModule,
    NgSelectModule,
    CustomerModule,
    ObjectModule,
    SharedModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    LoadingModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AuthLibModule.forRoot(environment.authentication),
    AdminModule,
    CpFormsModule
  ],
  providers: [
    ZoneNavigationFilterService,
    LoadingService,
    {
      provide: LOCALE_ID,
      useValue: 'de'
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [TermsModalComponent, IntroVideoComponent]
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient): TranslateLoader {
  return {
    getTranslation: lang =>
      http.get<Object>(`./assets/i18n/${lang}.json?v=${i18nHashes[lang]}`)
  };
}
