import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ContractInfo } from '../../dto/contract-info.dto';
import { environment } from 'src/environments/environment';
import { ObservableCache } from '../observable-cache';

@Injectable()
export class ContractInformationService {
  private readonly cache = new ObservableCache<number, ContractInfo[]>(10);

  constructor(private readonly http: HttpClient) {}

  getObjectContractInfo(objectZoneId: number): Observable<ContractInfo[]> {
    return this.cache.getOrCreate(objectZoneId, () =>
      this.http.get<ContractInfo[]>(
        `${environment.bolApiHost}/${environment.bolApiBaseUrl}/contractinfos?objectZoneId=${objectZoneId}`
      )
    );
  }
}
