import { Injectable } from '@angular/core';
import { Observable, of, merge } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, switchMap, shareReplay, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  admin$: Observable<boolean>;
  adminSegmentKey = 'admin';

  constructor(private readonly router: Router) {
    const onNavigationEnd$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => this.hasAdminKeyInUrl())
    );
    const onNavigationInit$ = of(this.hasAdminKeyInUrl());

    this.admin$ = merge(onNavigationEnd$, onNavigationInit$).pipe(
      shareReplay(1)
    );
  }

  private hasAdminKeyInUrl(): boolean {
    const urlSegments = this.router.url.split('/');
    const hasAdmin = urlSegments.includes(this.adminSegmentKey);
    return hasAdmin;
  }
}
