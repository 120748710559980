<ng-container *ngIf="hasControlDates()">
  <div class="row">
    <div class="col-7">
      <b>
        <span class="d-none d-sm-inline-block icon-wrapper">
          <i class="fas fa-eye"></i>
        </span>
        {{ 'OBJECT.LAST_CHECK' | translate }}:
      </b>
    </div>
    <div class="col-5">
      <ng-container>
        <div>
          {{ latestControl | date: 'dd.MM.yyyy' }}
        </div>
        <isd-info-popup
          *ngIf="hasMoreControls()"
          [label]="'COMMON.SHOW_MORE' | translate"
          [popupTemplate]="controlsPopoverContent"
          [popoverClass]="'popover-sm text-center'"
        ></isd-info-popup>
      </ng-container>
      <ng-template #controlsPopoverContent>
        <ng-container
          *ngFor="let controlDate of remainingObjectControls; let last = last"
        >
          <p [class.m-0]="last" [class.mb-2]="!last">
            {{ controlDate | date: 'dd.MM.yyyy' }}
          </p>
        </ng-container>
      </ng-template>
      <ng-template #noControls>
        <span class="text-muted">
          {{ 'OBJECT.NO_CHECKS_DONE' | translate }}</span
        >
      </ng-template>
    </div>
  </div>
</ng-container>
