<div class="d-flex modal-header border-0">
  <h4 class="text-muted">
    {{ 'INTRO_VIDEO.WELCOME' | translate }}
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="video-wrapper">
    <video
      #videoPlayer
      [src]="videoLink"
      height="100px"
      controls
      disablePictureInPicture
      controlsList="nodownload"
      preload="none"
      poster="assets/img/vid_preview.png"
    >
      {{ 'INTRO_VIDEO.MISSING_BROWSER_SUPPORT' | translate }}
      <a [href]="videoLink">{{
        'INTRO_VIDEO.CLICK_TO_DOWNLOAD' | translate
      }}</a>
    </video>
  </div>
</div>
<div class="modal-footer" *ngIf="showButtons">
  <button (click)="activeModal.close('later')" class="btn btn-primary">
    {{ 'INTRO_VIDEO.WATCH_LATER' | translate }}
  </button>
  <button (click)="activeModal.close('no')" class="btn btn-primary">
    {{ 'INTRO_VIDEO.NO' | translate }}
  </button>
</div>
