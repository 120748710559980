import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, User } from 'ps-ng-auth-lib';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { LegalDocument } from 'src/app/dto/legal-document.dto';
import { environment } from './../../../environments/environment';
import { LegalDocumentService } from 'src/app/service/legal-document/legal-document.service';
import { TermsOfUseService } from 'src/app/service/terms-of-use/terms-of-use.service';
import { FileSaverService } from 'src/app/file-transfer/services/file-saver.service';
import { IntroVideoService } from 'src/app/portal/intro-video/intro-video.service';
@Component({
  selector: 'isd-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss']
})
export class UserDropdownComponent {
  user$: Observable<User>;
  dropdownToggled = false;
  legalDocumentsToggled = false;
  legalDocuments$: Observable<LegalDocument[]>;

  constructor(
    public auth: AuthService,
    public router: Router,
    private readonly terms: TermsOfUseService,
    private readonly video: IntroVideoService,
    private readonly fileSaver: FileSaverService,
    private readonly legalDocumentService: LegalDocumentService
  ) {
    this.user$ = this.auth.getUser().pipe(shareReplay(1));
    this.legalDocuments$ = this.legalDocumentService.getLegalDocuments();
  }

  toggleLegalDocuments(): void {
    this.legalDocumentsToggled = !this.legalDocumentsToggled;
  }

  openTerms(): void {
    this.terms.openModal();
  }

  showPrivacyPolicy(): void {
    window.open(environment.privacyPolicyLink, '_blank');
  }

  openVideo(): void {
    this.video.openModal(false);
  }

  openResetPage(): void {
    this.auth.resetPassword();
  }

  downloadLegalDocumentPdf(legalDocumentUrl: string, label: string): void {
    this.legalDocumentService
      .getLegalDocumentData(legalDocumentUrl)
      .subscribe(data => this.fileSaver.saveAs(data, label));
  }

  isInternalUser(): Observable<boolean> {
    return this.user$.pipe(
      map(user => user.tenant === environment.authentication.internal.tenantId)
    );
  }
}
