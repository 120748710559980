import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Customer } from '../dto/customer.dto';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ObservableCache } from '../service/observable-cache';
import { filter, shareReplay, switchMap, tap } from 'rxjs/operators';
import { NavigationStateService } from '../service/navigation-state/navigation-state.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerZoneDataService {
  currentCustomer$: Observable<Customer>;

  private readonly cache = new ObservableCache<number, Customer>(20);

  constructor(
    private readonly http: HttpClient,
    private readonly navigationState: NavigationStateService
  ) {
    this.currentCustomer$ = this.navigationState.getCustomerNumber().pipe(
      switchMap(customerNumber =>
        this.getCustomerByCustomerNumber$(customerNumber)
      ),
      shareReplay(1)
    );
  }

  getCustomerByCustomerNumber$(customerNumber: number): Observable<Customer> {
    return this.cache.getOrCreate(customerNumber, () =>
      this.fetchCustomer$(customerNumber)
    );
  }

  private fetchCustomer$(customerNumber: number): Observable<Customer> {
    return this.http.get<Customer>(
      `${environment.bolApiHost}/${environment.bolApiBaseUrl}/customers/${customerNumber}`
    );
  }
}
