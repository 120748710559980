<div class="d-flex modal-header border-0">
  <h4 class="text-muted">
    {{ 'TERMS.MODAL_TITLE' | translate }}
  </h4>
  <button
    *ngIf="!requestApproval"
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngFor="let termsInfo of termInfos">
    <h5 class="">
      {{ termsInfo.label }}
    </h5>
    <div *ngIf="termsInfo.list">
      <ol class="pl-3">
        <div *ngFor="let element of termsInfo.list">
          <li>{{ element }}</li>
        </div>
      </ol>
    </div>
    <div *ngIf="termsInfo.text">
      {{ termsInfo.text }}
    </div>
    <br />
  </div>
</div>
<div class="modal-footer" *ngIf="requestApproval">
  <button (click)="activeModal.close(true)" class="btn btn-primary">
    {{ 'TERMS.APPROVE' | translate }}
  </button>
</div>
