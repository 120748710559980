import { Injectable } from '@angular/core';
import { FolderItem } from '../dto/folder-item.dto';
import { Observable, ReplaySubject } from 'rxjs';
import { FileTransferBackendService } from './file-transfer-backend.service';
import { take, tap, map } from 'rxjs/operators';
import { FolderItemType } from '../enum/folder-item-type.enum';
import { FileSaverService } from './file-saver.service';
import { FileTransferBreadcrumbService } from './file-transfer-breadcrumb.service';

/**
 * Provides all the core functionality to interact with the ISDCP filesystem.
 */
@Injectable()
export class FileTransferService {
  folderItems$: Observable<FolderItem[]>;
  currentFolderType$: Observable<FolderItemType>;

  private readonly folderItemsSubject = new ReplaySubject<FolderItem[]>(1);
  private readonly currentFolderTypeSubject = new ReplaySubject<FolderItemType>(
    1
  );

  constructor(
    private readonly backend: FileTransferBackendService,
    private readonly fileSaver: FileSaverService,
    private readonly breadcrumbService: FileTransferBreadcrumbService
  ) {
    this.folderItems$ = this.folderItemsSubject
      .asObservable()
      .pipe(
        map(unorderedfolderItems =>
          unorderedfolderItems.sort(compareFolderItems)
        )
      );
    this.currentFolderType$ = this.currentFolderTypeSubject.asObservable();
  }

  setBreadcrumbRootName(breadcrumbRootName: string): void {
    this.breadcrumbService.breadcrumbRootName = breadcrumbRootName;
  }

  getBreadcrumb(): Observable<FolderItem[]> {
    return this.breadcrumbService.breadcrumbItems$;
  }

  async restoreStateFromBreadcrumb(breadcrumb: FolderItem[]): Promise<void> {
    const success = this.breadcrumbService.initFromFolderPath(breadcrumb);
    if (success) {
      await this.openFolder(this.breadcrumbService.getActiveFolderItem());
    }
  }

  async openCustomerZoneRoot(customerZoneId: number): Promise<void> {
    if (!this.hasPathChanged(customerZoneId.toString())) {
      return;
    }

    await this.backend
      .getFolderItemsFromPath(customerZoneId.toString())
      .pipe(
        tap(collection => {
          this.breadcrumbService.initFromCollection(collection);
          this.currentFolderTypeSubject.next(FolderItemType.Folder);
          this.folderItemsSubject.next(collection.folderItems);
        })
      )
      .toPromise();
  }

  async deleteFolderItem(folderItemToDelete: FolderItem): Promise<void> {
    await this.backend.deleteFolderItem(folderItemToDelete.path).toPromise();
    await this.removeFolderItemFromCurrentFolder(folderItemToDelete);
  }

  async renameFolderItem(changedFolderItem: FolderItem): Promise<void> {
    const updatedFolderItem = await this.backend
      .renameFolderItem(changedFolderItem.name, changedFolderItem.path)
      .toPromise();

    await this.updateFolderItemInCurrentFolder(updatedFolderItem);
  }

  async createFolder(folderName: string): Promise<void> {
    const createdFolderItem = await this.backend
      .createFolder(
        folderName,
        this.breadcrumbService.getActiveFolderItem().path
      )
      .toPromise();

    this.addFolderItemToCurrentFolder(createdFolderItem);
  }

  async uploadFile(file: File, force: boolean): Promise<FolderItem> {
    const createdFolderItem = await this.backend
      .uploadFile(
        file,
        this.breadcrumbService.getActiveFolderItem().path,
        force
      )
      .toPromise();

    if (!force) {
      this.addFolderItemToCurrentFolder(createdFolderItem);
    }
    return createdFolderItem;
  }

  async uploadToInbox(file: File, customerZoneId: number): Promise<void> {
    await this.backend.uploadToInbox(file, customerZoneId).toPromise();
  }

  async openBreadcrumbItem(breadcrumbItem: FolderItem): Promise<void> {
    if (!this.hasPathChanged(breadcrumbItem.path)) {
      return;
    }

    await this.openFolder(breadcrumbItem);
    this.breadcrumbService.removeAllBreadcrumbsRightOf(breadcrumbItem);
  }

  async openFolderItem(folderItemToOpen: FolderItem): Promise<void> {
    if (!this.hasPathChanged(folderItemToOpen.path)) {
      return;
    }

    if (folderItemToOpen.type === FolderItemType.File) {
      await this.openFile(folderItemToOpen);
    } else {
      await this.openFolder(folderItemToOpen);
      this.breadcrumbService.addFolderToBreadcrumb(folderItemToOpen);
    }
  }

  private async openFolder(folderToOpen: FolderItem): Promise<void> {
    const collection = await this.backend
      .getFolderItemsFromPath(folderToOpen.path)
      .toPromise();
    this.currentFolderTypeSubject.next(folderToOpen.type);
    this.folderItemsSubject.next(collection.folderItems);
  }

  private async openFile(fileToOpen: FolderItem): Promise<void> {
    const binaryData = await this.backend
      .downloadFile(fileToOpen.path)
      .toPromise();

    this.fileSaver.saveAs(binaryData, fileToOpen.name);
  }

  private async addFolderItemToCurrentFolder(
    folderItemToAdd: FolderItem
  ): Promise<void> {
    const currentFolderItemList = await this.folderItems$
      .pipe(take(1))
      .toPromise();
    const updatedfolderItemList = [...currentFolderItemList, folderItemToAdd];
    this.folderItemsSubject.next(updatedfolderItemList);
  }

  private async updateFolderItemInCurrentFolder(
    updatedFolderItem: FolderItem
  ): Promise<void> {
    const folderItems = await this.folderItems$.pipe(take(1)).toPromise();
    const clonedfolderItems = [...folderItems];
    const matchIndex = clonedfolderItems.findIndex(
      fi => fi.path === updatedFolderItem.path
    );

    if (matchIndex >= 0) {
      clonedfolderItems.splice(matchIndex, 1, updatedFolderItem);
    }

    this.folderItemsSubject.next(clonedfolderItems);
  }

  private async removeFolderItemFromCurrentFolder(
    folderItemToDelete: FolderItem
  ): Promise<void> {
    const folderItems = await this.folderItems$.pipe(take(1)).toPromise();
    const clonedfolderItems = [...folderItems];
    const matchIndex = clonedfolderItems.findIndex(
      fi => fi.path === folderItemToDelete.path
    );
    if (matchIndex >= 0) {
      clonedfolderItems.splice(matchIndex, 1);
    }
    this.folderItemsSubject.next(clonedfolderItems);
  }

  private hasPathChanged(path: string): boolean {
    return !this.breadcrumbService.isPathAlreadyActive(path);
  }
}

function compareFolderItems(
  folderItemA: FolderItem,
  folderItemB: FolderItem
): number {
  const typeCompare = folderItemB.type - folderItemA.type;
  const nameCompare = folderItemA.name.localeCompare(folderItemB.name);
  return typeCompare * 10 + nameCompare;
}
