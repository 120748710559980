import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerZoneStructure } from 'src/app/dto/customer-zone-structure.dto';
import { Customer } from 'src/app/dto/customer.dto';
import { NavigationStateService } from 'src/app/service/navigation-state/navigation-state.service';
import { CustomerZoneDataService } from 'src/app/customer/customer-zone-data.service';
import { CustomerZoneStructureService } from '../service/customer-zone-structure.service';
import { LoadingService } from 'customer-portal-framework-lib';
import { RoleService } from '../service/role.service';
import { ErrorHandlerService } from 'src/app/service/error-handler/error-handler.service';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'isd-admin-overview',
  templateUrl: './admin-overview.component.html',
  styleUrls: ['./admin-overview.component.scss']
})
export class AdminOverviewComponent implements OnInit {
  customer$: Observable<Customer>;
  loadingCustomerKey = 'LoadingCustomer';

  constructor(
    private readonly navigationStateService: NavigationStateService,
    private readonly customerZoneDataService: CustomerZoneDataService,
    readonly loadingService: LoadingService,
    private readonly errorHandler: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.initCustomerObservable();
  }

  private initCustomerObservable(): void {
    this.customer$ = this.navigationStateService.getCustomerNumber().pipe(
      this.errorHandler.pipeHandleError(),
      this.loadingService.pipeStartLoading(this.loadingCustomerKey),
      switchMap(customerNumber =>
        this.customerZoneDataService
          .getCustomerByCustomerNumber$(customerNumber)
          .pipe(this.errorHandler.pipeHandleError())
      ),
      this.loadingService.pipeStopLoading(this.loadingCustomerKey)
    );
  }
}
