import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import {
  CpFileSystemModule,
  LoadingModule
} from 'customer-portal-framework-lib';
import { PermissionModule } from '../permission/permission.module';
import { ContractInformationService } from '../service/contact-information/contract-information.service';
import { SharedModule } from '../shared/shared.module';
import { CalendarEventService } from './calendar-modal/calendar-event.service';
import { CalendarModalComponent } from './calendar-modal/calendar-modal.component';
import { NewServiceModalComponent } from './new-service-modal/new-service-modal.component';
import { ObjectContractsComponent } from './object-contracts/object-contracts.component';
import { ObjectDocumentsComponent } from './object-documents/object-documents.component';
import { ObjectDocumentsService } from './object-documents/object-documents.service';
import { ObjectControlsComponent } from './object-information/object-controls/object-controls.component';
import { ObjectInformationComponent } from './object-information/object-information.component';
import { ObjectReportsComponent } from './object-information/object-reports/object-reports.component';
import { ObjectOverviewComponent } from './object-overview/object-overview.component';
import { ServicesCardComponent } from './object-overview/services-card/services-card.component';
import { ObjectServiceCalendarComponent } from './object-service-calendar/object-service-calendar.component';
import { AdditionalServicesBannerComponent } from './object-overview/additional-services-banner/additional-services-banner.component';
import { CalendarImageModalComponent } from './calendar-image-modal/calendar-image-modal.component';

FullCalendarModule.registerPlugins([dayGridPlugin]);
@NgModule({
  declarations: [
    ObjectServiceCalendarComponent,
    ObjectOverviewComponent,
    ObjectInformationComponent,
    CalendarModalComponent,
    CalendarImageModalComponent,
    ObjectContractsComponent,
    ObjectDocumentsComponent,
    ObjectControlsComponent,
    ObjectReportsComponent,
    ServicesCardComponent,
    NewServiceModalComponent,
    AdditionalServicesBannerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    PermissionModule,
    NgbPopoverModule,
    NgSelectModule,
    RouterModule,
    FullCalendarModule,
    LoadingModule,
    CpFileSystemModule,
    FullCalendarModule,
    SharedModule,
    FormsModule
  ],
  entryComponents: [CalendarModalComponent, CalendarImageModalComponent, NewServiceModalComponent],
  providers: [
    CalendarEventService,
    ObjectDocumentsService,
    ContractInformationService
  ]
})
export class ObjectModule {}
