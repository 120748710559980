<ng-select
  #select
  [placeholder]="'USER.SELECT_USER' | translate"
  [loadingText]="'COMMON.SELECT.LOADING' | translate"
  [typeToSearchText]="'USER.SELECT_FILTER_HINT' | translate"
  [notFoundText]="'COMMON.SELECT.NOT_FOUND' | translate"
  [clearAllText]="'COMMON.SELECT.CLEAR' | translate"
  id="userSelectionInput"
  [items]="users$ | async"
  [loading]="isCurrentlySearching"
  [typeahead]="searchInput$"
  [(ngModel)]="selectedUser"
  (change)="onSelectionChange()"
  [disabled]="disabled"
>
  <ng-template
    ng-option-tmp
    let-item="item"
    let-search="searchTerm"
    let-index="index"
  >
    <span class="font-weight-bold">{{ item.displayName }} </span> |
    <span class="text-muted font-weight-light">{{ item.mail }}</span>
  </ng-template>
  <ng-template ng-label-tmp let-item="item">
    <span class="font-weight-bold">{{ item.displayName }} </span> |
    <span class="text-muted font-weight-light">{{ item.mail }}</span>
  </ng-template>
  <ng-template ng-footer-tmp>
    <div class="p-1">
      <button class="p-0 btn btn-link" (click)="create()">{{
        'USER.CREATE_NEW' | translate
      }}</button>
    </div>
  </ng-template>
</ng-select>
