import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

export const errorUrl = 'error';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(private readonly router: Router) {}

  async sendToErrorPage(): Promise<boolean> {
    return this.router.navigate([this.getAbsoluteErrorPath()]);
  }

  private getAbsoluteErrorPath(): string {
    return `/${errorUrl}`;
  }
}
