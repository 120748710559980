import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'isd-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent {
  @Input() popupTemplate: TemplateRef<any>;
  constructor(readonly activeModal: NgbActiveModal) {}
}
