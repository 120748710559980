<div
  class="row justify-content-end"
  *isdIfPermitted="'customer.documents.canRead'"
>
  <cp-file-upload
    [uploadFn]="onUploadFilesToInbox()"
    (uploaded)="onUploadFilesToInboxDone()"
    [translation]="getUploadToInboxTranslation()"
  ></cp-file-upload>
</div>
<div
  class="row justify-content-center"
  *isdIfNotPermitted="'customer.documents.canRead'"
>
  <div class="col-6">
    <div class="row">
      <h4 class="w-100 text-center text-muted">
        {{ 'FILESYSTEM.UPLOAD_TO_INBOX' | translate }}
      </h4>
    </div>
    <div class="row my-3">
      <p class="w-100 text-center text-black-50">
        {{ 'FILESYSTEM.UPLOAD_TO_INBOX_DESCRIPTION' | translate }}
      </p>
    </div>
    <div class="row justify-content-center">
      <cp-file-upload
        [uploadFn]="onUploadFilesToInbox()"
        (uploaded)="onUploadFilesToInboxDone()"
        [translation]="getUploadToInboxTranslation()"
        type="button"
      ></cp-file-upload>
    </div>
  </div>
</div>
