<div *ngIf="customer$ | async as customer">
  <div class="row">
    <div class="col-12 mb-4 col-xl-4 mb-xl-0">
      <ng-container
        *ngIf="!(loadingService.isLoading(loadingCustomersKey) | async)"
      >
        <isd-customer-info
          class="w-100"
          [customer]="customer"
        ></isd-customer-info>
      </ng-container>
      <isd-loading-spinner-block
        [height]="'300px'"
        *cpLoading="loadingCustomersKey"
      ></isd-loading-spinner-block>
    </div>
    <div class="col-12 col-xl-8">
      <isd-file-transfer-wrapper></isd-file-transfer-wrapper>
    </div>
  </div>
</div>
