import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { User, UserCreate } from 'src/app/dto/user.dto';
import { Validators, FormBuilder } from '@angular/forms';
import { ExternalUserService } from 'src/app/service/external-user/external-user.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'customer-portal-framework-lib';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationStateService } from 'src/app/service/navigation-state/navigation-state.service';
import { take, switchMap } from 'rxjs/operators';

export class Salutation {
  label: string;
  value: string;
}

@Component({
  selector: 'isd-user-create-modal',
  templateUrl: './user-create-modal.component.html',
  styleUrls: ['./user-create-modal.component.scss']
})
export class UserCreateModalComponent implements OnInit {
  userForm = this.fb.group({
    givenName: ['', Validators.required],
    surname: ['', Validators.required],
    salutation: ['', Validators.required],
    mail: ['', [Validators.required, Validators.email]]
  });

  salutationOptions: Salutation[];
  savingUserKey = 'savingUserKey';

  user: User;

  constructor(
    private readonly externalUserService: ExternalUserService,
    private readonly navigationState: NavigationStateService,
    readonly translateService: TranslateService,
    readonly loadingService: LoadingService,
    private readonly fb: FormBuilder,
    public activeModal: NgbActiveModal
  ) {
    this.loadGermanSalutations();
  }

  ngOnInit(): void {
    this.userForm.valueChanges.subscribe(() => {
      this.user = {
        ...this.user,
        ...this.userForm.value,
        displayName: this.getDisplayName()
      };
    });
  }

  save(): void {
    this.loadingService.startLoading(this.savingUserKey);
    this.navigationState
      .getCustomerZoneId()
      .pipe(
        take(1),
        switchMap(customerZoneId =>
          this.externalUserService.createExternalUser(
            this.generateCreateModel(this.user, customerZoneId)
          )
        )
      )
      .pipe(this.loadingService.pipeStopLoading(this.savingUserKey))
      .subscribe(createdUser => this.activeModal.close(createdUser));
  }

  cancel(): void {
    this.activeModal.close();
  }

  private loadGermanSalutations(): void {
    const mr = this.translateService.instant('USER.MR');
    const mrs = this.translateService.instant('USER.MRS');
    const none = this.translateService.instant('USER.NO_SALUTATION');
    this.salutationOptions = [
      { value: mr, label: mr },
      { value: mrs, label: mrs },
      { value: 'none', label: none }
    ];
  }

  private getDisplayName(): string {
    if (this.userForm.value.salutation === 'none') {
      return `${this.userForm.value.givenName} ${this.userForm.value.surname}`;
    }

    return `${this.userForm.value.salutation} ${this.userForm.value.givenName} ${this.userForm.value.surname}`;
  }

  private generateCreateModel(user: User, customerZoneId: number): UserCreate {
    return {
      id: user.id,
      givenName: user.givenName,
      surname: user.surname,
      mail: user.mail,
      displayName: user.displayName,
      customerZoneId
    };
  }
}
