import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  FileSystemBreadcrumbItem,
  FileSystemItem,
  FileSystemItemType,
  LoadingService
} from 'customer-portal-framework-lib';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { FileSaverService } from 'src/app/file-transfer/services/file-saver.service';
import { PermissionService } from 'src/app/permission/permission.service';
import { ErrorHandlerService } from 'src/app/service/error-handler/error-handler.service';
import { ObjectDocumentsService } from './object-documents.service';

@Component({
  selector: 'isd-object-documents',
  templateUrl: './object-documents.component.html',
  styleUrls: ['./object-documents.component.scss']
})
export class ObjectDocumentsComponent {
  fileSystemItems$ = new ReplaySubject<FileSystemItem[]>(1);
  breadcrumbItems$ = new BehaviorSubject<FileSystemBreadcrumbItem[]>([]);
  hasPermission = false;

  loadingKey = 'objectDocuments';

  constructor(
    private readonly documentsService: ObjectDocumentsService,
    private readonly permissionService: PermissionService,
    private readonly translate: TranslateService,
    private readonly fileSaver: FileSaverService,
    private readonly errorHandler: ErrorHandlerService,
    public loading: LoadingService
  ) {
    this.getObjectDocumentPermission()
      .pipe(
        filter(hasPermission => hasPermission),
        this.loading.pipeStartLoading(this.loadingKey),
        switchMap(() => this.documentsService.getFolderContent())
      )
      .subscribe(f => {
        this.fileSystemItems$.next(f);
        this.breadcrumbItems$.next([this.generateRootBreadcrumb()]);
        this.loading.stopLoading(this.loadingKey);
      });
  }

  openBreadcrumbItem(item: FileSystemBreadcrumbItem): void {
    this.openFolder(item);
  }

  fileSystemItemClicked(item: FileSystemItem): void {
    if (item.fileType === FileSystemItemType.SystemFolder) {
      this.openFolder(item);
    } else if (item.fileType === FileSystemItemType.File) {
      this.downloadFile(item);
    }
  }

  isInvoicesFolderActive(): boolean {
    const bc = this.breadcrumbItems$.value;
    return (
      bc.length > 1 && bc[1].uri === this.documentsService.getInvoicesUrl()
    );
  }

  isRootActive(): boolean {
    return this.breadcrumbItems$.value.length === 1;
  }

  isContractsFolderActive(): boolean {
    const bc = this.breadcrumbItems$.value;
    return (
      bc.length > 1 && bc[1].uri === this.documentsService.getContractsUrl()
    );
  }

  private downloadFile(item: FileSystemItem): void {
    this.documentsService
      .downloadDocument(item.uri)
      .pipe(this.errorHandler.pipeCatchError())
      .subscribe(binaryData => this.fileSaver.saveAs(binaryData, item.label));
  }

  private getObjectDocumentPermission(): Observable<boolean> {
    return this.permissionService.getCurrentObjectPermission().pipe(
      map(
        permissions =>
          permissions.contract.canRead || permissions.invoice.canRead
      ),
      tap(hasPermission => (this.hasPermission = hasPermission))
    );
  }

  private openFolder(item: { uri: string; label: string }): void {
    this.loading.startLoading(this.loadingKey);

    this.documentsService
      .getFolderContent(item.uri)
      .pipe(
        this.loading.pipeStopLoading(this.loadingKey),
        this.errorHandler.pipeCatchError()
      )
      .subscribe(folderContent => {
        this.fileSystemItems$.next(folderContent);
        this.updateBreadcrumb(item);
      });
  }

  private updateBreadcrumb(item: { uri: string; label: string }): void {
    const breadcrumb = [...this.breadcrumbItems$.value];

    if (item.uri === this.documentsService.getRootUrl()) {
      breadcrumb.splice(1, 1);
    } else {
      breadcrumb.push({ uri: item.uri, label: item.label });
    }

    this.breadcrumbItems$.next(breadcrumb);
  }

  private generateRootBreadcrumb(): FileSystemBreadcrumbItem {
    return {
      label: this.translate.instant('OBJECT.DOCUMENTS.FILES'),
      uri: this.documentsService.getRootUrl()
    };
  }
}
