import { Injectable } from '@angular/core';
import { UserRoles } from 'src/app/dto/user-roles.dto';
import { RoleMembership } from 'src/app/dto/role-membership.dto';
import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UserRolesService {
  constructor() {}

  toUserRolesPipe(): OperatorFunction<RoleMembership[], UserRoles[]> {
    return map(roleMembershipList =>
      this.convertRoleMembershipToUserRoles(roleMembershipList)
    );
  }

  convertRoleMembershipToUserRoles(
    roleMembershipList: RoleMembership[]
  ): UserRoles[] {
    const userRoles: UserRoles[] = [];

    for (const roleMembership of roleMembershipList) {
      let userRole = userRoles.find(
        ur => ur.user.id === roleMembership.user.id
      );
      if (!userRole) {
        userRole = { user: roleMembership.user, roles: [] };
        userRoles.push(userRole);
      }

      userRole.roles.push(roleMembership);
    }

    return userRoles;
  }
}
