export class CustomerZonePermission {
  roles: Rights;
  documents: Rights;
  documentInbox: Rights;
}

export class ObjectZonePermission {
  contract: Rights;
  invoice: Rights;
  serviceCalendar: Rights;
  objectControls: Rights;
}

export interface Rights {
  canRead: boolean;
  canCreate: boolean;
  canEdit: boolean;
  canDelete: boolean;
}

export const NO_RIGHTS: Rights = {
  canRead: false,
  canCreate: false,
  canEdit: false,
  canDelete: false
};

export const NO_CUSTOMERZONE_PERMISSIONS: CustomerZonePermission = {
  roles: NO_RIGHTS,
  documentInbox: NO_RIGHTS,
  documents: NO_RIGHTS
};

export const NO_OBJECTZONE_PERMISSION: ObjectZonePermission = {
  contract: NO_RIGHTS,
  invoice: NO_RIGHTS,
  serviceCalendar: NO_RIGHTS,
  objectControls: NO_RIGHTS
};
