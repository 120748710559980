import { Component, OnInit, Input } from '@angular/core';
import { LoadingService } from 'customer-portal-framework-lib';

@Component({
  selector: 'isd-loading-spinner-overlay',
  templateUrl: './loading-spinner-overlay.component.html',
  styleUrls: ['./loading-spinner-overlay.component.scss']
})
export class LoadingSpinnerOverlayComponent {
  @Input() loadingKey: string;
  constructor(readonly loadingService: LoadingService) {}
}
