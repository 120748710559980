import { Component, OnInit } from '@angular/core';
import { AuthService } from 'ps-ng-auth-lib';
import { Router } from '@angular/router';

@Component({
  selector: 'isd-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent {
  constructor(readonly auth: AuthService) {}
}
