<div class="container-fluid vh-100 bg-primary-lightest">
  <div class="row vh-100 align-items-center justify-content-center">
    <div class="col-sm-8 col-md-6 col-lg-5 col-xl-4">
      <div class="bg-white shadow rounded-lg p-5 d-block">
        <img
          class="isd-logo w-100 h-auto cursor-pointer"
          routerLink="/"
          src="../assets/svg/isd_logo.svg"
        />
        <h4 class="text-center text-danger">
          {{ 'COMMON.FORBIDDEN' | translate }}
        </h4>
        <ng-container *ngIf="auth.getUser() | async as user">
          <div class="text-center mt-3 mb-2">
            {{ user.name }}
          </div>
          <div class="text-center">
            <a routerLink="/" class="my-2">
              {{ 'COMMON.BUTTON.BACK_TO_HOME' | translate }}
            </a>
            <div *ngIf="auth.isAuthenticated() | async">
              {{ 'COMMON.OR' | translate }}
              <br />
              <a [routerLink]="" (click)="auth.logout()">
                {{ 'AUTH.LOGOUT' | translate }}
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
