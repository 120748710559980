import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Month } from './month.dto';
import { ServiceEventsService } from 'src/app/service/service-events/service-events.service';
import { NavigationStateService } from 'src/app/service/navigation-state/navigation-state.service';
import { map, tap, take, switchMap } from 'rxjs/operators';
import { EventInput } from '@fullcalendar/core';
import { ServiceEventStatus } from 'src/app/dto/enums/service-event-status.enum';
import * as dayjs from 'dayjs';
import { ErrorHandlerService } from 'src/app/service/error-handler/error-handler.service';
import { DateRange } from './date-range.dto';

@Injectable()
export class CalendarEventService {
  private readonly loadedMonths: Month[] = [];
  private loadedEvents: EventInput[] = [];

  constructor(
    private readonly serviceEventsService: ServiceEventsService,
    private readonly navigationStateService: NavigationStateService,
    private readonly errorHandler: ErrorHandlerService
  ) {}

  loadEventsByDateRange(dateRange: DateRange): Observable<EventInput[]> {
    const monthYears = this.getMonthYearsInDateRange(dateRange);
    const monthYearsDelta = this.getMonthYearsDelta(monthYears);

    if (monthYearsDelta.length === 0) {
      return of(this.loadedEvents);
    }

    return this.loadEventsByMonthYears(monthYearsDelta).pipe(
      map(eventInputs => [...this.loadedEvents, ...eventInputs]),
      tap(events => {
        this.loadedMonths.push(...monthYearsDelta);
        this.loadedEvents = events;
        return events;
      })
    );
  }

  private getMonthYearsDelta(monthYears: Month[]): Month[] {
    return monthYears.filter(
      my =>
        this.loadedMonths.findIndex(
          cachedMonthYear =>
            cachedMonthYear.year === my.year &&
            cachedMonthYear.month === my.month
        ) === -1
    );
  }

  private getMonthYearsInDateRange(dateRange: DateRange): Month[] {
    const monthYears: Month[] = [];

    for (
      let date = dayjs(dateRange.start);
      !dayjs(date).isAfter(dateRange.end);
      date = date.add(1, 'month')
    ) {
      monthYears.push({ month: date.month(), year: date.year() });
    }

    return monthYears;
  }

  private loadEventsByMonthYears(
    monthYears: Month[]
  ): Observable<EventInput[]> {
    const startDate = this.getStartDate(monthYears);
    const endDate = this.getEndDate(monthYears);

    return this.navigationStateService.getObjectLocationId().pipe(
      take(1),
      switchMap(olid =>
        this.serviceEventsService.getObjectLocationServiceEvents(
          olid,
          startDate,
          endDate
        )
      ),
      this.errorHandler.pipeHandleError(),
      map(serviceEvents =>
        serviceEvents.map(serviceEvent => {
          const eventInput: EventInput = {
            date: serviceEvent.date,
            className: this.getEventClassByStatusCode(serviceEvent.statusCode),
            title: serviceEvent.category,
            extendedProps: {
              imageUrl: serviceEvent.imageUrl
            }
          };
          return eventInput;
        })
      )
    );
  }

  private getStartDate(monthYears: Month[]): Date {
    const startMonthYear = monthYears[0];
    return new Date(startMonthYear.year, startMonthYear.month);
  }

  private getEndDate(monthYears: Month[]): Date {
    const endMonthYear = monthYears[monthYears.length - 1];
    return this.getLastDayOfMonth(endMonthYear);
  }

  private getLastDayOfMonth(monthYear: Month): Date {
    let lastDayOfMonth = dayjs(new Date(monthYear.year, monthYear.month));
    lastDayOfMonth = lastDayOfMonth.date(lastDayOfMonth.daysInMonth());
    return lastDayOfMonth.toDate();
  }

  private getEventClassByStatusCode(statusCode: ServiceEventStatus): string {
    switch (statusCode) {
      case ServiceEventStatus.PLANNED:
        return 'event-planned';
      case ServiceEventStatus.DONE:
        return 'event-done';
      default:
        return statusCode.toString();
    }
  }
}
