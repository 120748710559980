<div *ngIf="roleDefinition" class="role border p-3 rounded-sm">
  <div class="role-head h5 m-0">
    <div class="d-flex">
      <div class="d-flex flex-grow-1 overflow-hidden">
        <i
          class="fas cursor-pointer hover-opacity-2 mr-2 pt-1"
          (click)="toggleRole()"
          [class.fa-chevron-up]="toggled"
          [class.fa-chevron-down]="!toggled"
        ></i>
        <div *ngIf="!editName" class="text-truncate">
          {{ roleDefinition.roleName }}
        </div>
        <input
          *ngIf="editName"
          id="changNameInputField"
          class="form-control flex-fill"
          type="text"
          [placeholder]="'ROLE.NEW_NAME' | translate"
          [formControl]="newRoleName"
        />
      </div>
      <div class="ml-2 d-flex align-items-center">
        <i
          class="fas cursor-pointer hover-opacity-3"
          (click)="resetRoleNameAndToggleEditMode()"
          [class.fa-edit]="!editName"
          [class.fa-times]="editName"
        ></i>
      </div>
    </div>
  </div>
  <div *ngIf="toggled" class="role-body">
    <div class="role-scope row mb-3 mt-3 p-3 bg-primary-lightest">
      <div class="col align-self-start text-left">
        <i class="fas fa-layer-group"></i> {{ 'ROLE.LAYER' | translate }}
        <i
          class="fas fa-info-circle cursor-pointer hover-opacity-2 text-primary"
          [popoverClass]="'popover-md'"
          [ngbPopover]="'ROLE.INFORMATION.LAYER' | translate"
          placement="top"
        ></i>
      </div>
      <div class="col-2 align-self-end text-center">
        {{
          'PERMISSION.TYPE.' +
            getZoneTypeTranslateKey(roleDefinition.roleTypeId) | translate
        }}
      </div>
    </div>

    <div class="mb-3">
      <div class="row">
        <div class="col">
          <button
            *ngIf="roleDefinitionHasChanged() && roleNameIsValid()"
            [disabled]="
              loadingService.isLoading(savingRoleDefinitionKey) | async
            "
            class="btn btn-primary"
            (click)="save()"
          >
            <ng-container
              *ngIf="
                !(loadingService.isLoading(savingRoleDefinitionKey) | async)
              "
            >
              {{ 'COMMON.BUTTON.SAVE' | translate }}
            </ng-container>
            <ng-container
              *ngIf="loadingService.isLoading(savingRoleDefinitionKey) | async"
            >
              {{ 'COMMON.BUTTON.SAVING' | translate }}
            </ng-container>
          </button>
        </div>
      </div>
    </div>
    <div class="role-setup">
      <div class="row premission-group-head mb-2">
        <div class="col-9 col-md-10">
          <div class="h5 text-muted">
            {{ 'PERMISSION.GROUP.CUSTOMER_DATA' | translate }}
          </div>
        </div>
        <div class="col-3 col-md-2">
          <div class="text-muted text-center">
            {{ 'ROLE.ACCESS' | translate }}
          </div>
        </div>
      </div>

      <isd-permission-row
        [title]="'PERMISSION.ADDRESS_AND_CONTACT_OF_CUSTOMER' | translate"
        [description]="
          'PERMISSION.INFORMATION.ADDRESS_AND_CONTACT_OF_CUSTOMER' | translate
        "
        [(checked)]="roleDefinition.customerInfo"
        [disabled]="true"
      ></isd-permission-row>

      <isd-permission-row
        *ngIf="isCustomerRole()"
        [title]="'PERMISSION.UPLOAD_DOCUMENTS' | translate"
        [description]="'PERMISSION.INFORMATION.UPLOAD_DOCUMENTS' | translate"
        [(checked)]="roleDefinition.documentInbox"
      ></isd-permission-row>

      <isd-permission-row
        *ngIf="isCustomerRole()"
        [title]="'PERMISSION.READ_DOCUMENTS' | translate"
        [description]="'PERMISSION.INFORMATION.READ_DOCUMENTS' | translate"
        [(checked)]="roleDefinition.customerDocuments"
      ></isd-permission-row>

      <div class="row premission-group-head mb-2">
        <div class="col-9 col-md-10">
          <div class="h5 text-muted">
            {{ 'PERMISSION.GROUP.OBJECT_DATA' | translate }}
          </div>
        </div>
      </div>

      <isd-permission-row
        [title]="'PERMISSION.ADDRESS' | translate"
        [description]="'PERMISSION.INFORMATION.ADDRESS' | translate"
        [(checked)]="roleDefinition.objectData"
        [disabled]="true"
      ></isd-permission-row>

      <isd-permission-row
        [title]="'PERMISSION.CONTACTPERSON_ISD' | translate"
        [description]="'PERMISSION.INFORMATION.CONTACTPERSON_ISD' | translate"
        [(checked)]="roleDefinition.contactPersonsIsd"
        [disabled]="true"
      ></isd-permission-row>

      <isd-permission-row
        [title]="'PERMISSION.OBJECTCONTROLS' | translate"
        [description]="'PERMISSION.INFORMATION.OBJECTCONTROLS' | translate"
        [(checked)]="roleDefinition.objectControls"
      ></isd-permission-row>

      <isd-permission-row
        [title]="'PERMISSION.INVOICES' | translate"
        [description]="'PERMISSION.INFORMATION.INVOICES' | translate"
        [(checked)]="roleDefinition.invoices"
      ></isd-permission-row>

      <isd-permission-row
        [title]="'PERMISSION.SERVICE_CALENDAR' | translate"
        [description]="'PERMISSION.INFORMATION.SERVICE_CALENDAR' | translate"
        [(checked)]="roleDefinition.serviceCalendar"
      ></isd-permission-row>

      <isd-permission-row
        [title]="'PERMISSION.CONTRACT_DOCUMENTS' | translate"
        [description]="'PERMISSION.INFORMATION.CONTRACT_DOCUMENTS' | translate"
        [(checked)]="roleDefinition.contractDocuments"
      ></isd-permission-row>
    </div>
  </div>
  <div class="role-footer">
    <div class="row">
      <div class="col">
        <button
          *ngIf="roleDefinitionHasChanged() && roleNameIsValid()"
          [disabled]="loadingService.isLoading(savingRoleDefinitionKey) | async"
          class="btn btn-primary"
          [class.mt-3]="!toggled"
          (click)="save()"
        >
          <ng-container
            *ngIf="!(loadingService.isLoading(savingRoleDefinitionKey) | async)"
          >
            {{ 'COMMON.BUTTON.SAVE' | translate }}
          </ng-container>
          <ng-container
            *ngIf="loadingService.isLoading(savingRoleDefinitionKey) | async"
          >
            {{ 'COMMON.BUTTON.SAVING' | translate }}
          </ng-container>
        </button>
      </div>
    </div>
  </div>
</div>
