import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { FolderItem, Folder } from '../dto/folder-item.dto';
import { FolderPathBookmarkService } from './folder-path-bookmark.service';
import { filter } from 'rxjs/operators';
import { FolderItemCollection } from '../dto/folder-item-collection.dto';
import { FolderItemType } from '../enum/folder-item-type.enum';

@Injectable()
export class FileTransferBreadcrumbService {
  breadcrumbItems$: Observable<FolderItem[]>;
  breadcrumbRootName: string;

  private readonly breadcrumbItemsSubject = new BehaviorSubject<FolderItem[]>(
    undefined
  );

  constructor(private readonly bookmark: FolderPathBookmarkService) {
    this.breadcrumbItems$ = this.breadcrumbItemsSubject.pipe(
      filter(breadCrumbItems => breadCrumbItems !== undefined)
    );
  }

  /**
   * Tries to initialize breadcrumb from given folderpath. Returns true on success. False otherwise.
   */
  initFromFolderPath(folderPath: FolderItem[]): boolean {
    if (!folderPath || folderPath.length === 0) {
      return false;
    }

    const lastEntry = folderPath[folderPath.length - 1];
    if (this.isPathAlreadyActive(lastEntry.path)) {
      return false;
    }

    folderPath[0].name = this.breadcrumbRootName;
    this.breadcrumbItemsSubject.next(folderPath);
    return true;
  }

  initFromCollection(collection: FolderItemCollection): void {
    const breadcrumbItems: FolderItem[] = [
      {
        name: this.breadcrumbRootName,
        path: collection.path,
        type: FolderItemType.Folder
      }
    ];
    this.breadcrumbItemsSubject.next(breadcrumbItems);
    this.bookmark.set(breadcrumbItems);
  }

  addFolderToBreadcrumb(folder: FolderItem): void {
    const alreadyExists = this.breadcrumbItemsSubject.value.find(
      bi => bi.path === folder.path
    );
    if (alreadyExists) {
      return;
    }

    const breadcrumbItems = [...this.breadcrumbItemsSubject.value, folder];
    this.breadcrumbItemsSubject.next(breadcrumbItems);
    this.bookmark.set(breadcrumbItems);
  }

  removeAllBreadcrumbsRightOf(breadcrumbItem: Folder): void {
    const clone = [...this.breadcrumbItemsSubject.value];

    while (clone[clone.length - 1].path !== breadcrumbItem.path) {
      clone.pop();
    }

    this.breadcrumbItemsSubject.next(clone);
    this.bookmark.set(clone);
  }

  getActiveFolderItem(): FolderItem {
    const breadcrumbItems = this.breadcrumbItemsSubject.value;
    return breadcrumbItems && breadcrumbItems[breadcrumbItems.length - 1];
  }

  isPathAlreadyActive(path: string): boolean {
    const active = this.getActiveFolderItem();
    return path === (active && active.path);
  }
}
