import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'isd-validation-popup',
  templateUrl: './validation-popup.component.html',
  styleUrls: ['./validation-popup.component.scss']
})
export class ValidationPopupComponent {
  @Input() formControlField: FormControl;

  @Input() set customErrorMessages(customErrorMessages: Map<string, string>) {
    this.keyMapping = new Map([
      ...Array.from(this.keyMapping.entries()),
      ...Array.from(customErrorMessages.entries())
    ]);
  }

  keyMapping = new Map<string, any>([
    ['required', 'FORM.ERROR.REQUIRED'],
    ['minlength', 'FORM.ERROR.MINLENGTH'],
    ['maxlength', 'FORM.ERROR.MAXLENGTH'],
    ['min', 'FORM.ERROR.MIN'],
    ['max', 'FORM.ERROR.MAX'],
    ['dateIsAfterOtherField', 'FORM.ERROR.DATE_IS_AFTER'],
    ['minDate', 'FORM.ERROR.MIN_DATE'],
    ['timeFieldAfterOther', 'FORM.ERROR.TIME_FIELD_AFTER_OTHER'],
    ['email', 'FORM.ERROR.EMAIL']
  ]);

  getErrorKeys(): string[] {
    if (!this.formControlField.errors) {
      return [];
    }
    return Object.keys(this.formControlField.errors);
  }
}
