import { Component, Input, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoModalComponent } from './info-modal/info-modal.component';

@Component({
  selector: 'isd-info-popup',
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.component.scss']
})
export class InfoPopupComponent {
  @Input() popupTemplate: TemplateRef<any>;
  @Input() placement: string | string[] = 'top';
  @Input() label: string;
  @Input() popoverClass = 'popover-md';
  @Input() iconClass = 'text-primary';

  constructor(private readonly ngbModal: NgbModal) {}
  openInfoModal(): void {
    const component = this.ngbModal.open(InfoModalComponent)
      .componentInstance as InfoModalComponent;
    component.popupTemplate = this.popupTemplate;
  }
}
