<div class="container-fluid vh-100 bg-primary-lightest">
  <div class="row vh-100 align-items-center justify-content-center">
    <div class="col-sm-8 col-md-6 col-lg-5 col-xl-4">
      <div class="bg-white shadow rounded-lg p-5 d-block">
        <img
          class="isd-logo w-100 h-auto cursor-pointer"
          routerLink="/"
          src="../assets/svg/isd_logo.svg"
        />

        <div class="text-center mb-4">
          <h2 class="text-gray-700">
            {{ 'PORTAL.ERROR.TITLE' | translate }}
          </h2>
        </div>
        <div class="text-center">
          <p class="text-center">{{ 'PORTAL.ERROR.MESSAGE' | translate }}</p>
        </div>
        <div class="text-center">
          <a routerLink="/" class="my-2">
            {{ 'COMMON.BUTTON.BACK_TO_HOME' | translate }}
          </a>
          <div *ngIf="authService.isAuthenticated() | async">
            {{ 'COMMON.OR' | translate }}
            <br />
            <a [routerLink]="" (click)="authService.logout()">
              {{ 'AUTH.LOGOUT' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
