import { Component, Input } from '@angular/core';
import { LoadingService } from 'customer-portal-framework-lib';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  ContractInfo,
  ServiceCategoryInfo
} from 'src/app/dto/contract-info.dto';
import { ContractInformationService } from 'src/app/service/contact-information/contract-information.service';
import { NavigationStateService } from 'src/app/service/navigation-state/navigation-state.service';
import { ObjectContractsService } from '../../object-contracts/object-contracts.service';

@Component({
  selector: 'isd-additional-services-banner',
  templateUrl: './additional-services-banner.component.html',
  styleUrls: ['./additional-services-banner.component.scss']
})
export class AdditionalServicesBannerComponent {
  @Input() officeMail: string;
  loadingServiceCategories = 'loadingServiceCategories';

  serviceCategories$: Observable<ServiceCategoryInfo[]>;
  contractList: ContractInfo[];

  constructor(
    public loadingService: LoadingService,
    readonly navState: NavigationStateService,
    private readonly contractInfoService: ContractInformationService,
    readonly objectContractsService: ObjectContractsService
  ) {
    this.loadServiceCategories();
  }

  openAddNewServiceModal(serviceCategory: ServiceCategoryInfo): void {
    this.objectContractsService.openNewServiceModal(
      this.officeMail,
      serviceCategory,
      this.contractList,
      undefined
    );
  }

  private loadServiceCategories(): void {
    this.loadingService.startLoading(this.loadingServiceCategories);

    this.serviceCategories$ = this.getMissingServiceCategories$().pipe(
      this.loadingService.pipeStopLoading(this.loadingServiceCategories)
    );
  }

  private getMissingServiceCategories$(): Observable<ServiceCategoryInfo[]> {
    return this.navState.getObjectZoneId().pipe(
      switchMap(objectZoneId =>
        this.contractInfoService.getObjectContractInfo(objectZoneId)
      ),
      map(data => {
        this.objectContractsService.mergeServiceCategories(data);
        this.contractList =
          this.objectContractsService.filterContractsWithoutSpecialServices(
            data
          );
        const serviceCategories = data.map(x =>
          x.serviceCategories.filter(y => y.isInContract)
        );

        return this.getMissingServiceCategoriesIntersection(serviceCategories);
      })
    );
  }

  private getMissingServiceCategoriesIntersection(
    serviceCategories: ServiceCategoryInfo[][]
  ): ServiceCategoryInfo[] {
    if (serviceCategories.length === 1) {
      return serviceCategories[0];
    }

    let filteredArray = serviceCategories[0];

    serviceCategories.forEach(element => {
      filteredArray = filteredArray.filter(value =>
        element.some(x => x.name === value.name)
      );
    });

    return filteredArray;
  }
}
