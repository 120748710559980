import { Injectable } from '@angular/core';
import { NavigationStateService } from 'src/app/service/navigation-state/navigation-state.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { NavigationState } from 'src/app/service/navigation-state/navigation-state.dto';

export const forbiddenUrl = 'forbidden';

@Injectable({
  providedIn: 'root'
})
export class ForbiddenService {
  constructor(
    private readonly navState: NavigationStateService,
    private readonly router: Router
  ) {}

  async sendToForbiddenPage(): Promise<boolean> {
    return this.router.navigate([this.getAbsoluteForbiddenPath()]);
  }

  async sendToForbiddenPageWithNavState(): Promise<boolean> {
    const navState = await this.getCurrentNavigationState();
    return this.router.navigate([this.getAbsoluteForbiddenPath()], {
      queryParams: {
        customer: navState.customerZoneId,
        object: navState.objectLocationId
      }
    });
  }

  private async getCurrentNavigationState(): Promise<NavigationState> {
    return this.navState.get().pipe(take(1)).toPromise();
  }

  private getAbsoluteForbiddenPath(): string {
    return `/${forbiddenUrl}`;
  }
}
