import { Component } from '@angular/core';
import { AuthService } from 'ps-ng-auth-lib';
import { ZoneNavigationFilterService } from './zone-navigation-filter.service';

@Component({
  selector: 'isd-zone-navigation-filters',
  templateUrl: './zone-navigation-filters.component.html',
  styleUrls: ['./zone-navigation-filters.component.scss'],
  providers: [ZoneNavigationFilterService]
})
export class ZoneNavigationFiltersComponent {
  constructor(
    readonly auth: AuthService,
    readonly navigationFilterService: ZoneNavigationFilterService
  ) {}
}
