<ng-container *ngIf="!(loading.isLoading(loadingNewCustomerKey) | async)">
  <isd-loading-spinner-overlay [loadingKey]="loadingFolderContentKey">
    <cp-file-system-breadcrumb
      class="h4"
      [items]="breadcrumbItems$ | async"
      (itemClicked)="openFolderByBreadcrumbItem($event)"
    ></cp-file-system-breadcrumb>
    <cp-file-system-folder
      [fileSystemItems]="fileSystemItems$ | async"
      (fileSystemItemClicked)="fileSystemItemClicked($event)"
      [noContentText]="'FILESYSTEM.NO_CONTENT_AVAILABLE' | translate"
      (actionClicked)="dropdownActionClicked($event)"
    ></cp-file-system-folder>
    <div class="row justify-content-end">
      <div *ngIf="isAllowedToCreateOrUpload() | async">
        <cp-create-folder
          (folderNameEntered)="createFolder($event)"
          [translation]="createFolderTranslation"
        ></cp-create-folder>
      </div>
      <div *ngIf="isAllowedToCreateOrUpload() | async">
        <cp-file-upload
          [uploadFn]="onUploadFiles()"
          (uploaded)="loading.stopLoading(loadingFolderContentKey)"
          [translation]="uploadTranslation"
        ></cp-file-upload>
      </div>
    </div>
  </isd-loading-spinner-overlay>
</ng-container>
<isd-loading-spinner-block
  [height]="'400px'"
  *cpLoading="loadingNewCustomerKey"
></isd-loading-spinner-block>
