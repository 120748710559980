<ng-container *ngIf="auth.isAuthenticated() | async">
  <div class="cp-zone-navigation-filters row">
    <div class="col-xl-6 col-12 mb-2 mb-xl-0">
      <isd-customer-select></isd-customer-select>
    </div>
    <div
      class="col-xl-6 col-12 mb-2 mb-xl-0"
      *ngIf="navigationFilterService.selectedCustomerZone$ | async"
    >
      <isd-object-location-select></isd-object-location-select>
    </div>
  </div>
</ng-container>
