import { Component, Input } from '@angular/core';
import { NavigationStateService } from 'src/app/service/navigation-state/navigation-state.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdminService } from 'src/app/service/admin/admin.service';

@Component({
  selector: 'isd-admin-panel-opener',
  templateUrl: './admin-panel-opener.component.html',
  styleUrls: ['./admin-panel-opener.component.scss']
})
export class AdminPanelOpenerComponent {
  @Input() zoneId: number;
  constructor(
    private readonly navState: NavigationStateService,
    readonly adminService: AdminService
  ) {}

  get adminLink$(): Observable<any[]> {
    return this.navState
      .get()
      .pipe(
        map(state =>
          state.customerZoneId === undefined
            ? undefined
            : ['/customer', state.customerZoneId, 'admin']
        )
      );
  }
  get leaveAdminLink$(): Observable<any[]> {
    return this.navState
      .get()
      .pipe(
        map(state =>
          state.customerZoneId === undefined
            ? undefined
            : ['/customer', state.customerZoneId]
        )
      );
  }
}
