import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'ps-ng-auth-lib';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WhenNotAuthenticatedRedirectToLoginGuard implements CanActivate {
  constructor(
    private readonly auth: AuthService,
    private readonly router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.auth.isAuthenticated().pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return true;
        }

        return this.router.parseUrl('/login');
      })
    );
  }
}
