import { ValidatorFn, FormControl, ValidationErrors } from '@angular/forms';

export function whiteSpaceValidator(): ValidatorFn {
  return (formControl: FormControl): ValidationErrors | undefined => {
    if (formControl.value.trim().length === 0) {
      return {
        error: {
          empty: true
        }
      };
    }
    return undefined;
  };
}
