<div class="customer-info-wrapper h-100" *ngIf="customer">
  <div class="customer-info rounded shadow p-3">
    <div class="row justify-content-center text-primary">
      <b class="customer-name px-3 py-1 h4">{{ customer.name }}</b>
    </div>

    <div class="row mt-4">
      <div class="col-4 text-nowrap">
        <span class="d-none d-sm-inline">
          <i class="mr-1 fa fa-home"></i>
        </span>
        <b>
          {{ 'CUSTOMERZONE.MAIN_ADDRESS' | translate }}
        </b>
      </div>
      <div class="col-8">
        <div class="row">{{ customer.mainAddress.street }},</div>
        <div class="row">
          {{ customer.mainAddress.zip }} {{ customer.mainAddress.city }}
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-4 text-nowrap">
        <span class="d-none d-sm-inline">
          <i class="mr-1 fas fa-phone-alt"></i>
        </span>
        <b>{{ 'CUSTOMERZONE.TELEPHONE' | translate }}</b>
      </div>
      <div class="col-8">
        <div class="row" *ngFor="let tel of customer.tel">
          <a href="tel:{{ tel }}">{{ tel }}</a>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-4 text-nowrap">
        <span class="d-none d-sm-inline">
          <i class="mr-1 fas fa-fax"></i>
        </span>
        <b>{{ 'CUSTOMERZONE.FAX' | translate }}</b>
      </div>
      <div class="col-8">
        <div class="row" *ngFor="let fax of customer.fax">{{ fax }}</div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-4 text-nowrap">
        <span class="d-none d-sm-inline">
          <i class="mr-1 fas fa-envelope"></i>
        </span>
        <b>{{ 'CUSTOMERZONE.MAIL' | translate }}</b>
      </div>
      <div class="col-8">
        <div class="row text-truncate" *ngFor="let mail of customer.mail">
          <a href="mailto:{{ mail }}">{{ mail }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
