import { Component, OnDestroy } from '@angular/core';
import { CustomerZoneStructure } from 'src/app/dto/customer-zone-structure.dto';
import { NavigationStateService } from 'src/app/service/navigation-state/navigation-state.service';
import { map, switchMap, shareReplay, take } from 'rxjs/operators';
import { LoadingService } from 'customer-portal-framework-lib';
import { RoleService } from '../../service/role.service';
import { Observable, Subscription } from 'rxjs';
import { Role } from 'src/app/dto/role.dto';
import { ZoneType } from 'src/app/dto/enums/zone-type.dto';
import { CustomerZoneStructureService } from '../../service/customer-zone-structure.service';
import { ErrorHandlerService } from 'src/app/service/error-handler/error-handler.service';

@Component({
  selector: 'isd-role-membership-editor',
  templateUrl: './role-membership-editor.component.html',
  styleUrls: ['./role-membership-editor.component.scss']
})
export class RoleMembershipEditorComponent implements OnDestroy {
  customerZoneStructure$: Observable<CustomerZoneStructure>;

  loadingCustomerKey = 'LoadingCustomer';
  loadingCustomerStructureKey = 'LoadingCustomerStructure';
  customerZoneRoles$: Observable<Role[]>;
  objectZoneRoles$: Observable<Role[]>;

  private readonly subscription = new Subscription();

  constructor(
    private readonly customerStructureService: CustomerZoneStructureService,
    private readonly navigationStateService: NavigationStateService,
    readonly loadingService: LoadingService,
    private readonly roleService: RoleService,
    private readonly errorHandler: ErrorHandlerService
  ) {
    this.initCustomerStructureObservable();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initCustomerStructureObservable(): void {
    const sub = this.navigationStateService.get().subscribe(navState => {
      this.loadingService.startLoading(this.loadingCustomerStructureKey);
      this.customerZoneStructure$ = this.customerStructureService
        .getStructure(navState.customerZoneId)
        .pipe(
          this.errorHandler.pipeHandleError(),
          take(1),
          this.loadingService.pipeStopLoading(this.loadingCustomerStructureKey),
          shareReplay(1)
        );
      this.initRoleObservables();
    });

    this.subscription.add(sub);
  }

  private initRoleObservables(): void {
    const roles$ = this.customerZoneStructure$.pipe(
      this.errorHandler.pipeHandleError(),
      switchMap(customerZone =>
        this.roleService.getRolesByCustomerZone(customerZone.id)
      )
    );

    this.customerZoneRoles$ = roles$.pipe(
      this.errorHandler.pipeHandleError(),
      map(roles => roles.filter(r => r.zoneType === ZoneType.CustomerZone))
    );

    this.objectZoneRoles$ = roles$.pipe(
      this.errorHandler.pipeHandleError(),
      map(roles => roles.filter(r => r.zoneType === ZoneType.ObjectZone))
    );
  }
}
