//#region imports
import { Component, OnDestroy } from '@angular/core';
import { FileTransferService } from '../services/file-transfer.service';
import {
  Observable,
  from,
  Subscription,
  combineLatest,
  OperatorFunction
} from 'rxjs';
import {
  FileSystemItem,
  FileSystemItemType,
  FileSystemItemDropdownOption,
  FileSystemItemDropdownAction,
  FileUploadTranslation,
  CreateFolderTranslation,
  CpEditFileNameModalComponent,
  FileSystemBreadcrumbItem,
  LoadingService
} from 'customer-portal-framework-lib';
import {
  map,
  take,
  withLatestFrom,
  skip,
  switchMap,
  filter,
  tap
} from 'rxjs/operators';
import { FolderItemType } from '../enum/folder-item-type.enum';
import { FolderItem, Folder } from '../dto/folder-item.dto';
import { IsdcpFileSystemItem } from '../dto/isdcp-filesystem-item.dto';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationStateService } from 'src/app/service/navigation-state/navigation-state.service';
import { PermissionService } from 'src/app/permission/permission.service';
import { ErrorHandlerService } from 'src/app/service/error-handler/error-handler.service';
import { FolderPathBookmarkService } from '../services/folder-path-bookmark.service';
import { FrameworkLibTranslationProviderService } from '../services/framework-lib-translation-provider.service';
import { Rights, NO_RIGHTS } from 'src/app/permission/permission.dto';
//#endregion

@Component({
  selector: 'isd-file-transfer',
  templateUrl: './file-transfer.component.html',
  styleUrls: ['./file-transfer.component.scss']
})
export class FileTransferComponent implements OnDestroy {
  fileSystemItems$: Observable<FileSystemItem[]>;
  breadcrumbItems$: Observable<FileSystemBreadcrumbItem[]>;

  createFolderTranslation: CreateFolderTranslation;
  uploadTranslation: FileUploadTranslation;
  inboxUploadTranslation: FileUploadTranslation;

  loadingFolderContentKey = 'loadingFolderContent';
  loadingNewCustomerKey = 'loadingNewCustomer';

  private subscriptions = new Subscription();

  constructor(
    private readonly fileTransferService: FileTransferService,
    private readonly modalService: NgbModal,
    private readonly navState: NavigationStateService,
    private readonly errorHandler: ErrorHandlerService,
    private readonly permissionsService: PermissionService,
    private readonly translate: TranslateService,
    private readonly frameworkLibTranslation: FrameworkLibTranslationProviderService,
    private readonly bookmark: FolderPathBookmarkService,
    public loading: LoadingService
  ) {
    this.loading.startLoading(this.loadingFolderContentKey);
    this.initFileTransferService();

    this.generateTranslations();

    this.setupBreadcrumbObservable();
    this.setupFolderItemsObservable();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async openFolderByBreadcrumbItem(
    item: FileSystemBreadcrumbItem
  ): Promise<void> {
    this.loading.startLoading(this.loadingFolderContentKey);

    const folderItem = await this.convertBreadcrumbItemToFolder(item);
    await this.fileTransferService.openBreadcrumbItem(folderItem);

    this.loading.stopLoading(this.loadingFolderContentKey);
  }

  async fileSystemItemClicked(item: IsdcpFileSystemItem): Promise<void> {
    this.loading.startLoading(this.loadingFolderContentKey);

    const folderItem = item.folderItem;
    await this.fileTransferService.openFolderItem(folderItem);

    this.loading.stopLoading(this.loadingFolderContentKey);
  }

  dropdownActionClicked(action: FileSystemItemDropdownAction): void {
    const folderItem = (action.fileSystemItem as IsdcpFileSystemItem)
      .folderItem;

    if (action.value === 'delete') {
      this.deleteFolderItem(folderItem);
    } else if (action.value === 'rename') {
      this.renameFolderItem(folderItem);
    }
  }

  isAllowedToCreateOrUpload(): Observable<boolean> {
    return this.getCurrentFolderRights().pipe(map(rights => rights.canCreate));
  }

  onUploadFiles(): (
    file: File,
    force: boolean
  ) => Observable<IsdcpFileSystemItem> {
    return (item, force) => this.uploadFile(item, force);
  }

  async createFolder(name: string): Promise<void> {
    this.loading.startLoading(this.loadingFolderContentKey);
    try {
      await this.fileTransferService.createFolder(name);
    } catch (error) {
      this.loading.stopLoading(this.loadingFolderContentKey);
      this.errorHandler.handleError(error, {
        409: this.translate.instant('FILESYSTEM.ERROR.FOLDER_ALREADY_EXISTS')
      });
    }
  }

  private async convertBreadcrumbItemToFolder(
    item: FileSystemBreadcrumbItem
  ): Promise<FolderItem> {
    const breadcrumbList = await this.fileTransferService
      .getBreadcrumb()
      .pipe(take(1))
      .toPromise();
    return breadcrumbList.find(bc => bc.path === item.uri);
  }

  private convertFolderToBreadcrumbItem(
    folderItem: Folder
  ): FileSystemBreadcrumbItem {
    const breadcrumbItem: FileSystemBreadcrumbItem = {
      label: folderItem.name,
      uri: folderItem.path
    };
    return breadcrumbItem;
  }

  private convertFolderItemToFileSystemItem(
    folderItem: FolderItem,
    dropdownOptions: FileSystemItemDropdownOption[]
  ): IsdcpFileSystemItem {
    const fileSystemItem: IsdcpFileSystemItem = {
      id: undefined,
      dropdownOptions: this.isProtected(folderItem)
        ? undefined
        : dropdownOptions,
      fileType: this.convertFileType(folderItem.type),
      uri: folderItem.path,
      label: folderItem.name,
      folderItem
    };
    return fileSystemItem;
  }

  private isProtected(folderItem: FolderItem): boolean {
    return folderItem.type === FolderItemType.DocumentInbox;
  }

  private convertFileType(folderItemType: FolderItemType): FileSystemItemType {
    switch (folderItemType) {
      case FolderItemType.DocumentInbox:
        return FileSystemItemType.SystemFolder;
      case FolderItemType.Folder:
        return FileSystemItemType.Folder;
      case FolderItemType.File:
        return FileSystemItemType.File;
      default:
        return FileSystemItemType.Unknown;
    }
  }

  private uploadFile(
    file: File,
    force: boolean
  ): Observable<IsdcpFileSystemItem> {
    this.loading.startLoading(this.loadingFolderContentKey);

    const uploadItem$ = from(this.fileTransferService.uploadFile(file, force));
    return uploadItem$ as any;
  }

  private getFileSystemItemOptions(): Observable<
    FileSystemItemDropdownOption[]
  > {
    return this.getCurrentFolderRights().pipe(
      map(rights => {
        const dropdownOptions: FileSystemItemDropdownOption[] = [];
        if (rights.canDelete) {
          dropdownOptions.push({
            label: this.translate.instant('COMMON.BUTTON.DELETE'),
            value: 'delete',
            iconClass: 'fa fa-trash-alt',
            isSeperator: false
          });
        }
        if (rights.canEdit) {
          dropdownOptions.push({
            label: this.translate.instant('COMMON.BUTTON.RENAME'),
            value: 'rename',
            iconClass: 'fa fa-edit',
            isSeperator: false
          });
        }
        return dropdownOptions;
      })
    );
  }

  private async initFileTransferService(): Promise<void> {
    this.fileTransferService.setBreadcrumbRootName(
      this.translate.instant('FILESYSTEM.FILES')
    );

    const hasBookmarkedBreadcrumb = await this.hasInitialBreadcrumbBookmark();

    this.listenForCustomerZoneChanges(hasBookmarkedBreadcrumb);
    this.listenForBookmarkChanges();
  }

  private async hasInitialBreadcrumbBookmark(): Promise<boolean> {
    return this.bookmark
      .get()
      .pipe(
        take(1),
        map(bb => !!bb)
      )
      .toPromise();
  }

  private listenForBookmarkChanges(): void {
    const sub = this.bookmark
      .get()
      .pipe(filter(bb => bb !== undefined))
      .subscribe(async bookmarkedBreadcrumb => {
        this.loading.pipeStartLoading(this.loadingFolderContentKey);
        try {
          await this.fileTransferService.restoreStateFromBreadcrumb(
            bookmarkedBreadcrumb
          );
        } catch (err) {
          this.clearBookmarkAndReinitialize();
        }

        this.loading.stopLoading(this.loadingFolderContentKey);
      });

    this.subscriptions.add(sub);
  }

  private clearBookmarkAndReinitialize(): void {
    this.bookmark.set([]);
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
    setTimeout(() => this.initFileTransferService(), 50);
  }

  private listenForCustomerZoneChanges(shouldSkipOne: boolean): void {
    let customerZoneId$ = this.navState.getCustomerZoneId();

    if (shouldSkipOne) {
      customerZoneId$ = customerZoneId$.pipe(skip(1));
    }

    const sub = customerZoneId$
      .pipe(
        this.filterPermittedZonesPipe(),
        this.loading.pipeStartLoading(this.loadingNewCustomerKey)
      )
      .subscribe(async zoneId => {
        await this.fileTransferService.openCustomerZoneRoot(zoneId);
        this.loading.stopLoading(this.loadingNewCustomerKey);
      });

    this.subscriptions.add(sub);
  }

  private filterPermittedZonesPipe(): OperatorFunction<number, number> {
    return switchMap(customerZoneId =>
      this.permissionsService.getCustomerPermission(customerZoneId).pipe(
        filter(permission => permission.documents.canRead),
        map(_ => customerZoneId)
      )
    );
  }

  private setupBreadcrumbObservable(): void {
    this.breadcrumbItems$ = this.fileTransferService
      .getBreadcrumb()
      .pipe(
        map(folders => folders.map(f => this.convertFolderToBreadcrumbItem(f)))
      );
  }

  private setupFolderItemsObservable(): void {
    this.fileSystemItems$ = this.fileTransferService.folderItems$.pipe(
      withLatestFrom(this.getFileSystemItemOptions()),
      map(([folderItems, dropdownOptions]) =>
        folderItems.map(fi =>
          this.convertFolderItemToFileSystemItem(fi, dropdownOptions)
        )
      ),
      this.loading.pipeStopLoading(this.loadingFolderContentKey)
    );
  }

  private async renameFolderItem(folderItem: FolderItem): Promise<void> {
    const newName = await this.openRenameDialog(
      folderItem.name,
      this.isFile(folderItem)
    );
    if (!newName || newName === folderItem.name) {
      return;
    }

    await this.tryToRenameFolderItem(folderItem, newName);
  }

  private isFile(folderItem: FolderItem): boolean {
    return folderItem.type === FolderItemType.File;
  }

  private async tryToRenameFolderItem(
    folderItem: FolderItem,
    newName: string
  ): Promise<void> {
    this.loading.startLoading(this.loadingFolderContentKey);

    try {
      const clone: FolderItem = { ...folderItem, name: newName };
      await this.fileTransferService.renameFolderItem(clone);
    } catch (ex) {
      this.errorHandler.handleError(ex, {
        409: this.getAlreadyExistsErrorMessage(folderItem)
      });
    }

    this.loading.stopLoading(this.loadingFolderContentKey);
  }

  private getAlreadyExistsErrorMessage(folderItem: FolderItem): string {
    if (this.isFile(folderItem)) {
      return this.translate.instant('FILESYSTEM.ERROR.FILE_ALREADY_EXISTS');
    }

    return this.translate.instant('FILESYSTEM.ERROR.FOLDER_ALREADY_EXISTS');
  }

  private async openRenameDialog(
    oldName: string,
    isFile: boolean
  ): Promise<string> {
    const modalRef = this.modalService.open(CpEditFileNameModalComponent);
    const component = modalRef.componentInstance as CpEditFileNameModalComponent;
    component.folderName = oldName;
    component.translation = this.frameworkLibTranslation.generateRenameTranslation(
      isFile
    );

    return component.folderDisplayName.pipe(take(1)).toPromise();
  }

  private async deleteFolderItem(folderItem: FolderItem): Promise<void> {
    this.loading.startLoading(this.loadingFolderContentKey);
    try {
      await this.fileTransferService.deleteFolderItem(folderItem);
    } catch (ex) {
      this.loading.stopLoading(this.loadingFolderContentKey);
      this.errorHandler.handleError(ex, {
        409: this.translate.instant('FILESYSTEM.ERROR.FOLDER_NOT_EMPTY')
      });
    }
  }

  private generateTranslations(): void {
    this.createFolderTranslation = this.frameworkLibTranslation.generateCreateFolderTranslation();
    this.uploadTranslation = this.frameworkLibTranslation.generateUploadTranslation();
    this.inboxUploadTranslation = this.frameworkLibTranslation.generateInboxUploadTranslation();
  }

  private getCurrentFolderRights(): Observable<Rights> {
    return combineLatest(
      this.permissionsService.getCurrentCustomerPermission(),
      this.fileTransferService.currentFolderType$
    ).pipe(
      map(([permission, folderType]) => {
        switch (folderType) {
          case FolderItemType.DocumentInbox:
            return permission.documentInbox;
          case FolderItemType.Folder:
            return permission.documents;
          default:
            return NO_RIGHTS;
        }
      })
    );
  }
}
