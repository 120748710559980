import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'isd-permission-row',
  templateUrl: './permission-row.component.html',
  styleUrls: ['./permission-row.component.scss']
})
export class PermissionRowComponent {
  @Input() title: string;
  @Input() description: string;

  @Input() disabled = false;

  @Input() checked: boolean;
  @Output() readonly checkedChange = new EventEmitter<boolean>();
}
