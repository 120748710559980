import { Injectable } from '@angular/core';
import { LegalDocument } from 'src/app/dto/legal-document.dto';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LegalDocumentService {
  constructor(private readonly http: HttpClient) {}

  getLegalDocuments(): Observable<LegalDocument[]> {
    return this.http
      .get<LegalDocument[]>(
        `${environment.bolApiHost}/${environment.bolApiBaseUrl}/legalDocuments`
      )
      .pipe(shareReplay(1));
  }

  getLegalDocumentData(legalDocumentUrl: string): Observable<Blob> {
    return this.http.get(`${environment.bolApiHost}/${legalDocumentUrl}`, {
      responseType: 'blob'
    });
  }
}
