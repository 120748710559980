import { Component, Input } from '@angular/core';
import { LoadingService } from 'customer-portal-framework-lib';
import { Observable } from 'rxjs';
import { shareReplay, switchMap, tap } from 'rxjs/operators';
import {
  ContractInfo,
  ServiceCategoryInfo
} from 'src/app/dto/contract-info.dto';
import { ContractInformationService } from 'src/app/service/contact-information/contract-information.service';
import { NavigationStateService } from 'src/app/service/navigation-state/navigation-state.service';
import { ObjectContractsService } from './object-contracts.service';

@Component({
  selector: 'isd-object-contracts',
  templateUrl: './object-contracts.component.html',
  styleUrls: ['./object-contracts.component.scss']
})
export class ObjectContractsComponent {
  @Input() officeEmail: string;

  loadingKey = 'contractInfo';
  contracts$: Observable<ContractInfo[]>;
  contractList: ContractInfo[];

  constructor(
    loading: LoadingService,
    navState: NavigationStateService,
    private readonly objectContractsService: ObjectContractsService,
    private readonly contractInfoService: ContractInformationService
  ) {
    this.contracts$ = navState.getObjectZoneId().pipe(
      loading.pipeStartLoading(this.loadingKey),
      switchMap(objectZoneId =>
        this.contractInfoService.getObjectContractInfo(objectZoneId)
      ),
      tap(contractInfos => {
        this.objectContractsService.mergeServiceCategories(contractInfos);
        this.contractList =
          objectContractsService.filterContractsWithoutSpecialServices(
            contractInfos
          );
      }),

      loading.pipeStopLoading(this.loadingKey),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  hasNoSpecialServices(contract: ContractInfo): boolean {
    return contract.serviceCategories[0].details.length === 0;
  }

  isSpecialServiceCategory(serviceCategory: ServiceCategoryInfo): boolean {
    return serviceCategory.details.length > 0;
  }

  openAddNewServiceModal(
    serviceCategory: ServiceCategoryInfo,
    selectedContract: ContractInfo
  ): void {
    this.objectContractsService.openNewServiceModal(
      this.officeEmail,
      serviceCategory,
      this.contractList,
      selectedContract
    );
  }
}
