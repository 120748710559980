import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ObjectInformation } from '../dto/object-information.dto';
import { map } from 'rxjs/operators';
import { ImageFileStreamData } from '../dto/image-file-stream-data.dto';
import * as dayjs from 'dayjs';
import { ObjectReport } from './object-information/object-reports/object-report.dto';
import { Util } from '../util';

@Injectable({
  providedIn: 'root'
})
export class ObjectLocationDataService {
  constructor(private readonly http: HttpClient) {}

  getObjectInformationByObjectNumber(
    objectLocationId: string
  ): Observable<ObjectInformation> {
    return this.http.get<ObjectInformation>(
      `${environment.bolApiHost}/${environment.bolApiBaseUrl}/objectLocations/${objectLocationId}/ObjectInformation`
    );
  }

  getObjectControls(objectLocationId: string): Observable<string[]> {
    return this.http
      .get<string[]>(
        `${environment.bolApiHost}/${environment.bolApiBaseUrl}/objectLocations/${objectLocationId}/ObjectControls`
      )
      .pipe(
        map(resp => (!resp ? [] : resp)),
        map(objectControls => this.sortObjectControlsDesc(objectControls))
      );
  }

  getObjectReports(objectLocationId: string): Observable<ObjectReport[]> {
    return this.http
      .get<ObjectReport[]>(
        `${environment.bolApiHost}/${environment.bolApiBaseUrl}/objectLocations/${objectLocationId}/ObjectReports`
      )
      .pipe(
        map(resp => (!resp ? [] : resp)),
        map(objectReports => this.sortObjectReportsDesc(objectReports))
      );
  }

  downloadObjectReport(url: string): Observable<Blob> {
    return this.http.get(Util.urlConcat(environment.bolApiHost, url), {
      responseType: 'blob'
    });
  }

  getBase64ObjectImageByObjectNumber(
    objectLocationId: string
  ): Observable<string> {
    return this.http
      .get<ImageFileStreamData>(
        `${environment.bolApiHost}/${environment.bolApiBaseUrl}/objectLocations/${objectLocationId}/Image`
      )
      .pipe(map(imageData => this.toBase64DataUri(imageData)));
  }

  private toBase64DataUri(imageData: ImageFileStreamData): string {
    return `data:image/jpeg;base64,${imageData.values[0].base64Data}`;
  }

  private sortObjectControlsDesc(objectControls: string[]): string[] {
    return objectControls.sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? -1 : 1));
  }
  private sortObjectReportsDesc(objectReports: ObjectReport[]): ObjectReport[] {
    return objectReports.sort((a, b) =>
      dayjs(a.date).isAfter(dayjs(b.date)) ? -1 : 1
    );
  }
}
