import { EnvironmentType } from './environment-type';

/**
 * This configuration should be used for deployment to the azure test environment.
 */
export const environment: EnvironmentType = {
  production: false,
  privacyPolicyLink:
    'https://www.isd-service.de/service/datenschutzerklaerung/',
  introVideoLink:
    'https://prd99isdcp99stor99ui.blob.core.windows.net/media/Video_Kundenportal.mp4',
  authentication: {
    internalDomains: ['persona.de', 'persona-digital.de', 'isd-service.de'],
    storePreference: true,
    logoutWhenTokenExpired: false,
    redirectUrl: 'https://kundenportal.isd-service-cloud-test123.de/',
    internal: {
      clientId: '52227903-db98-4fba-a0e9-d69e012b37fd',
      tenantId: '1c2b8ed7-dafe-4820-ab1e-23ce67c5326e',
      authority:
        'https://login.microsoftonline.com/1c2b8ed7-dafe-4820-ab1e-23ce67c5326e',
      protectedResources: [
        [
          'https://tst-api-apim.azure-api.net',
          ['api://8a841457-fbc6-42b8-b242-e3318ec150ae/Access']
        ]
      ],
      validateAuthority: true
    },
    external: {
      clientId: '64d2d5ea-906a-4e04-a958-775a4009be80',
      tenantId: '5c1fc2bb-bf27-4569-8bcf-53167ea206a5',
      authority:
        'https://tst99ps99b2c.b2clogin.com/5c1fc2bb-bf27-4569-8bcf-53167ea206a5/B2C_1A_ForcePasswordChangeFirstLogin_signin',
      pwResetAuthority:
        'https://tst99ps99b2c.b2clogin.com/5c1fc2bb-bf27-4569-8bcf-53167ea206a5/B2C_1A_PasswordReset',
      protectedResources: [
        [
          'https://tst-api-apim.azure-api.net',
          [
            'https://tst99ps99b2c.onmicrosoft.com/d7dd4e22-0c6d-464f-88dc-1d631ef81e5d/user_impersonation'
          ]
        ]
      ],
      validateAuthority: false,
      validateClaims: {
        iss: 'https://tst99ps99b2c.b2clogin.com/5c1fc2bb-bf27-4569-8bcf-53167ea206a5/v2.0/'
      }
    },
    authGuard: { onUnauthorizedForwardTo: '/login' }
  },
  bolApiHost: 'https://tst-api-apim.azure-api.net/isdcp-bol/v1',
  bolApiBaseUrl: 'de'
};
