<div class="container-fluid vh-100 bg-primary-lightest">
  <div class="row vh-100 align-items-center justify-content-center">
    <div class="col-sm-8 col-md-6 col-lg-5 col-xl-4">
      <div class="bg-white shadow rounded-lg p-5 d-block">
        <img
          class="isd-logo mb-4 w-100 h-auto"
          routerLink="/"
          src="../assets/svg/isd_logo.svg"
        />
        <div class="d-flex justify-content-center">
          <isd-loading-spinner></isd-loading-spinner>
        </div>
        <h4 class="text-center text-muted my-3">
          <span>{{ 'COMMON.LOADING.ONE_MOMENT' | translate }}</span>
          <br />
          <span>{{ 'COMMON.LOADING.PREPARING' | translate }}</span>
        </h4>
      </div>
    </div>
  </div>
</div>
