import { UserModule } from '../user/user.module';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import {
  LoadingService,
  LoadingModule,
  CpFormsModule
} from 'customer-portal-framework-lib';
import { AdminPanelOpenerComponent } from './admin-panel-opener/admin-panel-opener.component';
import { NgbModalModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { PermissionModule } from '../permission/permission.module';
import { RoleMembershipEditorComponent } from './role-membership/role-membership-editor/role-membership-editor.component';
import { CustomerZoneStructureService } from './service/customer-zone-structure.service';
import { RolemembershipService } from './service/rolemembership.service';
import { RoleService } from './service/role.service';
import { RouterModule } from '@angular/router';
import { ZoneRolemembershipComponent } from './role-membership/zone-rolemembership/zone-rolemembership.component';
import { CustomerModule } from '../customer/customer.module';
import { RoleAssignmentModalComponent } from './role-membership/role-assignment-modal/role-assignment-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserRolesService } from './service/user-roles.service';
import { RoleDefinitionService } from './service/role-definition.service';
import { AdminOverviewComponent } from './admin-overview/admin-overview.component';
import { EditRoleDefinitionComponent } from './role-definition/edit-role-definition/edit-role-definition.component';
import { RoleDefinitionOverviewComponent } from './role-definition/role-definition-overview/role-definition-overview.component';
import { PermissionRowComponent } from './role-definition/permission-row/permission-row.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    AdminPanelOpenerComponent,
    RoleDefinitionOverviewComponent,
    RoleMembershipEditorComponent,
    ZoneRolemembershipComponent,
    RoleAssignmentModalComponent,
    AdminOverviewComponent,
    EditRoleDefinitionComponent,
    PermissionRowComponent
  ],
  imports: [
    BrowserModule,
    UserModule,
    HttpClientModule,
    TranslateModule.forChild(),
    NgbModalModule,
    RouterModule,
    CustomerModule,
    LoadingModule,
    SharedModule,
    FormsModule,
    PermissionModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgbPopoverModule,
    CpFormsModule
  ],
  providers: [
    LoadingService,
    CustomerZoneStructureService,
    RolemembershipService,
    RoleService,
    UserRolesService,
    RoleDefinitionService
  ],
  exports: [
    AdminPanelOpenerComponent,
    RoleMembershipEditorComponent,
    RoleDefinitionOverviewComponent
  ],
  entryComponents: [RoleAssignmentModalComponent]
})
export class AdminModule {}
