import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileTransferComponent } from './file-transfer/file-transfer.component';
import { FileTransferService } from './services/file-transfer.service';
import { FileTransferBackendService } from './services/file-transfer-backend.service';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionModule } from '../permission/permission.module';
import {
  LoadingService,
  CpFileSystemModule,
  LoadingModule
} from 'customer-portal-framework-lib';
import { FileSaverService } from './services/file-saver.service';
import { FolderPathBookmarkService } from './services/folder-path-bookmark.service';
import { FrameworkLibTranslationProviderService } from './services/framework-lib-translation-provider.service';
import { FileTransferBreadcrumbService } from './services/file-transfer-breadcrumb.service';
import { FileTransferWrapperComponent } from './file-transfer-wrapper/file-transfer-wrapper.component';
import { DocumentInboxUploadComponent } from './document-inbox-upload/document-inbox-upload.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forChild(),
    PermissionModule,
    CpFileSystemModule,
    LoadingModule,
    SharedModule
  ],
  declarations: [
    FileTransferComponent,
    FileTransferWrapperComponent,
    DocumentInboxUploadComponent
  ],
  providers: [
    FolderPathBookmarkService,
    FileTransferService,
    FileTransferBackendService,
    FileSaverService,
    FrameworkLibTranslationProviderService,
    FileTransferBreadcrumbService
  ],
  exports: [FileTransferWrapperComponent]
})
export class FileTransferModule {}
