import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerInfoComponent } from './customer-info/customer-info.component';
import { CustomerZoneOverviewComponent } from './customer-zone-overview/customer-zone-overview.component';
import { CustomerZoneDataService } from './customer-zone-data.service';
import { LoadingModule } from 'customer-portal-framework-lib';
import { TranslateModule } from '@ngx-translate/core';
import { FileTransferModule } from '../file-transfer/file-transfer.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [CustomerInfoComponent, CustomerZoneOverviewComponent],
  imports: [
    CommonModule,
    LoadingModule,
    TranslateModule.forRoot(),
    FileTransferModule,
    SharedModule
  ],
  exports: [CustomerInfoComponent, CustomerZoneOverviewComponent]
})
export class CustomerModule {}
